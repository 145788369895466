import React from "react";

import styles from "./HomeContactBlock.module.scss";
import ContactBlock from "../../global/ContactBlock/ContactBlock";
import BgLight from "../../layout/BgLight/BgLight";
import TransitionProvider, { TransitionStyleTypes } from "../../../providers/TransitionProvider";
import { useLazy } from "../../../hooks/useLazy";

import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const {   
  contactHeaderSubtitle
} = translations;

interface Props {}

const HomeContactBlock: React.FC<Props> = (props) => {
      const { isInView, ref } = useLazy(0.2);
      const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <section ref={ref} className={`container ${styles.homeContactBlock}`}>
      <ContactBlock text={contactHeaderSubtitle[siteLanguage]} />       
      <TransitionProvider
          inProp={isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
      <BgLight className={styles.homeContactBlock__bgLight} />
        </TransitionProvider>
    </section>
  );
};

export default HomeContactBlock;
