import { useLayoutEffect, useState } from "react";



export const useContentLoaded = () => {
    const [contentLoaded, setContentLoaded] = useState(false);

    useLayoutEffect(() => {
      setTimeout(() => {
        setContentLoaded(true);
      }, 500);
    }, []);


    return contentLoaded
}