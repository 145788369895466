import React from "react";
import ContactHeader from "../components/ContactPage/ContactHeader/ContactHeader";
import Head from '../components/global/Head/Head'
import { useAppSelector } from "./../hooks/redux";
import { translations } from "../constants/translations";
 
const { 
  metaContactKeyword,
  metaContactTitle,
  metaContactDescription
} = translations; 

const ContactsPage = () => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <>
    <Head     
        keyword={metaContactKeyword[siteLanguage]}
        title={metaContactTitle[siteLanguage]}
        ogTitle={metaContactTitle[siteLanguage]}
        description={metaContactDescription[siteLanguage]}
        ogDescription={metaContactDescription[siteLanguage]}
      />
      <ContactHeader />
    </>
  );
};

export default ContactsPage;