import React from 'react';
import ContactBlock from '../../global/ContactBlock/ContactBlock';
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

interface Props {  
}

const {   
  serviceContactBlockDescription
} = translations;

const ServiceContactBlock: React.FC<Props> = (props) => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return <div className={`container`}>
    <ContactBlock text={serviceContactBlockDescription[siteLanguage]} /> 
  </div>;
};

export default ServiceContactBlock;