import React, { ReactNode } from "react";

import styles from "./ContactBlock.module.scss";
import MainBtn from "../../layout/MainBtn/MainBtn";
import { useLocation } from "react-router-dom";
import { servicesPagePath } from "../../../router/path";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";
import { contactFormLinks } from "../../../constants/contacts";

const { contactBlockBtn, serviceContactBlockTitle, contactHeaderTitle } =
  translations;

interface Props {
  text: ReactNode;
  className?: string;
}

const ContactBlock: React.FC<Props> = ({ text, className }) => {
  const { isInView, ref } = useLazy(0.2);

  const location = useLocation();
  const isServices = location.pathname === servicesPagePath;
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <section ref={ref}>
      <TransitionProvider
        inProp={isInView}
        style={TransitionStyleTypes.bottom}
        className={`${styles.contactBlock} ${className || ""}`}
      >
        <div className={styles.contactBlock__texts}>
          <h2 className={"titleMd"}>
            {isServices ? (
              <>{serviceContactBlockTitle[siteLanguage]}</>
            ) : (
              <>{contactHeaderTitle[siteLanguage]}</>
            )}
          </h2>
          <h4 className={styles.contactBlock__subtitle}>{text}</h4>
        </div>
        <div className={styles.contactBlock__btnWrapper}>
          <MainBtn
            asLink
            href={contactFormLinks[siteLanguage]}
            target="_blank"
            rel="noreferrer"
            className={styles.contactBlock__btn}
          >
            {contactBlockBtn[siteLanguage]}
          </MainBtn>
        </div>
      </TransitionProvider>
    </section>
  );
};

export default ContactBlock;
