export const scrollIntoElement = (elQuery: string, offset?: number) => {
  const curElement = document.querySelector(elQuery);
  if (curElement) {
    setTimeout(() => {
      const topOffset = offset || 150; // Adjust the margin as needed
      const elementPosition =
        curElement.getBoundingClientRect().top + window.scrollY;
      const offsetPosition = elementPosition - topOffset;

      window.scrollTo({
        top: offsetPosition,
        // behavior: "smooth",
      });
    }, 100);
  }
};
