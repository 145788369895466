import {
  aboutPagePath,
  contactsPagePath,
  projectsPagePath,
  servicesPagePath,
} from "../router/path";
import { translations } from "./translations";

const {
  aboutText,
  contactsText,
  projectsText,
  servicesText,
} = translations.menu

export const menuItems = [
  {
    link: aboutPagePath,
    text: aboutText,
  },
  {
    link: servicesPagePath, 
    text: servicesText,
  },
  {
    link: projectsPagePath,
    text: projectsText,
  },
  {
    link: contactsPagePath,
    text: contactsText,
  },  
];