import React from 'react'
import HomeHeader from '../components/HomePage/HomeHeader/HomeHeader'
import HomeServiceTypes from '../components/HomePage/HomeServiceTypes/HomeServiceTypes'
import HomeAutoCommunication from '../components/HomePage/HomeAutoCommunication/HomeAutoCommunication'
import HomeProjects from '../components/HomePage/HomeProjects/HomeProjects'
import HomeTargeting from '../components/HomePage/HomeTargeting/HomeTargeting'
import HomeContactBlock from '../components/HomePage/HomeContactBlock/HomeContactBlock'

import Head from '../components/global/Head/Head'
import { useAppSelector } from "./../hooks/redux";
import { translations } from "../constants/translations";
 
const { 
  metaHomepageKeyword,
  metaHomepagetTitle,
  metaHomepageDescription
} = translations; 

const HomePage = () => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);
  return (
    <>
    <Head     
        keyword={metaHomepageKeyword[siteLanguage]}
        title={metaHomepagetTitle[siteLanguage]}
        ogTitle={metaHomepagetTitle[siteLanguage]}
        description={metaHomepageDescription[siteLanguage]}
        ogDescription={metaHomepageDescription[siteLanguage]}
      />
      <HomeHeader/>
      <HomeServiceTypes/>
      <HomeTargeting/>
      <HomeAutoCommunication/>
      <HomeProjects/>
      <HomeContactBlock/>
    </>
  )
}

export default HomePage 