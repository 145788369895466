import React from "react";
import AboutHeader from "../components/AboutPage/AboutHeader/AboutHeader";
import AboutMission from "../components/AboutPage/AboutMission/AboutMission";
import AboutComand from "../components/AboutPage/AboutComand/AboutComand";
import AboutFeatures from "../components/AboutPage/AboutFeatures/AboutFeatures";
import AboutContactBlock from "../components/AboutPage/AboutContactBlock/AboutContactBlock";
import Head from '../components/global/Head/Head'
import { useAppSelector } from "./../hooks/redux";
import { translations } from "../constants/translations";
 
const { 
  metaAboutKeyword,
  metaAboutTitle,
  metaAboutDescription
} = translations;
 
const AboutPage = () => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <>
     <Head     
        keyword={metaAboutKeyword[siteLanguage]}
        title={metaAboutTitle[siteLanguage]}
        ogTitle={metaAboutTitle[siteLanguage]}
        description={metaAboutDescription[siteLanguage]}
        ogDescription={metaAboutDescription[siteLanguage]}
      />
      <AboutHeader />
      <AboutMission />
      <AboutComand />
      <AboutFeatures />
      <AboutContactBlock />
    </>
  );
};

export default AboutPage;