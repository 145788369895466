import React from "react";
import { useAppSelector } from "../hooks/redux";
import { translations } from "../constants/translations";

const { 
  LegalInfoPageTitleLg, 
  LegalInfoPageIP,
  LegalInfoPageVAT,
  LegalInfoPageSubtitle1,
  LegalInfoPageSubtitle2,
  LegalInfoPageInfoText
} = translations;

const LegalInfoPage: React.FC = () => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <main className="container doc">
      <header>
        <h1>
          {LegalInfoPageTitleLg[siteLanguage]}
        </h1>
      </header>
      <section>
        <p>
          <strong>L'EMIGRANT Sp. z.o.o</strong>
        </p>
        <p>
          <strong>{LegalInfoPageIP[siteLanguage]}</strong> 5252980934<br />
          <strong>REGON:</strong> 526992520<br />
          <strong>KRS:</strong> 0001070264<br />
          <strong>{LegalInfoPageVAT[siteLanguage]}</strong> PL5252980934 
        </p>
      </section>
      <section>
        <h2>
          {LegalInfoPageSubtitle1[siteLanguage]}
        </h2>
        <p>84 1940 1076 3259 0197 0000 0000</p>
      </section>
      <section>
        <h2>
          {LegalInfoPageSubtitle2[siteLanguage]}
        </h2>
        <p>
        {LegalInfoPageInfoText[siteLanguage]}
        </p>
      </section>
    </main>
  );
};

export default LegalInfoPage;