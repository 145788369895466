import React from "react";

import styles from "./HomeHeader.module.scss";
import MainBtn from "../../layout/MainBtn/MainBtn";
import Svg from "../../layout/Svg/Svg";
import {
  commandIcon,
  complexConceptIcon,
  individualIcon,
} from "../../../assets/svg";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {
  homeHeaderRobotImage,
  homeHeaderRobotWebpImage,
} from "../../../assets/images";
import BgLight from "../../layout/BgLight/BgLight";
import { useNavigate } from "react-router-dom";
import { servicesPagePath } from "../../../router/path";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { useLazy } from "../../../hooks/useLazy";
import { useContentLoaded } from "../../../hooks/useContentLoaded";
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const { homeHeaderSubtitleLgText,
    readMoreText,
    featureItemText1,
    featureItemText2, 
    featureItemText3, 
    homeHeaderTitleLg 
  } = translations;

const features = [
  {
    icon: complexConceptIcon,
    text: featureItemText1,
  },
  {
    icon: commandIcon,
    text: featureItemText2,
  },
  {
    icon: individualIcon,
    text: featureItemText3,
  },
];

const HomeHeader: React.FC = () => {
  const { isInView, ref } = useLazy();
  const navigate = useNavigate();

  const contentLoaded = useContentLoaded();
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);
  
  return (
    <header
      className={`${styles.homeHeader} ${!contentLoaded ? "viewHeight" : ""}`}
    >
      <div className={`container ${styles.homeHeader__container}`} ref={ref}>
        <div className={styles.homeHeader__wrapper}>
          <TransitionProvider
            className={styles.homeHeader__main}
            style={TransitionStyleTypes.left}
            inProp={isInView}
          >
            <h1 className={`titleLg`}>
              {homeHeaderTitleLg[siteLanguage]}
            </h1>
            <h5 className={`subtitleLg`}>            
              {homeHeaderSubtitleLgText[siteLanguage]}            
            </h5>
            <MainBtn isLarge onClick={() => navigate(servicesPagePath)}>
              {readMoreText[siteLanguage]}
            </MainBtn>
          </TransitionProvider>
          <TransitionProvider
            style={TransitionStyleTypes.bottom}
            inProp={isInView}
            className={styles.homeHeader__features}
          >
            {features.map(({ text, icon }, index) => (
              <div className={styles.homeHeader__featureItem} key={index}>
                <div className={styles.homeHeader__featureItemIconWrapper}>
                  <Svg
                    id={icon} 
                    className={styles.homeHeader__featureItemIcon}
                  />
                </div> 
                <p className={styles.homeHeader__featureTxt}>
                  {text[siteLanguage]}
                </p>
              </div>
            ))}
          </TransitionProvider>
          <div className={styles.homeHeader__robotImageWrapper}>
            <ImageWebp
              srcSet={homeHeaderRobotWebpImage}
              src={homeHeaderRobotImage}
              alt={"Robot"}
              className={styles.homeHeader__robotImage}
              pictureClass={styles.homeHeader__robotImagePicture}
              transitionType={TransitionStyleTypes.right}
              isInView={isInView}
            />
          </div>
        </div>
        <TransitionProvider
          inProp={isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.homeHeader__bgLight} />
        </TransitionProvider>
      </div>
    </header>
  );
};

export default HomeHeader;
