import React from "react";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {
  aboutHeaderRobotImage,
  aboutHeaderRobotWebpImage,
} from "../../../assets/images";

import styles from "./AboutHeader.module.scss";
import BgLight from "../../layout/BgLight/BgLight";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { useContentLoaded } from "../../../hooks/useContentLoaded";
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const {
  aboutHeaderTitleLg,
  aboutHeaderSubtitleLg
   
} = translations;

const AboutHeader = () => {
  const { isInView, ref } = useLazy();
    const contentLoaded = useContentLoaded();
    const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <header className={`${styles.aboutHeader} ${!contentLoaded ? "viewHeight" : ""}`} ref={ref}>
      <div className={`container ${styles.aboutHeader__container}`}>
        <TransitionProvider
          inProp={isInView}
          style={TransitionStyleTypes.left}
          className={styles.aboutHeader__main}
        >
          <h1 className={"titleLg"}>
            {aboutHeaderTitleLg[siteLanguage]}
          </h1>
          <h4 className={"subtitleLg"}>
            {aboutHeaderSubtitleLg[siteLanguage]}
          </h4>
        </TransitionProvider>
        <div className={styles.aboutHeader__imgWrapper}>
          <ImageWebp
            src={aboutHeaderRobotImage}
            srcSet={aboutHeaderRobotWebpImage}
            alt="About us"
            isInView={isInView}
            transitionType={TransitionStyleTypes.right}
            className={styles.aboutHeader__img}
            pictureClass={styles.aboutHeader__imgPicture}
          />
        </div>
        <TransitionProvider
          inProp={isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.aboutHeader__bgLight} />
        </TransitionProvider>
      </div>
    </header>
  );
};

export default AboutHeader;