import React, { ReactNode, useEffect, useRef, useState } from "react";

import styles from "./AccordionProvider.module.scss";

type Props = {
  children: ReactNode;
  className?: string;
  innerClass?: string;
  active: boolean;
};

const AccordionProvider = ({
  children,
  active,
  className,
  innerClass,
}: Props) => {
  const wrapperRef = useRef<null | HTMLDivElement>(null);
  const getWrapperHeight = () =>
    wrapperRef.current
      ? wrapperRef.current.firstElementChild?.getBoundingClientRect().height
      : 0;
  const [height, setHeight] = useState(getWrapperHeight());

  useEffect(() => {
    const updatedHeight = getWrapperHeight();
    if (height !== updatedHeight) {
      setHeight(updatedHeight);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={`${styles.accordionProvider}  ${className || ""}`}>
      <div
        style={{ maxHeight: active ? height : 0 }}
        ref={wrapperRef}
        className={`${styles.accordionProvider__inner} ${innerClass || ""}`}
      >
        {children}
      </div>
    </div>
  );
};

export default AccordionProvider;
