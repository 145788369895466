import { translations } from "./translations";

import {
  homeWebsiteLandingImage,
  homeWebsiteLandingWebpImage,
  homeWebsiteMarketplaceImage,
  homeWebsiteMarketplaceWebpImage,
  homeWebsiteMultipageImage,
  homeWebsiteMultipageWebpImage,
  homeWebsiteRedesignImage,
  homeWebsiteRedesignWebpImage,
  homeWebsiteSeoImage,
  homeWebsiteSeoWebpImage,
  landingServiceImage,
  landingServiceWebpImage,
  marketPlaceServiceImage,
  marketPlaceServiceWebpImage,
  multiPageServiceImage,
  multiPageServiceWebpImage,
  redesignServiceImage,
  redesignServiceWebpImage,
  seoServiceImage,
  seoServiceWebpImage,
  homeWebsiteLandingMobImage,
  homeWebsiteLandingMobWebpImage,
  homeWebsiteMarketplaceMobImage,
  homeWebsiteMarketplaceMobWebpImage,
  homeWebsiteMultipageMobImage,
  homeWebsiteMultipageMobWebpImage,
  homeWebsiteRedesignMobImage,
  homeWebsiteRedesignMobWebpImage,
  homeWebsiteSeoMobImage,
  homeWebsiteSeoMobWebpImage,
} from "../assets/images";

export enum EServicePaths {
  landing = "landing",
  marketplace = "marketplace",
  redesign = "redesign",
  seo = "seo",
  multipage = "multipage",
}

const {
  // Accordion  Landing page
  landingName,
  landingDescription,

  landingStep1Name,
  landingStep1Description,

  landingStep2Name,
  landingStep2Description,

  landingStep3Name,
  landingStep3Description,

  landingStep4Name,
  landingStep4Description,

  landingStep5Name,
  landingStep5Description,

  landingStep6Name,
  landingStep6Description,

  landingStep7Name,
  landingStep7Description,

  landingTechnologiesName1,
  landingTechnologiesDescription1,

  landingTechnologiesName2,
  landingTechnologiesDescription2,

  landingTechnologiesName3,
  landingTechnologiesDescription3,

  landingTechnologiesName4,
  landingTechnologiesDescription4,

  landingTechnologiesName5,
  landingTechnologiesDescription5,

  landingTechnologiesName6,
  landingTechnologiesDescription6,

  landingTechnologiesName7,
  landingTechnologiesDescription7,

  // Multi Page website
  multipageName,
  multipageDescription,

  multipageStep1Name,
  multipageStep1Description,

  multipageStep2Name,
  multipageStep2Description,

  multipageStep3Name,
  multipageStep3Description,

  multipageStep4Name,
  multipageStep4Description,

  multipageStep5Name,
  multipageStep5Description,

  multipageStep6Name,
  multipageStep6Description,

  multipageStep7Name,
  multipageStep7Description,

  multipageStep8Name,
  multipageStep8Description,

  multipageStep9Name,
  multipageStep9Description,

  multipageTechnologiesName1,
  multipageTechnologiesDescription1,

  multipageTechnologiesName2,
  multipageTechnologiesDescription2,

  multipageTechnologiesName3,
  multipageTechnologiesDescription3,

  multipageTechnologiesName4,
  multipageTechnologiesDescription4,

  multipageTechnologiesName5,
  multipageTechnologiesDescription5,

  multipageTechnologiesName6,
  multipageTechnologiesDescription6,

  multipageTechnologiesName7,
  multipageTechnologiesDescription7,

  // E-commerce website

  ecommerceName,
  ecommerceDescription,

  ecommerceStep1Name,
  ecommerceStep1Description,

  ecommerceStep2Name,
  ecommerceStep2Description,

  ecommerceStep3Name,
  ecommerceStep3Description,

  ecommerceStep4Name,
  ecommerceStep4Description,

  ecommerceStep5Name,
  ecommerceStep5Description,

  ecommerceStep6Name,
  ecommerceStep6Description,

  ecommerceStep7Name,
  ecommerceStep7Description,

  ecommerceStep8Name,
  ecommerceStep8Description,

  ecommerceStep9Name,
  ecommerceStep9Description,

  ecommerceStep10Name,
  ecommerceStep10Description,

  ecommerceTechnologiesName1,
  ecommerceTechnologiesDescription1,

  ecommerceTechnologiesName2,
  ecommerceTechnologiesDescription2,

  ecommerceTechnologiesName3,
  ecommerceTechnologiesDescription3,

  ecommerceTechnologiesName4,
  ecommerceTechnologiesDescription4,

  ecommerceTechnologiesName5,
  ecommerceTechnologiesDescription5,

  ecommerceTechnologiesName6,
  ecommerceTechnologiesDescription6,

  ecommerceTechnologiesName7,
  ecommerceTechnologiesDescription7,

  ecommerceTechnologiesName8,
  ecommerceTechnologiesDescription8,

  // Redesign
  redesignName,
  redesignDescription,

  redesignStep1Name,
  redesignStep1Description,

  redesignStep2Name,
  redesignStep2Description,

  redesignStep3Name,
  redesignStep3Description,

  redesignStep4Name,
  redesignStep4Description,

  redesignStep5Name,
  redesignStep5Description,

  redesignStep6Name,
  redesignStep6Description,

  redesignStep7Name,
  redesignStep7Description,

  redesignStep8Name,
  redesignStep8Description,

  redesignTechnologiesName1,
  redesignTechnologiesDescription1,

  redesignTechnologiesName2,
  redesignTechnologiesDescription2,

  redesignTechnologiesName3,
  redesignTechnologiesDescription3,

  redesignTechnologiesName4,
  redesignTechnologiesDescription4,

  redesignTechnologiesName5,
  redesignTechnologiesDescription5,

  redesignTechnologiesName6,
  redesignTechnologiesDescription6,

  // SEO
  seoName,
  seoDescription,

  seoStep1Name,
  seoStep1Description,

  seoStep2Name,
  seoStep2Description,

  seoStep3Name,
  seoStep3Description,

  seoStep4Name,
  seoStep4Description,

  seoStep5Name,
  seoStep5Description,

  seoStep6Name,
  seoStep6Description,

  seoStep7Name,
  seoStep7Description,

  seoStep8Name,
  seoStep8Description,

  seoStep9Name,
  seoStep9Description,

  seoTechnologiesName1,
  seoTechnologiesDescription1,

  seoTechnologiesName2,
  seoTechnologiesDescription2,

  seoTechnologiesName3,
  seoTechnologiesDescription3,

  seoTechnologiesName4,
  seoTechnologiesDescription4,

  seoTechnologiesName5,
  seoTechnologiesDescription5,
} = translations;

export const SERVICES = [
  {
    path: EServicePaths.landing,
    order: {
      home: 1,
      services: 1,
    },
    //name: ["Landing", " Page"],
    name: landingName,
    description: landingDescription,
    homeImg: [homeWebsiteLandingImage, homeWebsiteLandingWebpImage],
    homeMobImg: [homeWebsiteLandingMobImage, homeWebsiteLandingMobWebpImage],
    mainImg: [landingServiceImage, landingServiceWebpImage],
    steps: [
      {
        name: landingStep1Name,
        description: landingStep1Description,
      },
      {
        name: landingStep2Name,
        description: landingStep2Description,
      },
      {
        name: landingStep3Name,
        description: landingStep3Description,
      },
      {
        name: landingStep4Name,
        description: landingStep4Description,
      },
      {
        name: landingStep5Name,
        description: landingStep5Description,
      },
      {
        name: landingStep6Name,
        description: landingStep6Description,
      },
      {
        name: landingStep7Name,
        description: landingStep7Description,
      },
    ],
    technologies: [
      {
        name: landingTechnologiesName1,
        description: landingTechnologiesDescription1,
      },
      {
        name: landingTechnologiesName2,
        description: landingTechnologiesDescription2,
      },
      {
        name: landingTechnologiesName3,
        description: landingTechnologiesDescription3,
      },
      {
        name: landingTechnologiesName4,
        description: landingTechnologiesDescription4,
      },
      {
        name: landingTechnologiesName5,
        description: landingTechnologiesDescription5,
      },
      {
        name: landingTechnologiesName6,
        description: landingTechnologiesDescription6,
      },
      {
        name: landingTechnologiesName7,
        description: landingTechnologiesDescription7,
      },
    ],
  },
  {
    order: {
      home: 2,
      services: 3,
    },
    path: EServicePaths.marketplace,
    //name: ["Интернет", "-магазин"],
    name: ecommerceName,
    description: ecommerceDescription,
    homeImg: [homeWebsiteMarketplaceImage, homeWebsiteMarketplaceWebpImage],
    homeMobImg: [
      homeWebsiteMarketplaceMobImage,
      homeWebsiteMarketplaceMobWebpImage,
    ],
    mainImg: [marketPlaceServiceImage, marketPlaceServiceWebpImage],
    steps: [
      {
        name: ecommerceStep1Name,
        description: ecommerceStep1Description,
      },
      {
        name: ecommerceStep2Name,
        description: ecommerceStep2Description,
      },
      {
        name: ecommerceStep3Name,
        description: ecommerceStep3Description,
      },
      {
        name: ecommerceStep4Name,
        description: ecommerceStep4Description,
      },
      {
        name: ecommerceStep5Name,
        description: ecommerceStep5Description,
      },
      {
        name: ecommerceStep6Name,
        description: ecommerceStep6Description,
      },
      {
        name: ecommerceStep7Name,
        description: ecommerceStep7Description,
      },
      {
        name: ecommerceStep8Name,
        description: ecommerceStep8Description,
      },
      {
        name: ecommerceStep9Name,
        description: ecommerceStep9Description,
      },
      {
        name: ecommerceStep10Name,
        description: ecommerceStep10Description,
      },
    ],
    technologies: [
      {
        name: ecommerceTechnologiesName1,
        description: ecommerceTechnologiesDescription1,
      },
      {
        name: ecommerceTechnologiesName2,
        description: ecommerceTechnologiesDescription2,
      },
      {
        name: ecommerceTechnologiesName3,
        description: ecommerceTechnologiesDescription3,
      },
      {
        name: ecommerceTechnologiesName4,
        description: ecommerceTechnologiesDescription4,
      },
      {
        name: ecommerceTechnologiesName5,
        description: ecommerceTechnologiesDescription5,
      },
      {
        name: ecommerceTechnologiesName6,
        description: ecommerceTechnologiesDescription6,
      },
      {
        name: ecommerceTechnologiesName7,
        description: ecommerceTechnologiesDescription7,
      },
      {
        name: ecommerceTechnologiesName8,
        description: ecommerceTechnologiesDescription8,
      },
    ],
  },
  {
    path: EServicePaths.redesign,
    order: {
      home: 3,
      services: 4,
    },
    //name: ["Ремонт и редизайн", " существующих сайтов"],
    name: redesignName,
    description: redesignDescription,
    homeImg: [homeWebsiteRedesignImage, homeWebsiteRedesignWebpImage],
    homeMobImg: [homeWebsiteRedesignMobImage, homeWebsiteRedesignMobWebpImage],
    mainImg: [redesignServiceImage, redesignServiceWebpImage],
    steps: [
      {
        name: redesignStep1Name,
        description: redesignStep1Description,
      },
      {
        name: redesignStep2Name,
        description: redesignStep2Description,
      },
      {
        name: redesignStep3Name,
        description: redesignStep3Description,
      },
      {
        name: redesignStep4Name,
        description: redesignStep4Description,
      },
      {
        name: redesignStep5Name,
        description: redesignStep5Description,
      },
      {
        name: redesignStep6Name,
        description: redesignStep6Description,
      },
      {
        name: redesignStep7Name,
        description: redesignStep7Description,
      },
      {
        name: redesignStep8Name,
        description: redesignStep8Description,
      },
    ],
    technologies: [
      {
        name: redesignTechnologiesName1,
        description: redesignTechnologiesDescription1,
      },
      {
        name: redesignTechnologiesName2,
        description: redesignTechnologiesDescription2,
      },
      {
        name: redesignTechnologiesName3,
        description: redesignTechnologiesDescription3,
      },
      {
        name: redesignTechnologiesName4,
        description: redesignTechnologiesDescription4,
      },
      {
        name: redesignTechnologiesName5,
        description: redesignTechnologiesDescription5,
      },
      {
        name: redesignTechnologiesName6,
        description: redesignTechnologiesDescription6,
      },
    ],
  },
  {
    path: EServicePaths.seo,
    order: {
      home: 4,
      services: 5,
    },
    // name: ["Оптимизация", " для поисковых систем (SEO)"],
    name: seoName,
    description: seoDescription,
    homeImg: [homeWebsiteSeoImage, homeWebsiteSeoWebpImage],
    homeMobImg: [homeWebsiteSeoMobImage, homeWebsiteSeoMobWebpImage],
    mainImg: [seoServiceImage, seoServiceWebpImage],
    steps: [
      {
        name: seoStep1Name,
        description: seoStep1Description,
      },
      {
        name: seoStep2Name,
        description: seoStep2Description,
      },
      {
        name: seoStep3Name,
        description: seoStep3Description,
      },
      {
        name: seoStep4Name,
        description: seoStep4Description,
      },
      {
        name: seoStep5Name,
        description: seoStep5Description,
      },
      {
        name: seoStep6Name,
        description: seoStep6Description,
      },
      {
        name: seoStep7Name,
        description: seoStep7Description,
      },
      {
        name: seoStep8Name,
        description: seoStep8Description,
      },
      {
        name: seoStep9Name,
        description: seoStep9Description,
      },
    ],
    technologies: [
      {
        name: seoTechnologiesName1,
        description: seoTechnologiesDescription1,
      },
      {
        name: seoTechnologiesName2,
        description: seoTechnologiesDescription2,
      },
      {
        name: seoTechnologiesName3,
        description: seoTechnologiesDescription3,
      },
      {
        name: seoTechnologiesName4,
        description: seoTechnologiesDescription4,
      },
      {
        name: seoTechnologiesName5,
        description: seoTechnologiesDescription5,
      },
    ],
  },
  {
    order: {
      home: 5,
      services: 2,
    },
    path: EServicePaths.multipage,
    // name: ["Многостраничный", " сайт"],
    name: multipageName,
    description: multipageDescription,
    homeImg: [homeWebsiteMultipageImage, homeWebsiteMultipageWebpImage],
    homeMobImg: [
      homeWebsiteMultipageMobImage,
      homeWebsiteMultipageMobWebpImage,
    ],
    mainImg: [multiPageServiceImage, multiPageServiceWebpImage],
    steps: [
      {
        name: multipageStep1Name,
        description: multipageStep1Description,
      },
      {
        name: multipageStep2Name,
        description: multipageStep2Description,
      },
      {
        name: multipageStep3Name,
        description: multipageStep3Description,
      },
      {
        name: multipageStep4Name,
        description: multipageStep4Description,
      },
      {
        name: multipageStep5Name,
        description: multipageStep5Description,
      },
      {
        name: multipageStep6Name,
        description: multipageStep6Description,
      },
      {
        name: multipageStep7Name,
        description: multipageStep7Description,
      },
      {
        name: multipageStep8Name,
        description: multipageStep8Description,
      },
      {
        name: multipageStep9Name,
        description: multipageStep9Description,
      },
    ],
    technologies: [
      {
        name: multipageTechnologiesName1,
        description: multipageTechnologiesDescription1,
      },
      {
        name: multipageTechnologiesName2,
        description: multipageTechnologiesDescription2,
      },
      {
        name: multipageTechnologiesName3,
        description: multipageTechnologiesDescription3,
      },
      {
        name: multipageTechnologiesName4,
        description: multipageTechnologiesDescription4,
      },
      {
        name: multipageTechnologiesName5,
        description: multipageTechnologiesDescription5,
      },
      {
        name: multipageTechnologiesName6,
        description: multipageTechnologiesDescription6,
      },
      {
        name: multipageTechnologiesName7,
        description: multipageTechnologiesDescription7,
      },
    ],
  },
];
