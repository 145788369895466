import React from "react";
import { useAppSelector } from "../hooks/redux";
import { translations } from "../constants/translations";

const { 
  cookiePolicyPageTitleLg,
  cookiePolicyPageTitleLgText,

  cookiePolicyPageTitle1, 
  cookiePolicyPageText1,
  
  cookiePolicyPageTitle2, 
  cookiePolicyPageListTitle,
  cookiePolicyPageText2,

  cookiePolicyPageTitle3, 
  cookiePolicyPageText3,

  cookiePolicyPageTitle4, 
  cookiePolicyPageText4Paragraph1, 
  cookiePolicyPageText4Paragraph2,
  cookiePolicyPageText4Paragraph3,

} = translations;

const CookiePolicyPage: React.FC = () => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  const cookiesTypeList = cookiePolicyPageText2[siteLanguage];

  return (
    <main className="container doc">
      <header>
        <h1>
          {cookiePolicyPageTitleLg[siteLanguage]}
          </h1> 
        <p>
          {cookiePolicyPageTitleLgText[siteLanguage]}
        </p>
      </header>
      <section>
        <h2>
          {cookiePolicyPageTitle1[siteLanguage]}
        </h2>
        <p>
          {cookiePolicyPageText1[siteLanguage]}  
        </p>         
        <h2>
          {cookiePolicyPageTitle2[siteLanguage]}
        </h2>
        <h3>
          {cookiePolicyPageListTitle[siteLanguage]}
        </h3>
        <ul>
          {cookiesTypeList.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
        <h2>
          {cookiePolicyPageTitle3[siteLanguage]}
        </h2>
        <p>
          {cookiePolicyPageText3[siteLanguage]}  
        </p>   
        <h2>
          {cookiePolicyPageTitle4[siteLanguage]}
        </h2>
        <p>
          {cookiePolicyPageText4Paragraph1[siteLanguage]}  
        </p>    
        <p>
          {cookiePolicyPageText4Paragraph2[siteLanguage]}  
        </p>  
        <p>
          {cookiePolicyPageText4Paragraph3[siteLanguage]}  
        </p>  
      </section>   
    </main>
  );
};

export default CookiePolicyPage;