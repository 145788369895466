import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch } from "../../hooks/redux";
import AppRouter from "../../router/AppRouter";
import Footer from "../global/Footer/Footer";
import Navbar from "../global/Navbar/Navbar";
import { getLSItem, setLSItem } from "../../utils/functions/localStorage";
import { lsProps } from "../../utils/lsProps";
import { setSiteLangauge } from "../../store/slices/UISlice";
import { scrollTop } from "../../utils/functions/scrollTop";
import { Elanguages, languagesArr } from "../../constants/Elanguages";
import CookiesModal from "../global/CookiesModal/CookiesModal";

function App() {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [cookiesModalOpened, setCookiesModalOpened] = useState(false);

  useEffect(() => {
    const language = getLSItem(lsProps.language) as Elanguages;
    const navigatorLang = navigator.language
      .slice(0, 2)
      .toUpperCase() as Elanguages;
    if (language && languagesArr.includes(language))
      dispatch(setSiteLangauge(language));
    else if (languagesArr.includes(navigatorLang)) {
      dispatch(setSiteLangauge(navigatorLang));
      setLSItem(lsProps.language, navigatorLang);
    }

    // open cookies modal
    setTimeout(() => {
      setCookiesModalOpened(true);
    }, 3000);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    scrollTop();
  }, [location]);

  return (
    <>
      <Navbar />
      <AppRouter />
      <Footer />
      <CookiesModal
        openState={cookiesModalOpened}
        onClose={() => setCookiesModalOpened(false)}
      />
    </>
  );
}

export default App;
