import React from "react";

import styles from "./ServicesHeader.module.scss";
import BgLight from "../../layout/BgLight/BgLight";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const {  
  servicesHeaderTitleLg,
  servicesHeaderSubtitleLg,
} = translations;

interface Props {}

const ServicesHeader: React.FC<Props> = (props) => {
  const { isInView, ref } = useLazy(0.1);
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <header ref={ref} className={styles.servicesHeader}>
      <div className={`container ${styles.servicesHeader__container}`}>
        <TransitionProvider
          inProp={true}
          style={TransitionStyleTypes.left}
          className={styles.servicesHeader__main}
        >
          <h1 className={"titleLg"}>
           {servicesHeaderTitleLg[siteLanguage]}
          </h1>
          <h4 className={"subtitleLg"}>
            {servicesHeaderSubtitleLg[siteLanguage]}
          </h4>
        </TransitionProvider>
        <TransitionProvider
          inProp={isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.servicesHeader__bgLight} />
        </TransitionProvider>
      </div>
    </header>
  );
};

export default ServicesHeader;