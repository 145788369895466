import React, { FC, useState } from "react";

import styles from "./ImageWebp.module.scss";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

const imageLoadTypes = {
  opacity: {
    key: "opacity",
    values: [0, 1],
  },
  width: {
    key: "width",
    values: ["0%", "100%"],
  },
};

interface ImageWebpProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  srcSet: string;
  src: string;
  pictureClass?: string;
  alt: string;
  useLoader?: boolean;
  isInView?: boolean;
  transitionType?: TransitionStyleTypes;
  imageLoadType?: keyof typeof imageLoadTypes;
}

const ImageWebp: FC<ImageWebpProps> = ({
  srcSet,
  pictureClass,
  alt,
  src,
  useLoader, // default true
  isInView,
  transitionType,
  imageLoadType,
  ...properties
}) => {
  useLoader = useLoader === undefined ? true : useLoader;
  const [loading, setLoading] = useState(useLoader);
  imageLoadType = imageLoadType || "opacity";


const curLoadType = imageLoadTypes[imageLoadType]

  const mainContent = (
    <>
      {" "}
      <source srcSet={srcSet} type="image/webp" />
      <img
        style={{
          [curLoadType.key]: loading
            ? curLoadType.values[0]
            : curLoadType.values[1],
        }}
        alt={alt}
        src={src}
        onLoad={useLoader ? () => setLoading(false) : undefined}
        {...properties}
      />
    </>
  );

  const pictureClassProp = `${styles.imageWebp} ${
    useLoader ? styles.imageWebp_loader : ""
  } ${pictureClass ? pictureClass : ""}`;


  return (
    <>
      {typeof isInView === "boolean" ? (
        <TransitionProvider
          isPicture
          className={pictureClassProp}
          style={transitionType || TransitionStyleTypes.right}
          inProp={isInView}
        >
          {mainContent}
        </TransitionProvider>
      ) : (
        <picture className={pictureClassProp}>{mainContent}</picture>
      )}
    </>
  );
};

export default ImageWebp;
