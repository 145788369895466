import React from "react";

import styles from "./AboutComand.module.scss";
import {
  aboutCommandAutomatisationImage,
  aboutCommandAutomatisationWebpImage,
  aboutCommandDevelopersImage,
  aboutCommandDevelopersWebpImage,
  aboutCommandMarketologsImage,
  aboutCommandMarketologsWebpImage,
} from "../../../assets/images";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import BgLight from "../../layout/BgLight/BgLight";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const { 
  aboutComandTitleLg,
  aboutComandSubtitleLg,
  aboutComandDevelopers,
  aboutComandDevelopersDescription,
  aboutComandMarketers,
  aboutComandrMarketersDescription,
  aboutComandAutomation,
  aboutComandAutomationDescription    
} = translations;

interface Props {}

const AboutComand: React.FC<Props> = (props) => {
  const { isInView, ref } = useLazy(0.8);
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage)

  const commandSections = [
    {
      name: aboutComandDevelopers[siteLanguage],
      description: aboutComandDevelopersDescription[siteLanguage],
      image: [aboutCommandDevelopersImage, aboutCommandDevelopersWebpImage],
      transitiontype: TransitionStyleTypes.left,
    },
    {
      name: aboutComandMarketers[siteLanguage],
      description: aboutComandrMarketersDescription[siteLanguage],
      image: [aboutCommandMarketologsImage, aboutCommandMarketologsWebpImage],
      transitiontype: TransitionStyleTypes.bottom,
    },
    {
      name: aboutComandAutomation[siteLanguage],
      description:aboutComandAutomationDescription[siteLanguage],
      image: [
        aboutCommandAutomatisationImage,
        aboutCommandAutomatisationWebpImage,
      ],
      transitiontype: TransitionStyleTypes.right,
    },
  ];

  return (
    <section className={styles.aboutComand} ref={ref}>
      <div className={`container ${styles.aboutComand__container}`}>
        <TransitionProvider
          style={TransitionStyleTypes.top}
          inProp={isInView}
          className={styles.aboutComand__titleBlock}
        >
          <h2 className={"titleLg"}>
            {aboutComandTitleLg[siteLanguage]}
          </h2>
          <h4 className={"subtitleLg"}>
            {aboutComandSubtitleLg[siteLanguage]}            
          </h4>
        </TransitionProvider>
        <div className={styles.aboutComand__main}>
          {commandSections.map(
            ({ name, description, image, transitiontype }, index) => (
              <TransitionProvider
                inProp={isInView}
                style={transitiontype}
                className={`${styles.aboutComand__section} ${
                  index % 2 ? styles.aboutComand__section_reversed : ""
                }`}
                key={index}
              >
                <div className={styles.aboutComand__sectionTexts}>
                  <div className={styles.aboutComand__sectionTextsWrapper}>
                    <h5 className={styles.aboutComand__sectionTitle}>{name}</h5>
                    <p className={styles.aboutComand__sectionDescription}>
                      {description}
                    </p>
                  </div>
                </div>
                <ImageWebp
                  src={image[0]}
                  srcSet={image[1]}
                  alt={name}
                  className={styles.aboutComand__sectionImg}
                  pictureClass={styles.aboutComand__sectionImgWrapper}
                />
              </TransitionProvider>
            )
          )}
        </div>
        <TransitionProvider
          inProp={isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.aboutComand__bgLight1} />
          <BgLight className={styles.aboutComand__bgLight2} />
        </TransitionProvider>
      </div>
    </section>
  );
};

export default AboutComand;