import { Elanguages } from "./Elanguages";

export const mailAddress = "info@webscover.com";
export const phoneNumber = "+48 793 355 999";

export const contactFormLinks = {
  [Elanguages.RU]: "https://wkf.ms/41CTqG7",
  [Elanguages.EN]: "https://wkf.ms/4gSZkrh",
  [Elanguages.ES]: "https://wkf.ms/49Bt1ui",
  [Elanguages.PL]: "https://wkf.ms/3ZQJU0K",
};
