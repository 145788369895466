import React from 'react';

import styles from "./BgLight.module.scss";

interface Props {
  className?: string,
}

const BgLight: React.FC<Props> = ({className}) => {
  return <div className={`${styles.bgLight} ${className || ""}`}></div>;
};

export default BgLight;