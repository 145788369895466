export const arrowDownIcon = "arrowDown"
export const individualIcon = "individual"
export const whatsappIcon = "whatsapp"
export const fbIcon = "fb"
export const instagramIcon = "instagram"
export const mailIcon = "mail"
export const phoneIcon = "phone"
export const starIcon = "star"
export const chatBotAnaliticsIcon = "chatBotAnalitics"
export const chatBotSettingsIcon = "chatBotSettings"
export const chatBotCRMIcon = "chatBotCRM"
export const arrowLeftIcon = "arrowLeft"
export const complexConceptIcon = "complexConcept"
export const commandIcon = "command"
export const burgerIcon = "burger"
export const crossIcon = "cross"
export const quoteIcon = "quote"
export const sliderArrowLeftIcon = "sliderArrowLeft"
export const sliderArrowRightIcon = "sliderArrowRight"