import React, { FC } from "react";

import styles from "./ProjectsCompletedCases.module.scss";
import { COMPLETED_CASES } from "./complatedCases";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";
import { Elanguages } from "../../../constants/Elanguages";

type CompletedCaseItemProps = (typeof COMPLETED_CASES)[number] & {
  index: number;
  siteLanguage: Elanguages;
};

const { projectsCompletedCasesTitle, projectsCompletedCasesResultTitle } =
  translations;

interface Props {}

const CompletedCaseItem: FC<CompletedCaseItemProps> = ({
  name,
  subtitle,
  description,
  steps,
  result,
  image,
  index,
  siteLanguage,
}) => {
  const { isInView, ref } = useLazy(0.5);

  return (
    <div
      ref={ref}
      className={`${styles.projectsCompletedCases__item} ${
        index % 2 ? styles.projectsCompletedCases__item_reverse : ""
      }`}
      key={index}
    >
      <div
        className={`container ${styles.projectsCompletedCases__itemContainer}`}
      >
        <TransitionProvider
          inProp={isInView}
          style={TransitionStyleTypes.left}
          className={styles.projectsCompletedCases__descriptionBlock}
        >
          <h4 className={styles.projectsCompletedCases__projectNameTxt}>
            {name}
          </h4>
          <h6 className={styles.projectsCompletedCases__projectSubtitle}>
            {subtitle[siteLanguage]}
          </h6>
          <p className={styles.projectsCompletedCases__descriptionTxt}>
            {description[siteLanguage]}
          </p>
          <div className={styles.projectsCompletedCases__imgWrapper}>
            <ImageWebp
              src={image[0]}
              srcSet={image[1]}
              alt={name}
              imageLoadType="width"
              className={styles.projectsCompletedCases__img}
            />
          </div>
        </TransitionProvider>
        <div className={styles.projectsCompletedCases__analizeBlock}>
          <div className={styles.projectsCompletedCases__stepsList}>
            {steps.map((step, stepIndex) => (
              <TransitionProvider
                inProp={isInView}
                style={TransitionStyleTypes.bottom}
                delay={stepIndex * 100}
                className={styles.projectsCompletedCases__stepsItem}
                key={stepIndex}
              >
                <h6 className={styles.projectsCompletedCases__stepsIndexTxt}>
                  0{stepIndex + 1}.
                </h6>
                <h6 className={styles.projectsCompletedCases__stepTitle}>
                  {step.name[siteLanguage]}
                </h6>
                <p
                  className={styles.projectsCompletedCases__stepDescriptionTxt}
                >
                  {step.description[siteLanguage]}
                </p>
              </TransitionProvider>
            ))}
          </div>
          <TransitionProvider
            inProp={isInView}
            style={TransitionStyleTypes.bottom}
            className={styles.projectsCompletedCases__result}
          >
            <h6 className={styles.projectsCompletedCases__resultTitle}>
              {projectsCompletedCasesResultTitle[siteLanguage]}
            </h6>
            <p className={styles.projectsCompletedCases__description}>
              {result[siteLanguage].map((res, resIndex) => (
                <span key={resIndex}>{res}</span>
              ))}
            </p>
          </TransitionProvider>
        </div>
      </div>
    </div>
  );
};

const ProjectsCompletedCases: React.FC<Props> = (props) => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);
  return (
    <section className={styles.projectsCompletedCases}>
      <div className="container">
        <h3 className={`titleLg ${styles.projectsCompletedCases__title}`}>
          {projectsCompletedCasesTitle[siteLanguage]}
        </h3>
      </div>
      <div className={styles.projectsCompletedCases__main}>
        {COMPLETED_CASES.map((item, index) => (
          <CompletedCaseItem
            key={index}
            index={index}
            siteLanguage={siteLanguage}
            {...item}
          />
        ))}
      </div>
    </section>
  );
};

export default ProjectsCompletedCases;