import React, { useState } from "react";
import { Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import styles from "./ProjectsReviews.module.scss";
import Svg from "../../layout/Svg/Svg";
import { quoteIcon } from "../../../assets/svg";

import "swiper/css/pagination";
import "./sliderDots.scss";
import BgLight from "../../layout/BgLight/BgLight";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

interface Props {}

const { 
  projectsReviewsTitle,
  projectsReviewsReviewer1,
  projectsReviewsReviewer2,
  projectsReviewsReviewer3,
  projectsReviewsReviewer4,
  projectsReviewsTeam
   
} = translations;


const ProjectsReviews: React.FC<Props> = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const { isInView, ref } = useLazy(0.5);

  const handleSlideChange = (swiper: any) => {
    setActiveIndex(swiper.activeIndex);
  };

  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage)

  const reviews = [
    {
      text: projectsReviewsReviewer1[siteLanguage],
      reviewer:
      siteLanguage === "EN"
        ? `~ DocPol ${projectsReviewsTeam[siteLanguage]}`
        : `~ ${projectsReviewsTeam[siteLanguage]} DocPol`,
  },
    {
      text: projectsReviewsReviewer2[siteLanguage],
      reviewer:
      siteLanguage === "EN"
        ? `~ L’EMIGRANT ${projectsReviewsTeam[siteLanguage]}`
        : `~ ${projectsReviewsTeam[siteLanguage]} L’EMIGRANT`,
  },
    {
      text: projectsReviewsReviewer3[siteLanguage],
      reviewer:
      siteLanguage === "EN"
        ? `~ Reality Bulgaria ${projectsReviewsTeam[siteLanguage]}`
        : `~ ${projectsReviewsTeam[siteLanguage]} Reality Bulgaria`,
  },
    {
      text: projectsReviewsReviewer4[siteLanguage],
      reviewer:
      siteLanguage === "EN"
        ? `~ Rise Partner ${projectsReviewsTeam[siteLanguage]}`
        : `~ ${projectsReviewsTeam[siteLanguage]} Rise Partner`,
  },
  ];

  return (
    <section className={styles.projectsReviews} ref={ref}>
      <TransitionProvider
        inProp={isInView}
        style={TransitionStyleTypes.top}
        className={`container ${styles.projectsReviews__container}`}
      >
        <h3 className={`titleLg ${styles.projectsReviews__title}`}>
          {projectsReviewsTitle[siteLanguage]}
        </h3>
        <BgLight className={styles.projectsReviews__bgLight} />
      </TransitionProvider>
      <TransitionProvider inProp={isInView} style={TransitionStyleTypes.zoomIn}>
        <Swiper
          onSlideChange={handleSlideChange}
          modules={[Pagination]}
          pagination={{ clickable: true }}
          spaceBetween={60}
          slidesPerView={1}
          breakpoints={{
            1024: {
              slidesPerView: 2,
            },
          }}
          className={`${window.innerWidth < 1420 ? "container" : ""} ${
            styles.projectsReviews__slider
          }`}
        >
          {reviews.map(({ text, reviewer }, index) => (
            <SwiperSlide key={index}>
              <div
                className={`${styles.projectsReviews__slide} ${
                  index < activeIndex || index > activeIndex + 1
                    ? styles.projectsReviews__slide_small
                    : ""
                }`}
              >
                <Svg
                  id={quoteIcon}
                  className={styles.projectsReviews__quoteIcon}
                />
                <p className={styles.projectsReviews__text}>{text}</p>
                <h6 className={styles.projectsReviews__reviewerName}>
                  {reviewer}
                </h6>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </TransitionProvider>
    </section>
  );
};

export default ProjectsReviews;