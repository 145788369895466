import styles from "./Topbar.module.scss";
import { Link, useLocation } from "react-router-dom";

import ImageWebp from "../../../layout/ImageWebp/ImageWebp";

import { logoImage, logoWebpImage } from "../../../../assets/images";

import { homePagePath } from "../../../../router/path";
import Select from "../../../layout/Select/Select";
import { ISelectValue } from "../../../../models/UI/ISelectValue";
import { Elanguages, languagesArr } from "../../../../constants/Elanguages";
import { useAppDispatch, useAppSelector } from "../../../../hooks/redux";
import { changeLanguage } from "../../../../store/slices/UISlice";
import { translations } from "../../../../constants/translations";
import { menuItems } from "../../../../constants/menuItems";
import { NavLink } from "react-router-dom";
import MainBtn from "../../../layout/MainBtn/MainBtn";
import Svg from "../../../layout/Svg/Svg";
import { burgerIcon } from "../../../../assets/svg";
import { useEffect, useRef, useState } from "react";
import { contactFormLinks } from "../../../../constants/contacts";

type Props = {
  openSidebar: () => void;
};

const Topbar = ({ openSidebar }: Props) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);
  const { contactUsText } = translations.menu;
  const [isOnTop, setIsOnTop] = useState(false);
  const isOntopRef = useRef(isOnTop);

  const setActiveNavLinkClass = () => {
    return ({ isActive }: { isActive: boolean }) =>
      isActive
        ? `${styles.topbar__menuLink} ${styles.topbar__menuLink_active}`
        : styles.topbar__menuLink;
  };

  useEffect(() => {
    isOntopRef.current = isOnTop;
  }, [isOnTop]);

  const checkIsOnTop = () => {
    if (window.scrollY === 0) {
      setIsOnTop(true);
    } else if (isOntopRef.current) setIsOnTop(false);
  };

  useEffect(() => {
    checkIsOnTop();
  }, [location.pathname]);

  useEffect(() => {
    window.addEventListener("scroll", checkIsOnTop);

    return () => {
      window.removeEventListener("scroll", checkIsOnTop);
    };
  }, []);

  const activeNavLink = setActiveNavLinkClass();

  const languageOptions: ISelectValue[] = languagesArr.map((item) => ({
    value: item,
    name: item,
  }));

  return (
    <div className={`${styles.topbar} ${!isOnTop ? styles.topbar_fixed : ""}`}>
      <section className={`container ${styles.topbar__container}`}>
        <div className={styles.topbar__wrapper}>
          <Link to={homePagePath} className={styles.topbar__logoLink}>
            <ImageWebp
              src={logoImage}
              srcSet={logoWebpImage}
              alt="logo"
              className={styles.topbar__logoImg}
            />
          </Link>
          <nav className={styles.topbar__menu}>
            {menuItems.map(({ link, text }, index) => (
              <NavLink to={link} key={index} className={activeNavLink}>
                {text[siteLanguage]}
              </NavLink>
            ))}
          </nav>
          <div className={styles.topbar__options}>
            <Select
              values={languageOptions}
              onChange={(value) =>
                dispatch(changeLanguage(value as Elanguages))
              }
              selectedValue={siteLanguage}
            />
            <MainBtn
              asLink
              href={contactFormLinks[siteLanguage]}
              target="_blank"
              rel="noreferrer"
              className={styles.topbar__contactBtn}
            >
              {contactUsText[siteLanguage]}
            </MainBtn>
            <button className={styles.topbar__burgerBtn} onClick={openSidebar}>
              <Svg id={burgerIcon} className={styles.topbar__burgerIcon}></Svg>
            </button>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Topbar;
