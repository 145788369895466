import React from "react";

import styles from "./CookiesModal.module.scss";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import MainBtn from "../../layout/MainBtn/MainBtn";
import { useAppSelector } from "./../../../hooks/redux";
import { translations } from "../../../constants/translations";
import NewPortalProvider from "../../../providers/NewPortalProvider";

const { cookiesModalТеxt, cookiesModalBtnAccept, cookiesModalBtnReject } =
  translations;

interface Props {
  openState: boolean;
  onClose: () => void;
}

const CookiesModal: React.FC<Props> = ({ openState, onClose }) => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <NewPortalProvider>
      <TransitionProvider
        inProp={openState}
        style={TransitionStyleTypes.opacity}
        className={styles.cookiesModal}
      >
        <div className={`${styles.cookiesModal__container} container`}>
          <div className={styles.cookiesModal__main}>
            <p className={styles.cookiesModal__txt}>
              <span>{cookiesModalТеxt[siteLanguage]}</span>
            </p>
            <div className={styles.cookiesModal__btns}>
              <MainBtn
                isLarge
                className={styles.cookiesModal__aceptBtn}
                onClick={onClose}
              >
                {cookiesModalBtnAccept[siteLanguage]}
              </MainBtn>
              <button onClick={onClose} className={styles.cookiesModal__link}>
                {cookiesModalBtnReject[siteLanguage]}
              </button>
            </div>
          </div>
        </div>
      </TransitionProvider>
    </NewPortalProvider>
  );
};

export default CookiesModal;
