import { translations } from "../../../constants/translations";

import {
  projectCasesDocPolImage,
  projectCasesDocPolWebpImage,
  projectCasesLemigrantImage,
  projectCasesLemigrantWebpImage,
  projectCasesRealityBulgariaImage,
  projectCasesRealityBulgariaWebpImage,
  projectCasesRisePartnerImage,
  projectCasesRisePartnerWebpImage,
} from "../../../assets/images";

const { 
   completedCasesSubtitle,
   risePartnerDescription,

   risePartnerStep1Name,
   risePartnerStep1Description,

   risePartnerStep2Name,
   risePartnerStep2Description,

   risePartnerStep3Name,
   risePartnerStep3Description,

   risePartnerStep4Name,
   risePartnerStep4Description,

   risePartnerStep5Name,
   risePartnerStep5Description,

   risePartnerStep6Name,
   risePartnerStep6Description, 
   risePartnerResultText, 

   realityBulgariaDescription,

   realityBulgariaStep1Name,
   realityBulgariaStep1Description,

   realityBulgariaStep2Name,
   realityBulgariaStep2Description,

   realityBulgariaStep3Name,
   realityBulgariaStep3Description,

   realityBulgariaStep4Name,
   realityBulgariaStep4Description,

   realityBulgariaStep5Name,
   realityBulgariaStep5Description,

   realityBulgariaStep6Name,
   realityBulgariaStep6Description,
   realityBulgariaResultText,

   lemignrantDescription,
   lemignrantStep1Name,
   lemignrantStep1Description,

   lemignrantStep2Name,
   lemignrantStep2Description,

   lemignrantStep3Name,
   lemignrantStep3Description,

   lemignrantStep4Name,
   lemignrantStep4Description,

   lemignrantStep5Name,
   lemignrantStep5Description,
   lemignrantResultText,

   docPolDescription,
   docPolStep1Name,
   docPolStep1Description, 

   docPolStep2Name,
   docPolStep2Description,

   docPolStep3Name,
   docPolStep3Description,

   docPolStep4Name,
   docPolStep4Description,
   docPolResultText,
   
} = translations;

 
export const COMPLETED_CASES = [  
  {
    name: "Rise Partner",
    subtitle: completedCasesSubtitle,
    description: risePartnerDescription,
    image: [projectCasesRisePartnerImage, projectCasesRisePartnerWebpImage],
    steps: [
      {
        name: risePartnerStep1Name,
        description: risePartnerStep1Description,
      },
      {
        name: risePartnerStep2Name,
        description: risePartnerStep2Description,
      },
      {
        name: risePartnerStep3Name,
        description: risePartnerStep3Description,
      },
      {
        name: risePartnerStep4Name,
        description: risePartnerStep4Description,
      },
      {
        name: risePartnerStep5Name,
        description: risePartnerStep5Description,
      },
      {
        name: risePartnerStep6Name,
        description: risePartnerStep6Description,
      },
    ],
    result:risePartnerResultText,
  },
  {
    name: "Reality Bulgaria",
    subtitle: completedCasesSubtitle,
    description: realityBulgariaDescription,
    image: [
      projectCasesRealityBulgariaImage,
      projectCasesRealityBulgariaWebpImage,
    ],
    steps: [
      {
        name: realityBulgariaStep1Name,
        description: realityBulgariaStep1Description,
      },
      {
        name: realityBulgariaStep2Name,
        description: realityBulgariaStep2Description,
      },
      {
        name: realityBulgariaStep3Name,
        description: realityBulgariaStep3Description,
      },
      {
        name: realityBulgariaStep4Name,
        description: realityBulgariaStep4Description,
      },
      {
        name: realityBulgariaStep5Name,
        description: realityBulgariaStep5Description,
      },
      {
        name: realityBulgariaStep6Name,
        description: realityBulgariaStep6Description,
      },
    ],
    result: realityBulgariaResultText,
  },
  {
    name: "L’EMIGRANT",
    subtitle: completedCasesSubtitle,
    description: lemignrantDescription,
    image: [projectCasesLemigrantImage, projectCasesLemigrantWebpImage],
    steps: [
      {
        name: lemignrantStep1Name,
        description: lemignrantStep1Description,
      },
      {
        name: lemignrantStep2Name,
        description: lemignrantStep2Description,
      },
      {
        name: lemignrantStep3Name,
        description: lemignrantStep3Description,
      },
      {
        name: lemignrantStep4Name,
        description: lemignrantStep4Description,
      },
      {
        name: lemignrantStep5Name,
        description: lemignrantStep5Description,
      },
    ],
    result: lemignrantResultText,
  },
  {
    name: "DocPol",
    subtitle: completedCasesSubtitle,
    description: docPolDescription,
    image: [projectCasesDocPolImage, projectCasesDocPolWebpImage],
    steps: [
      {
        name: docPolStep1Name, 
        description: docPolStep1Description,
      },
      {
        name: docPolStep2Name,
        description: docPolStep2Description,
      },
      {
        name: docPolStep3Name,
        description: docPolStep3Description,
      },
      {
        name: docPolStep4Name,
        description: docPolStep4Description,
      },
    ],
    result: docPolResultText,
  },
];