import { createSlice } from "@reduxjs/toolkit";
import { Elanguages } from "../../constants/Elanguages";
import { AppDispatch } from "../store";
import { setLSItem } from "../../utils/functions/localStorage";
import { lsProps } from "../../utils/lsProps";
import { EServicePaths } from "../../constants/services";
import { serviceViewType } from "../../constants/serviceViewType";

export interface UIState {
  siteLanguage: Elanguages;
  activeService: EServicePaths | null;
  serviceView: serviceViewType | null
}

const initialState: UIState = {
  activeService: null,
  siteLanguage: Elanguages.EN,
  serviceView: null
};

export const changeLanguage =
  (language: Elanguages) => (dispatch: AppDispatch) => {
    dispatch(setSiteLangauge(language));
    setLSItem(lsProps.language, language);
  };

export const UISlice = createSlice({
  name: "uISlice",
  initialState,
  reducers: {
    setSiteLangauge(state, { payload }) {
      state.siteLanguage = payload;
    },
    setActiveService(state, { payload }) {
      state.activeService = payload;
    },
    setServiceView(state, { payload }) {
      state.serviceView = payload;
    },
  },
});

export const { setSiteLangauge, setActiveService,setServiceView } = UISlice.actions;

export default UISlice.reducer;
