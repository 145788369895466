import React, { FC, useEffect } from "react";

import styles from "./ServicesWebsite.module.scss";
import { EServicePaths, SERVICES } from "../../../constants/services";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import AccordionProvider from "../../layout/AccordionProvider/AccordionProvider";
import BgLight from "../../layout/BgLight/BgLight";
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";
import { useDispatch } from "react-redux";
import {
  setActiveService,
  setServiceView,
} from "../../../store/slices/UISlice";
import { scrollIntoElement } from "../../../utils/functions/scrollIntoElement";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { Elanguages } from "../../../constants/Elanguages";

const { servicesWebsiteTitle, servicesWebsiteSubtitle, technologiesText } =
  translations;

interface Props {}

const TechnologyItem: FC<{
  technology: {
    name: { [key in Elanguages]: string };
    description: { [key in Elanguages]: string };
  };
  siteLanguage: Elanguages;
  index: number;
}> = ({ technology, siteLanguage }) => (
  <div className={styles.servicesWebsite__technologyItem}>
    <h5 className={styles.servicesWebsite__technologyName}>
      {technology.name[siteLanguage]}
    </h5>
    <p className={styles.servicesWebsite__technologyDescription}>
      {technology.description[siteLanguage]}
    </p>
  </div>
);

const ServicesWebsite: React.FC<Props> = (props) => {
  const { isInView, ref } = useLazy(0.4);

  const dispatch = useDispatch();
  const activeService = useAppSelector((state) => state.ui.activeService);
  const serviceView = useAppSelector((state) => state.ui.serviceView);
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  const onChange = (path: EServicePaths) => {
    dispatch(setActiveService(activeService === path ? null : path));
  };

  useEffect(() => {
    if (activeService) {
      scrollIntoElement(`[data-path="${activeService}"]`);
    } else if (serviceView) {
      // scroll into taretting or autoCommunication block
      scrollIntoElement(`#${serviceView}`, -5);
    }

    return () => {
      dispatch(setActiveService(null));
      dispatch(setServiceView(null));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log({ activeService });

  return (
    <section className={styles.servicesWebsite} ref={ref}>
      <div className={`container ${styles.servicesWebsite__container}`}>
        <TransitionProvider
          inProp={!!activeService || isInView}
          style={TransitionStyleTypes.top}
        >
          <h3 className={`titleMd ${styles.servicesWebsite__title}`}>
            {servicesWebsiteTitle[siteLanguage]}
          </h3>
          <h4 className={styles.servicesWebsite__subtitle}>
            {servicesWebsiteSubtitle[siteLanguage]}
          </h4>
        </TransitionProvider>
        <div className={styles.servicesWebsite__list}>
          {SERVICES.sort((a, b) => a.order.services - b.order.services).map(
            (
              { name, description, mainImg, steps, technologies, path },
              index
            ) => {
              console.log({activeService , path});
              
              return (
                <div
                  className={styles.servicesWebsite__item}
                  key={index}
                  data-path={path}
                >
                  <TransitionProvider
                    inProp={!!activeService || !!serviceView || isInView}
                    style={TransitionStyleTypes.bottom}
                  >
                    <button
                      className={styles.servicesWebsite__dropdownBtn}
                      onClick={() => onChange(path)}
                    >
                      <span className={styles.servicesWebsite__dropdownBtnMain}>
                        <h4 className={styles.servicesWebsite__nameTxt}>
                          <span className="primaryTxt">
                            {name[siteLanguage][0]}
                          </span>
                          {name[siteLanguage][1]}
                        </h4>
                        <p
                          className={
                            styles.servicesWebsite__shortDescriptionTxt
                          }
                        >
                          {description[siteLanguage]}
                        </p>
                      </span>
                      <div
                        className={`${styles.servicesWebsite__plus} ${
                          activeService === path
                            ? styles.servicesWebsite__plus_active
                            : ""
                        }`}
                      >
                        <div className={styles.servicesWebsite__plusLine}></div>
                        <div className={styles.servicesWebsite__plusLine}></div>
                      </div>
                    </button>
                  </TransitionProvider>
                  <AccordionProvider active={activeService === path}>
                    <div className={styles.servicesWebsite__dropdownContent}>
                      <div
                        className={styles.servicesWebsite__dropdownContentMain}
                      >
                        <ul className={styles.servicesWebsite__steps}>
                          {steps.map((step, stepIndex) => (
                            <li
                              key={stepIndex}
                              className={styles.servicesWebsite__listItem}
                            >
                              <h6
                                className={styles.servicesWebsite__stepNameTxt}
                              >
                                {/*{step.name}*/}
                                {step.name[siteLanguage]}
                              </h6>
                              <p
                                className={
                                  styles.servicesWebsite__stepDescriptionTxt
                                }
                              >
                                {/*{step.description}*/}
                                {step.description[siteLanguage]}
                              </p>
                            </li>
                          ))}
                        </ul>
                        <ImageWebp
                          src={mainImg[0]}
                          srcSet={mainImg[1]}
                          alt={name[siteLanguage].join("")}
                          className={styles.servicesWebsite__img}
                          pictureClass={styles.servicesWebsite__imgWrapper}
                        />
                      </div>
                      <div className={styles.servicesWebsite__technologies}>
                        <h4
                          className={styles.servicesWebsite__technologiesTitle}
                        >
                          {technologiesText[siteLanguage]}
                        </h4>
                        <div
                          className={styles.servicesWebsite__technologiesMain}
                        >
                          {[
                            technologies.slice(
                              0,
                              Math.ceil(technologies.length / 2)
                            ),
                            technologies.slice(
                              Math.ceil(technologies.length / 2)
                            ),
                          ].map((col, colIndex) => (
                            <div
                              key={colIndex}
                              className={
                                styles.servicesWebsite__technologiesCol
                              }
                            >
                              {col.map((technology, techIndex) => {
                                return (
                                  <TechnologyItem
                                    siteLanguage={siteLanguage}
                                    technology={technology}
                                    index={techIndex}
                                    key={techIndex}
                                  />
                                );
                              })}
                            </div>
                          ))}
                        </div>
                        <div
                          className={
                            styles.servicesWebsite__technologiesMobWrapper
                          }
                        >
                          {technologies.map((technology, techIndex) => (
                            <TechnologyItem
                              siteLanguage={siteLanguage}
                              technology={technology}
                              index={techIndex}
                              key={techIndex}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </AccordionProvider>
                </div>
              );
            }
          )}
        </div>
        <TransitionProvider
          inProp={isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.servicesWebsite__bgLight1} />
          <BgLight className={styles.servicesWebsite__bgLight2} />
          <BgLight className={styles.servicesWebsite__bgLight3} />
          <BgLight className={styles.servicesWebsite__bgLight4} />
        </TransitionProvider>
      </div>
    </section>
  );
};

export default ServicesWebsite;
