import React from "react";

import styles from "./AboutContactBlock.module.scss";
import ContactBlock from "../../global/ContactBlock/ContactBlock";
import BgLight from "../../layout/BgLight/BgLight";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

interface Props {}

const {   
  aboutContactBlockDescription
} = translations;

const AboutContactBlock: React.FC<Props> = (props) => {
  const { isInView, ref } = useLazy(0.2);
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <section ref={ref} className={`container ${styles.aboutContactBlock}`}>
      <ContactBlock text={aboutContactBlockDescription[siteLanguage]} />
      <TransitionProvider
        inProp={isInView}
        style={TransitionStyleTypes.opacity}
      >
        <BgLight className={styles.aboutContactBlock__bgLight} />
      </TransitionProvider>
    </section>
  );
};

export default AboutContactBlock;
