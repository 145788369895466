import styles from "./Footer.module.scss";
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";
//import { changeLanguage } from "../../..//store/slices/UISlice";

import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import { logoImage, logoWebpImage } from "../../../assets/images";
import Svg from "../../layout/Svg/Svg";
import {
  fbIcon,
  instagramIcon,
  mailIcon,
  phoneIcon,
  whatsappIcon,
} from "../../../assets/svg";
import { Link } from "react-router-dom";
import {
  cookiesPolicyPagePath,
  homePagePath,
  legalInfoPagePath,
  privacyPolicyPagePath,
} from "../../../router/path";
import { mailAddress, phoneNumber } from "../../../constants/contacts";

const Footer = () => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);
  const { legalInformationText, useOfCookiesText, privacyPolicyText } = translations;

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__wrapper}>
        <div className={"container"}>
          <div className={styles.footer__main}>
            <Link to={homePagePath}>
              <ImageWebp
                src={logoImage}
                srcSet={logoWebpImage}
                alt="logo"
                className={styles.footer__img}
              />
            </Link>
            <div className={styles.footer__contacts}>
              <a
                href={`tel:${phoneNumber.replaceAll(" ", "")}`}
                className={styles.footer__contactLink}
              >
                <Svg
                  id={phoneIcon}
                  className={styles.footer__contactLinkIcon}
                />
                <span>{phoneNumber}</span>
              </a>
              <a
                target="_blank"
                href={`mailto:${mailAddress}`}
                rel="noreferrer"
                className={styles.footer__contactLink}
              >
                <Svg id={mailIcon} className={styles.footer__contactLinkIcon} />
                <span>{mailAddress}</span>
              </a>
              <div className={styles.footer__socialIcons}>
                <a 
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/webscover?igsh=MTJ1bTIxNmJxdWNjMw%3D%3D&utm_source=qr" className={styles.footer__socialiconLink}>
                  <Svg
                    id={instagramIcon}
                    className={styles.footer__socialicon}
                  />
                </a>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.facebook.com/profile.php?id=61572636907962" className={styles.footer__socialiconLink}>
                  <Svg id={fbIcon} className={styles.footer__socialicon} />
                </a>
                <a 
                 target="_blank"
                 rel="noreferrer"
                 href={`https://wa.me/${phoneNumber.replaceAll(" ", "")}`}
                 className={styles.footer__socialiconLink}>
                  <Svg
                    id={whatsappIcon}
                    className={styles.footer__socialicon}
                  />
                </a>
              </div>
            </div>
          </div>
          <div className={styles.footer__bottomBlock}>
            <p className={styles.footer__bottomBlockTxt}>
              webscover. {new Date().getFullYear()}
            </p>
            <div className={styles.footer__links}>
              <Link
                to={legalInfoPagePath}
                className={styles.footer__bottomBlockTxt}
              >                 
                {legalInformationText[siteLanguage]}
              </Link>
              <Link
                to={cookiesPolicyPagePath}
                className={styles.footer__bottomBlockTxt}
              >
                {useOfCookiesText[siteLanguage]}
              </Link>
              <Link
                to={privacyPolicyPagePath}
                className={styles.footer__bottomBlockTxt}
              >
                {privacyPolicyText[siteLanguage]}
              </Link>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
