import React from "react";

import styles from "./HomeTargeting.module.scss";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {
  targetAdRobotImage,
  targetAdRobotWebpImage,
} from "../../../assets/images";
import MainBtn from "../../layout/MainBtn/MainBtn";
import BgLight from "../../layout/BgLight/BgLight";
import { useNavigate } from "react-router-dom";
import { servicesPagePath } from "../../../router/path";
import { useDispatch } from "react-redux";
import { setServiceView } from "../../../store/slices/UISlice";
import { serviceViewType } from "../../../constants/serviceViewType";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { useLazy } from "../../../hooks/useLazy";
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const { 
  homeTargetingTitle,
  homeTargetingSubtitle,
  homeTargetingListItem1, 
  homeTargetingListItem2,
  homeTargetingListItem3,
  readMoreText   
} = translations;

interface Props {}

const HomeTargeting: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isInView, ref } = useLazy(0.8);

  const onClickMore = () => {
    dispatch(setServiceView(serviceViewType.targetting));
    navigate(servicesPagePath);
  };

  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <section className={styles.homeTargeting} ref={ref}>
      <div className={`container ${styles.homeTargeting__container}`}>
        <TransitionProvider
          style={TransitionStyleTypes.top}
          inProp={isInView}
          className={styles.homeTargeting__titleBlock_mob}
        >
          <h3 className="titleMd">
            {homeTargetingTitle[siteLanguage]}
          </h3>
          <h5 className="subtitleMd">
            {homeTargetingSubtitle[siteLanguage]}
          </h5>
        </TransitionProvider>
        <div className={styles.homeTargeting__wrapper}>
          <ImageWebp
            src={targetAdRobotImage}
            srcSet={targetAdRobotWebpImage}
            alt="targeting"
            isInView={isInView}
            transitionType={TransitionStyleTypes.left}
            className={styles.homeTargeting__img}
            pictureClass={styles.homeTargeting__picture}
          />
          <TransitionProvider
            inProp={isInView}
            style={TransitionStyleTypes.right}
            className={styles.homeTargeting__main}
          >
            <div className={styles.homeTargeting__titleBlock_desk}>
              <h3 className="titleMd">
              {homeTargetingTitle[siteLanguage]}
              </h3>
              <h5 className="subtitleMd">
                {homeTargetingSubtitle[siteLanguage]}
              </h5>
            </div>

            <ul className={styles.homeTargeting__list}>
              <div className={styles.homeTargeting__listItemWrapper}>
                <li className={styles.homeTargeting__listItem}>
                  {homeTargetingListItem1[siteLanguage]}
                </li>
              </div>
              <div className={styles.homeTargeting__listItemWrapper}>
                <li className={styles.homeTargeting__listItem}>
                {homeTargetingListItem2[siteLanguage]}
                </li>
              </div>

              <div className={styles.homeTargeting__listItemWrapper}>
                <li className={styles.homeTargeting__listItem}>
                {homeTargetingListItem3[siteLanguage]}
                </li>
              </div>
            </ul>
            <MainBtn onClick={onClickMore}>{readMoreText[siteLanguage]}</MainBtn>
          </TransitionProvider>
        </div>
        <TransitionProvider
          inProp={isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.homeTargeting__bgLight1} />
          <BgLight className={styles.homeTargeting__bgLight2} />
        </TransitionProvider>
      </div>
    </section>
  );
};

export default HomeTargeting;
