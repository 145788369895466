import React, { FC } from "react";

import styles from "./ServicesAutoCommunication.module.scss";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {
  homeProjectCaseItemBgImage,
  homeprojectCaseItemBgWebpImage,
  serviceAutoCommunicationImage,
  serviceAutoCommunicationWebpImage,
} from "../../../assets/images";
import BgLight from "../../layout/BgLight/BgLight";
import { serviceViewType } from "../../../constants/serviceViewType";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";
interface Props {}

const {  
  servicesAutoCommunicationTitle,
  servicesAutoCommunicationSubtitle,   

  servicesAutoCommunicationName1,
  servicesAutoCommunicationDescription1,
  sservicesAutoCommunicationFeature1,

  servicesAutoCommunicationName2,
  servicesAutoCommunicationDescription2,
  sservicesAutoCommunicationFeature2,

  servicesAutoCommunicationName3,
  servicesAutoCommunicationDescription3,
  sservicesAutoCommunicationFeature3,

  servicesAutoCommunicationName4,
  servicesAutoCommunicationDescription4,
  sservicesAutoCommunicationFeature4

} = translations;


const ServicesAutoCommunication: React.FC<Props> = (props) => {
  const { isInView, ref } = useLazy(0.8);
  const serviceView = useAppSelector((state) => state.ui.serviceView);
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  const types = [
    [
      {
        name: servicesAutoCommunicationName1[siteLanguage],
        description: servicesAutoCommunicationDescription1[siteLanguage],
        feature: sservicesAutoCommunicationFeature1[siteLanguage],
      },
      {
        name: servicesAutoCommunicationName2[siteLanguage],
        description: servicesAutoCommunicationDescription2[siteLanguage],
        feature: sservicesAutoCommunicationFeature2[siteLanguage],
      },
    ],
    [
      {
        name: servicesAutoCommunicationName3[siteLanguage],
        description: servicesAutoCommunicationDescription3[siteLanguage],
        feature: sservicesAutoCommunicationFeature3[siteLanguage],
      },
      {
        name: servicesAutoCommunicationName4[siteLanguage],
        description: servicesAutoCommunicationDescription4[siteLanguage],
        feature: sservicesAutoCommunicationFeature4[siteLanguage],
      },
    ],
  ];
  
  const Col: FC<{ index: 0 | 1; isInView: boolean }> = ({ index, isInView }) => (
    <TransitionProvider
      inProp={isInView}
      style={index ? TransitionStyleTypes.right : TransitionStyleTypes.left}
      className={styles.servicesAutoCommunication__col}
    >
      {types[index].map(({ name, description, feature }, index) => (
        <div className={styles.servicesAutoCommunication__box} key={index}>
          <h6 className={styles.servicesAutoCommunication__boxTitle}>{name}</h6>
          <p className={styles.servicesAutoCommunication__boxDescription}>
            {description}
          </p>
          <p className={styles.servicesAutoCommunication__boxFeature}>
            {feature}
          </p>
          <ImageWebp
            src={homeProjectCaseItemBgImage}
            srcSet={homeprojectCaseItemBgWebpImage}
            alt="background"
            className={styles.servicesAutoCommunication__boxBgImg}
          />
        </div>
      ))}
    </TransitionProvider>
  );

  return (
    <section
      ref={ref}
      className={styles.servicesAutoCommunication}
      id={serviceViewType.autoCommunication}
    >
      <div
        className={`container ${styles.servicesAutoCommunication__container}`}
      >
        <TransitionProvider
          inProp={!!serviceView || isInView}
          style={TransitionStyleTypes.top}
        >
          <h3 className={`titleMd ${styles.servicesAutoCommunication__title}`}>
            {servicesAutoCommunicationTitle[siteLanguage]}
          </h3>
          <h5
            className={styles.servicesAutoCommunication__subtitle}
          >
            {servicesAutoCommunicationSubtitle[siteLanguage]}
          </h5>
        </TransitionProvider>
        <div className={styles.servicesAutoCommunication__main}>
          <Col index={0} isInView={!!serviceView || isInView} />
          <div className={styles.servicesAutoCommunication__imgWrapper}>
            <ImageWebp
              src={serviceAutoCommunicationImage}
              alt="Auto Communication"
              pictureClass={styles.servicesAutoCommunication__imgPicture}
              className={styles.servicesAutoCommunication__img}
              srcSet={serviceAutoCommunicationWebpImage}
              isInView={!!serviceView || isInView}
              transitionType={TransitionStyleTypes.zoomIn}
            />
          </div>
          <Col index={1} isInView={!!serviceView || isInView} />
        </div>
        <TransitionProvider
          inProp={!!serviceView || isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.servicesAutoCommunication__bgLight} />
        </TransitionProvider>
      </div>
    </section>
  );
};

export default ServicesAutoCommunication;