import React from "react";

import styles from "./HomeProjects.module.scss";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {
  homeProjectCaseItemBgImage,
  homeprojectCaseItemBgWebpImage,
  homeProjectCaseLaptopImage,
  homeProjectCaseLaptopWebpImage,
} from "../../../assets/images";
import Svg from "../../layout/Svg/Svg";
import { starIcon } from "../../../assets/svg";
import MainBtn from "../../layout/MainBtn/MainBtn";
import BgLight from "../../layout/BgLight/BgLight";
import { useNavigate } from "react-router-dom";
import { projectsPagePath } from "../../../router/path";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const {
  homeProjectsTitle,
  homeProjectsAllProjects,
  websiteForText,
  websiteForTheText,
  readMoreText,
  homeProjectsBigProjectDescription,
  homeProjectsBigProjectResultTutleTxt,
  homeProjectsBigProjectResultTxt,
} = translations;

interface Props {}

const HomeProjects: React.FC<Props> = (props) => {
  const { isInView: isInViewMain, ref: mainRef } = useLazy(0.2);
  const { isInView: isInViewSmall, ref: smallref } = useLazy(0.2);

  const navigate = useNavigate();
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <section className={styles.homeProjects} ref={mainRef}>
      <div className={`container ${styles.homeProjects__container}`}>
        <TransitionProvider
          inProp={isInViewMain}
          style={TransitionStyleTypes.top}
        >
          <h2 className={`titleLg ${styles.homeProjects__title}`}>
            {homeProjectsTitle[siteLanguage]}
          </h2>
        </TransitionProvider>
        <div className={styles.homeProjects__main}>
          <div className={styles.homeProjects__bigProject}>
            <ImageWebp
              src={homeProjectCaseLaptopImage}
              srcSet={homeProjectCaseLaptopWebpImage}
              alt="laptop"
              isInView={isInViewMain}
              transitionType={TransitionStyleTypes.zoomIn}
              className={styles.homeProjects__bigProjectImg}
              pictureClass={styles.homeProjects__bigProjectImgWrapper}
            />
            <div className={styles.homeProjects__bigProjectTexts}>
              <TransitionProvider
                inProp={isInViewMain}
                style={TransitionStyleTypes.right}
                className={styles.homeProjects__bigProjectDescriptionBlock}
              >
                <h4 className={styles.homeProjects__bigProjectNameTxt}>
                  {websiteForText[siteLanguage]}{" "}
                  <span className="primaryTxt">L’EMIGRANT</span>{" "}
                </h4>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.le-migrant.com"
                  className={styles.homeProjects__bigProjectLink}
                >
                  https://www.le-migrant.com
                </a>
                <p className={styles.homeProjects__bigProjectDescription}>
                  {homeProjectsBigProjectDescription[siteLanguage]}
                </p>
              </TransitionProvider>
              <TransitionProvider
                inProp={isInViewMain}
                style={TransitionStyleTypes.right}
                delay={200}
                className={styles.homeProjects__bigProjectPrimaryBox}
              >
                <h5 className={styles.homeProjects__bigProjectResultTutleTxt}>
                  {homeProjectsBigProjectResultTutleTxt[siteLanguage]}
                </h5>
                <p className={styles.homeProjects__bigProjectResultTxt}>
                  {homeProjectsBigProjectResultTxt[siteLanguage]}
                </p>
              </TransitionProvider>
            </div>
          </div>
          <div className={styles.homeProjects__smallProjects} ref={smallref}>
            <TransitionProvider
              inProp={isInViewSmall}
              style={TransitionStyleTypes.bottom}
              className={styles.homeProjects__smallBox}
            >
              <div className={styles.homeProjects__smallBoxStars}>
                <Svg
                  id={starIcon}
                  className={styles.homeProjects__smallBoxStar}
                />
                <Svg
                  id={starIcon}
                  className={styles.homeProjects__smallBoxStar}
                />
                <Svg
                  id={starIcon}
                  className={styles.homeProjects__smallBoxStar}
                />
              </div>
              <ImageWebp
                src={homeProjectCaseItemBgImage}
                srcSet={homeprojectCaseItemBgWebpImage}
                alt="background"
                className={styles.homeProjects__smallBoxBg}
              />
              <h5 className={styles.homeProjects__smallBoxTitle}>
                {websiteForTheText[siteLanguage]} Reality Bulgaria
              </h5>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://realitybulgaria.pl"
                className={styles.homeProjects__smallBoxLink}
              >
                {readMoreText[siteLanguage]}
              </a>
            </TransitionProvider>
            <TransitionProvider
              inProp={isInViewSmall}
              style={TransitionStyleTypes.bottom}
              delay={200}
              className={styles.homeProjects__smallBox}
            >
              <ImageWebp
                src={homeProjectCaseItemBgImage}
                srcSet={homeprojectCaseItemBgWebpImage}
                alt="background"
                className={styles.homeProjects__smallBoxBg}
              />
              <div className={styles.homeProjects__smallBoxStars}>
                <Svg
                  id={starIcon}
                  className={styles.homeProjects__smallBoxStar}
                />
                <Svg
                  id={starIcon}
                  className={styles.homeProjects__smallBoxStar}
                />
                <Svg
                  id={starIcon}
                  className={styles.homeProjects__smallBoxStar}
                />
              </div>
              <h5 className={styles.homeProjects__smallBoxTitle}>
                {websiteForTheText[siteLanguage]} DocPol
              </h5>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://docpolpl.com/"
                className={styles.homeProjects__smallBoxLink}
              >
                {readMoreText[siteLanguage]}
              </a>
            </TransitionProvider>
          </div>
        </div>
        <MainBtn onClick={() => navigate(projectsPagePath)}>
          {homeProjectsAllProjects[siteLanguage]}
        </MainBtn>
        <TransitionProvider
          inProp={isInViewMain}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.homeProjects__bgLight1} />
          <BgLight className={styles.homeProjects__bgLight2} />
          <BgLight />
        </TransitionProvider>
      </div>
    </section>
  );
};

export default HomeProjects;
