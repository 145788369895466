import { Link, NavLink } from "react-router-dom";
import { menuItems } from "../../../../constants/menuItems";
import Svg from "../../../layout/Svg/Svg";

import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../../providers/TransitionProvider";
import Backdrop from "../../../layout/Backdrop/Backdrop";
import styles from "./MobileMenu.module.scss";
import NewPortalProvider from "../../../../providers/NewPortalProvider";
import ImageWebp from "../../../layout/ImageWebp/ImageWebp";
import { logoImage, logoWebpImage } from "../../../../assets/images";
import { crossIcon } from "../../../../assets/svg";
import { useAppSelector } from "../../../../hooks/redux";
import { translations } from "../../../../constants/translations";
import MainBtn from "../../../layout/MainBtn/MainBtn";
import { homePagePath } from "../../../../router/path";
import { contactFormLinks } from "../../../../constants/contacts";

type Props = {
  show: boolean;
  onClose: () => void;
};

const MobileMenu = ({ show, onClose }: Props) => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);
  const { contactUsText } = translations.menu;

  const setActiveNavLinkClass = () => {
    return ({ isActive }: { isActive: boolean }) =>
      isActive
        ? `${styles.mobileMenu__link} ${styles.mobileMenu__link_active}`
        : styles.mobileMenu__link;
  };

  const activeNavLink = setActiveNavLinkClass();

  return (
    <>
      <Backdrop inProp={show} onClose={onClose} />
      <NewPortalProvider>
        <TransitionProvider
          inProp={show}
          duration={300}
          style={TransitionStyleTypes.opacity}
          className={styles.mobileMenu}
        >
          <div className={styles.mobileMenu__header}>
            <Link
              to={homePagePath}
              className={styles.mobileMenu__logoLink}
              onClick={onClose}
            >
              <ImageWebp
                src={logoImage}
                srcSet={logoWebpImage}
                alt="logo"
                className={styles.mobileMenu__logoImg}
              />
            </Link>
            <button className={styles.mobileMenu__crossBtn} onClick={onClose}>
              <Svg id={crossIcon} className={styles.mobileMenu__crossIcon} />
            </button>
          </div>
          <nav className={styles.mobileMenu__navbar}>
            {menuItems.map(({ link, text }, index) => (
              <NavLink
                to={link}
                key={index}
                className={activeNavLink}
                onClick={onClose}
              >
                {text[siteLanguage]}
              </NavLink>
            ))}
          </nav>
          <MainBtn
            asLink
            href={contactFormLinks[siteLanguage]}
            target="_blank"
            rel="noreferrer"
            className={styles.mobileMenu__contactBtn}
          >
            {contactUsText[siteLanguage]}
          </MainBtn>
        </TransitionProvider>
      </NewPortalProvider>
    </>
  );
};

export default MobileMenu;
