import React from 'react'
import ServicesHeader from '../components/ServicesPage/ServicesHeader/ServicesHeader'
import ServicesWebsite from '../components/ServicesPage/ServicesWebsite/ServicesWebsite'
import ServicesTargetting from '../components/ServicesPage/ServicesTargetting/ServicesTargetting'
import ServicesAutoCommunication from '../components/ServicesPage/ServicesAutoCommunication/ServicesAutoCommunication'
import ServiceContactBlock from '../components/ServicesPage/ServiceContactBlock/ServiceContactBlock'
import Head from '../components/global/Head/Head'
import { useAppSelector } from "./../hooks/redux";
import { translations } from "../constants/translations";
 
const { 
  metaServicesKeyword,
  metaServicesTitle,
  metaServicesDescription
} = translations; 

const ServicesPage = () => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <>
    <Head     
        keyword={metaServicesKeyword[siteLanguage]}
        title={metaServicesTitle[siteLanguage]}
        ogTitle={metaServicesTitle[siteLanguage]}
        description={metaServicesDescription[siteLanguage]}
        ogDescription={metaServicesDescription[siteLanguage]}
      />
      <ServicesHeader/>
      <ServicesWebsite/>
      <ServicesTargetting/>
      <ServicesAutoCommunication/>
      <ServiceContactBlock/>
    </>
  )
}

export default ServicesPage