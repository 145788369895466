import { ReactElement } from "react";
import HomePage from "../pages/HomePage";
import AboutPage from "../pages/AboutPage";
import ProjectsPage from "../pages/ProjectsPage";
import ServicesPage from "../pages/ServicesPage";
import ContactsPage from "../pages/ContactsPage";
import ErrorPage from "../pages/ErrorPage";
import PrivacyPolicyPage from "../pages/PrivacyPolicyPage";
import CookiePolicyPage from "../pages/CookiePolicyPage";
import LegalInfoPage from "../pages/LegalInfoPage";

export const homePagePath = "/";
export const aboutPagePath = "/about";
export const projectsPagePath = "/projects";
export const servicesPagePath = "/services";
export const contactsPagePath = "/contacts";

export const privacyPolicyPagePath = "/privacyPolicy";
export const cookiesPolicyPagePath = "/cookiesPolicy";
export const legalInfoPagePath = "/legalInfo";

interface IRoute {
  path: string;
  component: ReactElement<any, any>;
  children?: Omit<IRoute, "children">[];
}

export const routes: IRoute[] = [
  {
    path: homePagePath,
    component: <HomePage />,
  },

  {
    path: aboutPagePath,
    component: <AboutPage />,
  },

  {
    path: projectsPagePath,
    component: <ProjectsPage />,
  },

  {
    path: servicesPagePath,
    component: <ServicesPage />,
  },

  {
    path: contactsPagePath,
    component: <ContactsPage />,
  },
  {
    path: servicesPagePath,
    component: <ServicesPage />,
  },

  {
    path: contactsPagePath,
    component: <ContactsPage />,
  },

  {
    path: privacyPolicyPagePath,
    component: <PrivacyPolicyPage />,
  },

  {
    path: cookiesPolicyPagePath,
    component: <CookiePolicyPage />,
  },

  {
    path: legalInfoPagePath,
    component: <LegalInfoPage />,
  },

  {
    path: "*",
    component: <ErrorPage />,
  },
];
