import React from "react";

import styles from "./ContactHeader.module.scss";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {
  contactsHeaderRobotImage,
  contactsHeaderRobotWebpImage,
} from "../../../assets/images";
import BgLight from "../../layout/BgLight/BgLight";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { mailAddress, phoneNumber } from "../../../constants/contacts";
import { useContentLoaded } from "../../../hooks/useContentLoaded";
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const {
  contactHeaderTitle,
  contactHeaderSubtitle,
  contactHeaderDatasTitle,
  contactHeaderPhone,
  contactHeaderEmail,
  contactHeaderAddress,
  contactHeaderLegalAddress,
} = translations;

interface Props {}

const ContactHeader: React.FC<Props> = (props) => {
  const { isInView, ref } = useLazy(0.1);
  const contentLoaded = useContentLoaded();
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <header
      ref={ref}
      className={`${
        !contentLoaded ? "viewHeight" : ""
      }`}
    >
      <div className={`container ${styles.contactHeader__container}`}>
        <div className={styles.contactHeader__main}>
          <TransitionProvider
            style={TransitionStyleTypes.top}
            inProp={isInView}
            className={styles.contactHeader__titleBlock}
          >
            <h1 className={`titleLg`}>{contactHeaderTitle[siteLanguage]}</h1>
            <h4 className={`subtitleLg`}>
              {contactHeaderSubtitle[siteLanguage]}
            </h4>
          </TransitionProvider>
          <TransitionProvider
            inProp={isInView}
            style={TransitionStyleTypes.bottom}
            className={styles.contactHeader__datasBlock}
          >
            <h5 className={styles.contactHeader__datasTitle}>
              {contactHeaderDatasTitle[siteLanguage]}
            </h5>
            <div className={styles.contactHeader__dataList}>
              <div className={styles.contactHeader__dataitem}>
                <h6 className={styles.contactHeader__dataLabel}>
                  {contactHeaderPhone[siteLanguage]}
                </h6>
                <a
                  href={`tel:${phoneNumber.replaceAll(" ", "")}`}
                  className={styles.contactHeader__dataValueTxt}
                >
                  {phoneNumber}
                </a>
              </div>
              <div className={styles.contactHeader__dataitem}>
                <h6 className={styles.contactHeader__dataLabel}>
                  {contactHeaderEmail[siteLanguage]}
                </h6>
                <a
                 target="_blank"
                 href={`mailto:${mailAddress}`}
                 rel="noreferrer"
                className={styles.contactHeader__dataValueTxt}>
                  {mailAddress}
                </a>
              </div>
              <div className={styles.contactHeader__dataitem}>
                <h6 className={styles.contactHeader__dataLabel}>
                  {contactHeaderAddress[siteLanguage]}
                </h6>
                <p className={styles.contactHeader__dataValueTxt}>
                  {contactHeaderLegalAddress[siteLanguage]}
                </p>
              </div>
            </div>
          </TransitionProvider>
        </div>
        <div className={styles.contactHeader__imgWrapper}>
          <ImageWebp
            src={contactsHeaderRobotImage}
            srcSet={contactsHeaderRobotWebpImage}
            alt="contacts"
            isInView={isInView}
            transitionType={TransitionStyleTypes.right}
            className={styles.contactHeader__img}
            pictureClass={styles.contactHeader__imgPicture}
          />
        </div>
        <TransitionProvider
          inProp={isInView}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.contactHeader__bgLight} />
        </TransitionProvider>
      </div>
    </header>
  );
};

export default ContactHeader;
