import React from "react";

import styles from "./HomeServiceTypes.module.scss";
import Svg from "../../layout/Svg/Svg";
import { arrowLeftIcon } from "../../../assets/svg";
import { EServicePaths, SERVICES } from "../../../constants/services";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import BgLight from "../../layout/BgLight/BgLight";
import { useDispatch } from "react-redux";
import { setActiveService } from "../../../store/slices/UISlice";
import { useNavigate } from "react-router-dom";
import { servicesPagePath } from "../../../router/path";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const HomeServiceTypes: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isInView, ref } = useLazy(1);

  const onClickItem = (path: EServicePaths) => {
    dispatch(setActiveService(path));
    navigate(servicesPagePath);
  };

  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);
  const { homeServiceTypesTitle, homeServiceTypesSubtitle } = translations;

  return (
    <section className={styles.homeServiceTypes} ref={ref}>
      <div className={`container ${styles.homeServiceTypes__container}`}>
        <TransitionProvider style={TransitionStyleTypes.top} inProp={isInView}>
          <h3 className={`titleMd`}>{homeServiceTypesTitle[siteLanguage]}</h3>
          <h5 className={`subtitleMd`}>
            {homeServiceTypesSubtitle[siteLanguage]}
          </h5>
        </TransitionProvider>
        <div className={styles.homeServiceTypes__main}>
          {SERVICES.sort((a, b) => a.order.home - b.order.home).map(
            (item, index) => (
              <TransitionProvider
                style={TransitionStyleTypes.zoomIn}
                inProp={isInView}
                delay={index * 100}
                className={styles.homeServiceTypes__item}
                key={item.path}
              >
                <button
                  onClick={() => onClickItem(item.path)}
                  className={styles.homeServiceTypes__itemBtn}
                >
                  <div className={styles.homeServiceTypes__itemMain}>
                    <Svg
                      id={arrowLeftIcon}
                      className={styles.homeServiceTypes__arrowIcon}
                    />
                    <h4 className={styles.homeServiceTypes__itemTitle}>
                      {item.name[siteLanguage].join("")}
                    </h4>
                    <p className={styles.homeServiceTypes__txt}>
                      {item.description[siteLanguage]}
                    </p>
                  </div>
                  <ImageWebp
                    src={item.homeImg[0]}
                    srcSet={item.homeImg[1]}
                    alt={item.name[siteLanguage].join("")}
                    className={styles.homeServiceTypes__itemImg}
                    pictureClass={styles.homeServiceTypes__itemImg_desk}
                  />
                  <ImageWebp
                    src={item.homeMobImg[0]}
                    srcSet={item.homeMobImg[1]}
                    alt={item.name[siteLanguage].join("")}
                    className={styles.homeServiceTypes__itemImg}
                    pictureClass={styles.homeServiceTypes__itemImg_mob}
                  />
                </button>
              </TransitionProvider>
            )
          )}
        </div>
        <TransitionProvider
          inProp={isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.homeServiceTypes__bgLight1} />
          <BgLight className={styles.homeServiceTypes__bgLight2} />
        </TransitionProvider>
      </div>
    </section>
  );
};

export default HomeServiceTypes;
