import React from 'react';
import ContactBlock from '../../global/ContactBlock/ContactBlock';
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

interface Props {  
}
const {   
  projectContactBlockDescription
} = translations;

const ProjectContactBlock: React.FC<Props> = (props) => {
   const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return <section className='container'>
    <ContactBlock text={projectContactBlockDescription[siteLanguage]}/>
  </section>;
};

export default ProjectContactBlock;