import React, { FC, memo, ReactNode } from 'react';

import styles from './MainBtn.module.scss';

type MainBtnProps = {
  className?: string;
  isLarge?: boolean;
  children: ReactNode;
  asLink?: boolean;
} & (
  | React.ButtonHTMLAttributes<HTMLButtonElement>
  | React.AnchorHTMLAttributes<HTMLAnchorElement>
);

const MainBtn: FC<MainBtnProps> = memo(({ className, children, isLarge, asLink, ...properties }) => {
  const TagName = asLink ? 'a' : 'button';

  return (
    <TagName
      className={`${styles['mainBtn']} ${className || ''} ${isLarge ? styles.mainBtn_lg : ''}`}
      {...(properties as any)} // Type assertion is safe here due to the union type
    >
      {children}
    </TagName>
  );
});

export default MainBtn;
