import React, { FC } from "react";

import styles from "./HomeAutoCommunication.module.scss";
import Svg from "../../layout/Svg/Svg";
import {
  chatBotAnaliticsIcon,
  chatBotCRMIcon,
  chatBotSettingsIcon,
} from "../../../assets/svg";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {
  homeAutoComunicationImage,
  homeAutoComunicationMobImage,
  homeAutoComunicationMobWebpImage,
  homeAutoComunicationWebpImage,
} from "../../../assets/images";
import MainBtn from "../../layout/MainBtn/MainBtn";
import BgLight from "../../layout/BgLight/BgLight";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setServiceView } from "../../../store/slices/UISlice";
import { serviceViewType } from "../../../constants/serviceViewType";
import { servicesPagePath } from "../../../router/path";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const { 
  homeAutoCommunicationTitle,
  homeAutoCommunicationSubtitle,
  readMoreText,
  chatBotCRM,
  chatBotAnalitics,
  chatBotSettings  
} = translations;

interface Props {}

const Box: FC<{
  icon: string;
  text: string;
  isMobile?: boolean;
  iconClass: string;
}> = ({ icon, text, isMobile, iconClass }) => (
  <div
    className={`${styles.homeAutoCommunication__mainBox} ${
      isMobile ? styles.homeAutoCommunication__mainBox_mob : ""
    }`}
  >
    <div className={styles.homeAutoCommunication__mainBoxIconWrapper}>
      <Svg
        className={`${
          iconClass || ""
        }`}
        id={icon}
      />
    </div>
    <p className={styles.homeAutoCommunication__mainBoxTxt}>{text}</p>
  </div>
);

const HomeAutoCommunication: React.FC<Props> = (props) => {
  const { isInView, ref } = useLazy(1);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onClickMore = () => {
    dispatch(setServiceView(serviceViewType.autoCommunication));
    navigate(servicesPagePath);
  };

  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <section ref={ref} className={styles.homeAutoCommunication}>
      <div className={`container ${styles.homeAutoCommunication__container}`}>
        <div className={styles.homeAutoCommunication__wrapper}>
          <div className={styles.homeAutoCommunication__main}>
            <TransitionProvider
              inProp={isInView}
              style={TransitionStyleTypes.left}
              className={styles.homeAutoCommunication__mainLeftBlock}
            >
              <Box
                icon={chatBotCRMIcon}
                text={chatBotCRM[siteLanguage]}
                iconClass={styles.homeAutoCommunication__crmBotIcon}
              />
              <Box
                icon={chatBotAnaliticsIcon}
                isMobile
                iconClass={styles.homeAutoCommunication__analiticsIcon}
                text={chatBotAnalitics[siteLanguage]}
              />
              <Box
                icon={chatBotSettingsIcon}
                text={chatBotSettings[siteLanguage]}
                iconClass={styles.homeAutoCommunication__settingsIcon}
              />
            </TransitionProvider>
            <ImageWebp
              src={homeAutoComunicationImage}
              srcSet={homeAutoComunicationWebpImage}
              alt="auto comunication"
              isInView={isInView}
              imageLoadType="width"
              transitionType={TransitionStyleTypes.zoomIn}
              className={styles.homeAutoCommunication__img}
              pictureClass={styles.homeAutoCommunication__picture}
            />
            <ImageWebp
              isInView={isInView}
              transitionType={TransitionStyleTypes.zoomIn}
              src={homeAutoComunicationMobImage}
              srcSet={homeAutoComunicationMobWebpImage}
              alt="auto comunication"
              imageLoadType="width"
              className={styles.homeAutoCommunication__img}
              pictureClass={`${styles.homeAutoCommunication__picture} ${styles.homeAutoCommunication__picture_mob}`}
            />
            <TransitionProvider
              inProp={isInView}
              style={TransitionStyleTypes.right}
              className={styles.homeAutoCommunication__mainRightBlock}
            >
              <Box
                icon={chatBotAnaliticsIcon}
                iconClass={styles.homeAutoCommunication__analiticsIcon}
                text={chatBotAnalitics[siteLanguage]}
              />
            </TransitionProvider>
          </div>
          <TransitionProvider
            inProp={isInView}
            style={TransitionStyleTypes.top}
            className={styles.homeAutoCommunication__titleBlock}
          >
            <h3 className={`titleMd ${styles.homeAutoCommunication__title}`}>
              {homeAutoCommunicationTitle[siteLanguage]}
            </h3>
            <h5
              className={`subtitleMd ${styles.homeAutoCommunication__subtitle}`}
            >
              {homeAutoCommunicationSubtitle[siteLanguage]}
            </h5>
            <MainBtn
              className={styles.homeAutoCommunication__btn}
              onClick={onClickMore}
            >
              {readMoreText[siteLanguage]}
            </MainBtn>
          </TransitionProvider>
        </div>
        <TransitionProvider
          inProp={isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.homeAutoCommunication__bgLight} />
        </TransitionProvider>
      </div>
    </section>
  );
};

export default HomeAutoCommunication;