import React from "react";

import styles from "./ServicesTargetting.module.scss";
import Svg from "../../layout/Svg/Svg";
import { starIcon } from "../../../assets/svg";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {
  targetAdRobotImage,
  targetAdRobotWebpImage,
} from "../../../assets/images";
import BgLight from "../../layout/BgLight/BgLight";
import { serviceViewType } from "../../../constants/serviceViewType";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const {  
  servicesTargettingTitleMd,
  servicesTargettingSubtitle,

  servicesTargettingTitle1,
  servicesTargettingDescription1,
  servicesTargettingFeature1,

  servicesTargettingTitle2,
  servicesTargettingDescription2,
  servicesTargettingFeature2,

  servicesTargettingTitle3,
  servicesTargettingDescription3,
  servicesTargettingFeature3,

  servicesTargettingTitle4,
  servicesTargettingDescription4,
  servicesTargettingFeature4,
  
} = translations;

interface Props {}

const ServicesTargetting: React.FC<Props> = (props) => {
  const { isInView, ref } = useLazy(0.8);
  const serviceView = useAppSelector((state) => state.ui.serviceView);
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  const types = [
    {
      title: servicesTargettingTitle1[siteLanguage],
      description: servicesTargettingDescription1[siteLanguage],
      feature: servicesTargettingFeature1[siteLanguage],
    },
    {
      title: servicesTargettingTitle2[siteLanguage],
      description: servicesTargettingDescription2[siteLanguage],
      feature: servicesTargettingFeature2[siteLanguage],
    },
    {
      title: servicesTargettingTitle3[siteLanguage],
      description: servicesTargettingDescription3[siteLanguage],
      feature: servicesTargettingFeature3[siteLanguage],
    },
    {
      title: servicesTargettingTitle4[siteLanguage],
      description: servicesTargettingDescription4[siteLanguage],
      feature: servicesTargettingFeature4[siteLanguage],
    },
  ];

  return (
    <section
      className={styles.servicesTargetting}
      id={serviceViewType.targetting}
      ref={ref}
    >
      <div className={`container ${styles.servicesTargetting__container}`}>
        <TransitionProvider
          inProp={!!serviceView || isInView}
          style={TransitionStyleTypes.top}
        >
          <h3 className="titleMd">
             {servicesTargettingTitleMd[siteLanguage]}
          </h3>
          <h5 className={styles.servicesTargetting__subtitle}>
            {servicesTargettingSubtitle[siteLanguage]}
          </h5>
        </TransitionProvider>
        <div className={styles.servicesTargetting__main}>
          <div className={styles.servicesTargetting__types}>
            {types.map(({ title, description, feature }, index) => (
              <TransitionProvider
                inProp={!!serviceView || isInView}
                style={TransitionStyleTypes.right}
                delay={index * 100}
                key={index}
                className={styles.servicesTargetting__typeItem}
              >
                <div className={styles.servicesTargetting__starIconWrapper}>
                  <Svg
                    id={starIcon}
                    className={styles.servicesTargetting__starIcon}
                  />
                </div>
                <h6 className={styles.servicesTargetting__itemTitle}>
                  {title}
                </h6>
                <p className={styles.servicesTargetting__descriptionTxt}>
                  {description}
                </p>
                <p className={styles.servicesTargetting__itemFeatureText}>
                  {feature}
                </p>
              </TransitionProvider>
            ))}
          </div>
          <div className={styles.servicesTargetting__imgWrapper}>
            <ImageWebp
              src={targetAdRobotImage}
              alt="Targetting"
              pictureClass={styles.servicesTargetting__imgPicture}
              className={styles.servicesTargetting__img}
              srcSet={targetAdRobotWebpImage}
              isInView={!!serviceView || isInView}
              transitionType={TransitionStyleTypes.right}
            />
          </div>
        </div>
        <TransitionProvider
          inProp={!!serviceView || isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.servicesTargetting__bgLight1} />
          <BgLight className={styles.servicesTargetting__bgLight2} />
          <BgLight className={styles.servicesTargetting__bgLight3} />
        </TransitionProvider>
      </div>
    </section>
  );
};

export default ServicesTargetting;