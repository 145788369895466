import React from "react";
import ProjectsReviews from "../components/ProjectsPage/ProjectsReviews/ProjectsReviews";
import ProjectsHeader from "../components/ProjectsPage/ProjectsHeader/ProjectsHeader";
import ProjectsCompletedCases from "../components/ProjectsPage/ProjectsCompletedCases/ProjectsCompletedCases";
import ProjectContactBlock from "../components/ProjectsPage/ProjectContactBlock/ProjectContactBlock";
import Head from '../components/global/Head/Head'
import { useAppSelector } from "./../hooks/redux";
import { translations } from "../constants/translations";
 
const { 
  metaProjectsKeyword,
  metaProjectsTitle,
  metaProjectsDescription
} = translations; 

const ProjectsPage = () => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  return (
    <>
    <Head     
        keyword={metaProjectsKeyword[siteLanguage]}
        title={metaProjectsTitle[siteLanguage]}
        ogTitle={metaProjectsTitle[siteLanguage]}
        description={metaProjectsDescription[siteLanguage]}
        ogDescription={metaProjectsDescription[siteLanguage]}
      />
      <ProjectsHeader />
      <ProjectsCompletedCases />
      <ProjectsReviews />
      <ProjectContactBlock />
    </>
  );
};

export default ProjectsPage;