import React from "react";

import styles from "./AboutMission.module.scss";
import Svg from "../../layout/Svg/Svg";
import { starIcon } from "../../../assets/svg";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import { aboutMissonImage, aboutMissonWebpImage } from "../../../assets/images";
import BgLight from "../../layout/BgLight/BgLight";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const { 
  aboutMissionTitle,
  aboutMissionMainTextBlockTitle,
  aboutMissionMainTextBlockDescription,
  aboutMissionFeature1,
  aboutMissionFeature2,
  aboutMissionFeature3,
  aboutMissionFeature4   
} = translations;

interface Props {}

const AboutMission: React.FC<Props> = (props) => {
  const { isInView, ref } = useLazy(1);
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage)

  const features = [
    aboutMissionFeature1[siteLanguage],
    aboutMissionFeature2[siteLanguage],
    aboutMissionFeature3[siteLanguage],
    aboutMissionFeature4[siteLanguage],
  ];

  return (
    <section className={styles.aboutMission} ref={ref}>
      <div className={`container ${styles.aboutMission__container}`}>
        <div className={styles.aboutMission__imgWrapper}
        >

        <ImageWebp
          srcSet={aboutMissonWebpImage}
          src={aboutMissonImage}
          alt={"Our Mission"}
          isInView={isInView}
          transitionType={TransitionStyleTypes.right}
          className={styles.aboutMission__img}
          pictureClass={styles.aboutMission__imgPicture}
          />
          </div>
        <TransitionProvider inProp={isInView} style={TransitionStyleTypes.top}>
          <h2 className={`titleLg ${styles.aboutMission__title}`}>
            {aboutMissionTitle[siteLanguage]}
          </h2>
        </TransitionProvider>
        <TransitionProvider
          inProp={isInView}
          style={TransitionStyleTypes.bottom}
          className={styles.aboutMission__main}
        >
          <div className={styles.aboutMission__mainTextBlock}>
            <h5 className={styles.aboutMission__mainTextBlockTitle}>{aboutMissionMainTextBlockTitle[siteLanguage]}</h5>
            <p className={styles.aboutMission__mainTextBlockDescription}>
              {aboutMissionMainTextBlockDescription[siteLanguage]}              
            </p>
          </div>
          <div className={styles.aboutMission__features}>
            {features.map((item, index) => (
              <TransitionProvider
                inProp={isInView}
                style={TransitionStyleTypes.zoomIn}
                delay={index * 300}
                className={styles.aboutMission__featureItem}
                key={index}
              >
                <div className={styles.aboutMission__starIconWrapper}>
                  <Svg
                    id={starIcon}
                    className={styles.aboutMission__starIcon}
                  />
                </div>
                <p className={styles.aboutMission__featureText}>{item}</p>
              </TransitionProvider>
            ))}
          </div>
        </TransitionProvider>
        <TransitionProvider
          inProp={isInView}
          delay={400}
          style={TransitionStyleTypes.opacity}
        >
          <BgLight className={styles.aboutMission__bgLight} />
        </TransitionProvider>
      </div>
    </section>
  );
};

export default AboutMission;
