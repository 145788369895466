import React, { useRef } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType } from "swiper/types";
import { Navigation } from "swiper/modules";

import styles from "./ProjectsHeader.module.scss";
import ImageWebp from "../../layout/ImageWebp/ImageWebp";
import {
  projectsHeaderRobotImage,
  projectsHeaderRobotWebpImage,
  projectsSliderDocPolImage,
  projectsSliderDocPolWebpImage,
  projectsSliderLemigrantImage,
  projectsSliderLemigrantWebpImage,
  projectsSliderRealityImage,
  projectsSliderRealityWebpImage,
  projectsSliderRisePartnerImage,
  projectsSliderRisePartnerWebpImage,
} from "../../../assets/images";
import Svg from "../../layout/Svg/Svg";
import { sliderArrowLeftIcon, sliderArrowRightIcon } from "../../../assets/svg";
import BgLight from "../../layout/BgLight/BgLight";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";
import { useContentLoaded } from "../../../hooks/useContentLoaded";
import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

interface Props {}

const slides = [
  {
    name: "L’EMIGRANT",
    link: "https://www.le-migrant.com/",
    img: [projectsSliderLemigrantImage, projectsSliderLemigrantWebpImage],
  },
  {
    name: "Rise Partner",
    link: "https://risepartnerpl.com/",
    img: [projectsSliderRisePartnerImage, projectsSliderRisePartnerWebpImage],
  },
  {
    name: "DocPol",
    link: "https://docpolpl.com",
    img: [projectsSliderDocPolImage, projectsSliderDocPolWebpImage],
  },
  {
    name: "Reality Bulgaria",
    link: "https://realitybulgaria.pl/",
    img: [projectsSliderRealityImage, projectsSliderRealityWebpImage],
  },
];

const ProjectsHeader: React.FC<Props> = (props) => {
  const { isInView, ref } = useLazy(0.2);
  const contentLoaded = useContentLoaded();

  const nextButtonRef = useRef(null);
  const prevButtonRef = useRef(null);

  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);
  const {
     readMoreText,
     projectsHeaderTitleLg,
     projectsHeaderSubtitleLg,
     websiteForTheText
   } = translations;

  return (
    <header
      className={`container ${styles.projectsHeader} ${
        !contentLoaded ? "viewHeight" : ""
      }`}
      ref={ref}
    >
      <div className={styles.projectsHeader__main}>
        <TransitionProvider
          inProp={isInView}
          style={TransitionStyleTypes.left}
          className={styles.projectsHeader__texts}
        >
          <h1 className="titleLg">
            {projectsHeaderTitleLg[siteLanguage]}
          </h1>
          <h4 className="subtitleLg">
            {projectsHeaderSubtitleLg[siteLanguage]}
          </h4>
        </TransitionProvider>
        <div className={styles.projectsHeader__imgWrapper}>
          <ImageWebp
            src={projectsHeaderRobotImage}
            srcSet={projectsHeaderRobotWebpImage}
            alt="Our Projects"
            isInView={isInView}
            transitionType={TransitionStyleTypes.right}
            className={styles.projectsHeader__img}
            pictureClass={styles.projectsHeader__imgPicture}
          />
        </div>
      </div>
      <TransitionProvider
        inProp={isInView}
        style={TransitionStyleTypes.bottom}
        className={styles.projectsHeader__sliderWrapper}
      >
        <Swiper
          modules={[Navigation]}
          navigation={{
            nextEl: styles.projectsHeader__arrowBtn_prev,
            prevEl: styles.projectsHeader__arrowBtn_next,
          }}
          className={styles.projectsHeader__slider}
          onBeforeInit={(swiper: SwiperType) => {
            if (typeof swiper.params.navigation === "object")
              swiper.params.navigation.nextEl = nextButtonRef.current;
            if (typeof swiper.params.navigation === "object")
              swiper.params.navigation.prevEl = prevButtonRef.current;
          }}
          spaceBetween={40}
          slidesPerView={1}
          breakpoints={
            window.innerWidth > 768
              ? {
                  576: {
                    spaceBetween: 20,
                  },
                  768: {
                    slidesPerView: 2,
                  },
                  1024: {
                    slidesPerView: 3,
                  },
                }
              : undefined
          }
        >
          {slides.map(({ name, img, link }, index) => (
            <SwiperSlide key={index}>
              <div className={styles.projectsHeader__slide}>
                <ImageWebp
                  src={img[0]}
                  srcSet={img[1]}
                  alt={name}
                  className={styles.projectsHeader__slideImg}
                />
                <div className={styles.projectsHeader__slideMain}>
                  <h4 className={styles.projectsHeader__slideTitle}>
                    {websiteForTheText[siteLanguage]} <span className="primaryTxt">{name}</span>
                  </h4>
                  <a
                    href={link}
                    target="_blank"
                    className={styles.projectsHeader__link}
                    rel="noreferrer"
                  >
                    {readMoreText[siteLanguage]}
                  </a>
                </div>
              </div>
            </SwiperSlide>
          ))}
          <button
            ref={prevButtonRef}
            className={`${styles.projectsHeader__arrowBtn} ${styles.projectsHeader__arrowBtn_prev}`}
          >
            <Svg
              id={sliderArrowLeftIcon}
              className={styles.projectsHeader__arrowIcon}
            />
          </button>
          <button
            ref={nextButtonRef}
            className={`${styles.projectsHeader__arrowBtn} ${styles.projectsHeader__arrowBtn_next}`}
          >
            <Svg
              id={sliderArrowRightIcon}
              className={styles.projectsHeader__arrowIcon}
            />
          </button>
        </Swiper>
      </TransitionProvider>
      <TransitionProvider
        inProp={isInView}
        delay={400}
        style={TransitionStyleTypes.opacity}
      >
        <BgLight className={styles.projectsHeader__bgLight1} />
        <BgLight className={styles.projectsHeader__bgLight2} />
      </TransitionProvider>
    </header>
  );
};

export default ProjectsHeader;