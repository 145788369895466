export enum Elanguages {
    RU = "RU",
    EN = "EN",
    PL = "PL",
    ES = "ES",
}


export const languagesArr = [
    Elanguages.RU,
    Elanguages.EN,
    Elanguages.ES,
    Elanguages.PL,
] 