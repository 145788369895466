// Header

export const translations = {
  menu: {
    aboutText: {
      RU: "О нас",
      EN: "About us",
      ES: "Sobre nosotros",
      PL: "O nas",
    },
    contactsText: {
      RU: "Контакты",
      EN: "Contacts",
      ES: "Contactos",
      PL: "Kontakt",
    },
    projectsText: {
      RU: "Наши проекты",
      EN: "Our projects",
      ES: "Nuestros proyectos",
      PL: "Nasze projekty",
    },
    servicesText: {
      RU: "Услуги",
      EN: "Services",
      ES: "Servicios",
      PL: "Usługi",
    },
    contactUsText: {
      RU: "Связаться с нами",
      EN: "Contact us",
      ES: "Contáctanos",
      PL: "Skontaktuj",
    },
  },

  // Footer
  legalInformationText: {
    RU: "Юридическая информация",
    EN: "Legal information",
    ES: "Información legal",
    PL: "Informacje prawne",
  },
  useOfCookiesText: {
    RU: "Использование cookies",
    EN: "Terms of Use",
    ES: "Términos de uso",
    PL: "Używanie plików cookies",
  },
  privacyPolicyText: {
    RU: "Политика конфиденциальности",
    EN: "Privacy Policy",
    ES: "Política de privacidad",
    PL: "Polityka prywatności",
  },

  // Repeating words

  readMoreText: {
    RU: "Подробнее",
    EN: "Read more",
    ES: "Leer más",
    PL: "Czytaj więcej",
  },
  websiteForText: {
    RU: "Сайт для компании",
    EN: "Website for",
    ES: "Sitio web para",
    PL: "Strona internetowa dla",
  },
  websiteForTheText: {
    RU: "Сайт для компании",
    EN: "Website for the company",
    ES: "Sitio web para la empresa",
    PL: "Strona internetowa dla firmy",
  },

  technologiesText: {
    RU: "Технологии ",
    EN: "Technologies",
    ES: "Tecnologías",
    PL: "Technologie",
  },

  // Homepage

  // HomeHeader

  homeHeaderTitleLg: {
    RU: (
      <>
        Мы создаем <span className="primaryTxt">веб-решения</span> <br /> для
        вашего успеха
      </>
    ),
    EN: (
      <>
        We create <span className="primaryTxt">web solutions</span> <br /> for
        your success
      </>
    ),
    ES: (
      <>
        Creamos <span className="primaryTxt">soluciones web</span> <br /> para
        su éxito
      </>
    ),
    PL: (
      <>
        Tworzymy rozwiązania <span className="primaryTxt">webowe</span>{" "}
        <br /> dla Twojego sukcesu
      </>
    ),
  },

  homeHeaderSubtitleLgText: {
    RU: "От разработки сайтов и таргетированной рекламы до автоматизации общения с клиентами через современные чат-боты",
    EN: "From website development and advanced targeted advertising to automation of client communication via modern chatbots integrated with CRM systems.",
    ES: "Desde el desarrollo de sitios web y publicidad dirigida avanzada hasta la automatización de la comunicación con clientes mediante chatbots modernos integrados con sistemas CRM.",
    PL: "Od tworzenie stron internetowych i zaawansowanej reklamy targetowanej po automatyzację komunikacji z klientami za pomocą nowoczesnych chatbotów zintegrowanych z systemami CRM.",
  },

  featureItemText1: {
    RU: "Комплексные решения от концепции до внедрения и поддержки",
    EN: "Comprehensive solutions from concept to implementation and support",
    ES: "Soluciones integrales desde la concepción hasta la implementación y el soporte",
    PL: "Kompleksowe rozwiązania od koncepcji po wdrożenie i wsparcie",
  },

  featureItemText2: {
    RU: "Высококвалифицированная команда с опытом в сфере IT и маркетинга",
    EN: "A highly skilled team with expertise in IT and marketing.",
    ES: "Un equipo altamente calificado con experiencia en TI y marketing.",
    PL: "Wykwalifikowany zespół z doświadczeniem w branży IT i marketingu.",
  },

  featureItemText3: {
    RU: "Индивидуальный подход к каждому проекту",
    EN: "Individual approach to each project",
    ES: "Enfoque individual para cada proyecto.",
    PL: "Indywidualne podejście do każdego projektu",
  },

  // HomeServiceTypes

  homeServiceTypesTitle: {
    RU: (
      <>
        Создание <span className="primaryTxt">веб-сайтов</span>
      </>
    ),
    EN: (
      <>
        Website <span className="primaryTxt">development</span>
      </>
    ),
    ES: (
      <>
        Desarrollo <span className="primaryTxt">de sitios web</span>
      </>
    ),
    PL: (
      <>
        Tworzenie <span className="primaryTxt">stron internetowych</span>
      </>
    ),
  },

  homeServiceTypesSubtitle: {
    RU: "Создаем адаптированные и функциональные сайты под любые цели",
    EN: "We create adapted and functional websites for any purpose",
    ES: "Creamos sitios web adaptados y funcionales para cualquier propósito",
    PL: "Tworzymy adaptacyjne i funkcjonalne strony webowe do dowolnego celu",
  },

  // HomeTargeting

  homeTargetingTitle: {
    RU: (
      <>
        <span className="primaryTxt">Таргетированная</span> реклама
      </>
    ),
    EN: (
      <>
        <span className="primaryTxt">Targeted </span> advertising
      </>
    ),
    ES: (
      <>
        <span className="primaryTxt">Publicidad </span> dirigida
      </>
    ),
    PL: (
      <>
        <span className="primaryTxt">Reklama</span> targetowana
      </>
    ),
  },

  homeTargetingSubtitle: {
    RU: "Мы помогаем вам привлечь нужную аудиторию и потенциальных клиентов для вашего бизнеса",
    EN: "We help you attract the right audience and potential customers for your business",
    ES: "Le ayudamos a atraer la audiencia adecuada y clientes potenciales para su negocio.",
    PL: "Pomagamy Ci przyciągnąć odpowiednią grupę docelową i potencjalnych klientów dla Twojego biznesu",
  },

  homeTargetingListItem1: {
    RU: "Базовый и продвинутый таргетинг для бизнеса любого уровня",
    EN: "Basic and advanced targeting for any level of business",
    ES: "Segmentación básica y avanzada para negocios de cualquier nivel.",
    PL: "Podstawowe i zaawansowane targetowanie dla biznesu na każdym poziomie",
  },

  homeTargetingListItem2: {
    RU: "Таргетинг для интернет-магазинов для увеличения продаж",
    EN: "Targeting for online shops to increase sales",
    ES: "Segmentación para tiendas en línea para aumentar las ventas",
    PL: "Targetowanie dla sklepów internetowych w celu zwiększenia sprzedaży",
  },

  homeTargetingListItem3: {
    RU: "Полный пакет под ключ с полным сопровождением и аналитикой",
    EN: "Full turnkey package with full support and analytics",
    ES: "Paquete completo llave en mano con soporte total y análisis.",
    PL: "Kompleksowy pakiet pod klucz z pełnym wsparciem i analityką",
  },

  //HomeAutoCommunication

  homeAutoCommunicationTitle: {
    RU: (
      <>
        <span className="primaryTxt">Автоматизация общения</span> с клиентами
      </>
    ),
    EN: (
      <>
        <span className="primaryTxt">Automation of communication</span> with
        customers
      </>
    ),
    ES: (
      <>
        <span className="primaryTxt">Automatización de la comunicación</span>{" "}
        con los clientes
      </>
    ),
    PL: (
      <>
        <span className="primaryTxt">Automatyzacja komunikacji</span> z
        klientami
      </>
    ),
  },

  homeAutoCommunicationSubtitle: {
    RU: "Ускорьте и упростите взаимодействие с клиентами через чат-ботов",
    EN: "Speed up and simplify customer interactions through chatbots",
    ES: "Acelere y simplifique las interacciones con los clientes a través de chatbots",
    PL: "Przyspiesz i uprość interakcje z klientami dzięki chatbotom",
  },

  chatBotCRM: {
    RU: "Базовый и интерактивный чат-бот с CRM интеграцией",
    EN: "Basic and interactive chatbot with CRM integration",
    ES: "Chatbot básico e interactivo con integración CRM",
    PL: "Podstawowy i interaktywny chatbot z integracją CRM ",
  },

  chatBotSettings: {
    RU: "Полный чат-бот под ключ с уникальными настройками",
    EN: "Complete turnkey chatbot with unique customizations",
    ES: "Chatbot completo llave en mano con personalizaciones únicas",
    PL: "Kompleksowy chatbot pod klucz z unikalnymi ustawieniami ",
  },

  chatBotAnalitics: {
    RU: "Продвинутый чат-бот с аналитикой для глубокого понимания запросов",
    EN: "Advanced chatbot with analytics for deep query insights",
    ES: "Chatbot avanzado con análisis para una comprensión profunda de las consultas",
    PL: "Zaawansowany chatbot z analityką do głębszego zrozumienia zapytań",
  },

  //HomeProjects

  homeProjectsTitle: {
    RU: (
      <>
        Проекты и <span className="primaryTxt">кейсы</span>
      </>
    ),
    EN: (
      <>
        Projects and <span className="primaryTxt">cases</span>
      </>
    ),
    ES: (
      <>
        Proyectos y <span className="primaryTxt">casos</span>
      </>
    ),
    PL: (
      <>
        Projekty i <span className="primaryTxt">realizacje</span>
      </>
    ),
  },

  homeProjectsAllProjects: {
    RU: "Все кейсы",
    EN: "All Projects",
    ES: "Todos los proyectos",
    PL: "Wszystkie projekty",
  },

  homeProjectsBigProjectDescription: {
    RU: "Основной задачей было создание современного и информативного сайта для компании L’EMIGRANT, которая предоставляет юридические и иммиграционные услуги.",
    EN: "The main task was to create a modern and informative website for L’EMIGRANT, a company that provides legal and migration services.",
    ES: "La tarea principal fue crear un sitio web moderno e informativo para L’EMIGRANT, una empresa que ofrece servicios legales y de migración.",
    PL: "Głównym zadaniem było stworzenie nowoczesnej i informacyjnej strony internetowej dla firmy L’EMIGRANT, która świadczy usługi prawne i imigracyjne.",
  },

  homeProjectsBigProjectResultTutleTxt: {
    RU: "Результаты работы",
    EN: "Results of work",
    ES: "Resultados del trabajo",
    PL: "Wyniki pracy",
  },

  homeProjectsBigProjectResultTxt: {
    RU: "Сайт позволил упростить процесс поиска информации о юридических и миграционных услугах, количество запросов увеличилось",
    EN: "The website has simplified the process of searching for information on legal and migration services, and the number of requests has increased",
    ES: "El sitio web ha simplificado el proceso de búsqueda de información sobre servicios legales y de migración, y el número de solicitudes ha aumentado.",
    PL: "Strona uprościła proces wyszukiwania informacji o usługach prawnych i imigracyjnych, a liczba zapytań wzrosła.",
  },

  contactBlockBtn: {
    RU: "Заполнить форму",
    EN: "Fill in the form",
    ES: "Rellenar el formulario",
    PL: "Wypełnij formularz",
  },

  // About us

  //AboutHeader

  aboutHeaderTitleLg: {
    RU: (
      <>
        <span className="primaryTxt">Кто мы</span> и чем занимаемся?
      </>
    ),
    EN: (
      <>
        <span className="primaryTxt">Who we are</span> and what we do?
      </>
    ),
    ES: (
      <>
        <span className="primaryTxt">¿Quiénes somos</span> y qué hacemos?
      </>
    ),
    PL: (
      <>
        <span className="primaryTxt">Kim jesteśmy</span> i czym się zajmujemy?{" "}
      </>
    ),
  },
  aboutHeaderSubtitleLg: {
    RU: "Мы — команда экспертов в области IT-услуг, помогающая бизнесам расширить свои возможности и достичь успеха в цифровом мире. От создания сайтов до автоматизации общения с клиентами — мы предлагаем комплексные решения, которые ориентированы на ваш рост и успех.",
    EN: "We're a team of IT services experts helping businesses empower themselves and succeed in the digital world. From building websites to automating customer communication, we offer end-to-end solutions that focus on your growth and success.",
    ES: "Somos un equipo de expertos en servicios de TI que ayuda a las empresas a ampliar sus posibilidades y alcanzar el éxito en el mundo digital. Desde la creación de sitios web hasta la automatización de la comunicación con los clientes, ofrecemos soluciones integrales centradas en su crecimiento y éxito.",
    PL: "Jesteśmy zespołem ekspertów w dziedzinie usług IT, pomagającym firmom rozwijać swoje możliwości i osiągać sukces w cyfrowym świecie. Od tworzenia stron internetowych po automatyzację komunikacji z klientami — oferujemy kompleksowe rozwiązania, które koncentrują się na Twoim rozwoju i sukcesie.",
  },

  // AboutMission

  aboutMissionTitle: {
    RU: (
      <>
        Наша миссия и <span className="primaryTxt">ценности</span>
      </>
    ),
    EN: (
      <>
        Our mission and <span className="primaryTxt">values</span>
      </>
    ),
    ES: (
      <>
        Nuestra misión y <span className="primaryTxt">valores </span>
      </>
    ),
    PL: (
      <>
        Nasza misja i <span className="primaryTxt">wartości</span>
      </>
    ),
  },
  aboutMissionMainTextBlockTitle: {
    RU: "Миссия:",
    EN: "Mission:",
    ES: "Misión:",
    PL: "Misja:",
  },
  aboutMissionMainTextBlockDescription: {
    RU: "Нашей целью является предоставление высококачественных и инновационных цифровых решений, которые помогают бизнесам достигать новых вершин.",
    EN: "Our aim is to provide high quality and innovative digital solutions that help businesses reach new heights.",
    ES: "Nuestro objetivo es proporcionar soluciones digitales de alta calidad e innovadoras que ayuden a las empresas a alcanzar nuevas alturas.",
    PL: "Naszym celem jest dostarczanie wysokiej jakości i innowacyjnych rozwiązań cyfrowych, które pomagają firmom osiągać nowe szczyty.",
  },

  aboutMissionFeature1: {
    RU: "Мы придерживаемся самых высоких стандартов и обеспечиваем качество на каждом этапе проекта",
    EN: "We adhere to the highest standards and ensure quality at every stage of the project",
    ES: "Nos adherimos a los más altos estándares y garantizamos calidad en cada etapa del proyecto.",
    PL: "Przestrzegamy najwyższych standardów i zapewniamy jakość na każdym etapie projektu.",
  },

  aboutMissionFeature2: {
    RU: "Понимание задач клиента позволяет нам разрабатывать уникальные и эффективные решения",
    EN: "Understanding our client's challenges allows us to develop unique and effective solutions",
    ES: "Comprender los desafíos de nuestros clientes nos permite desarrollar soluciones únicas y efectivas",
    PL: "Zrozumienie potrzeb klienta pozwala nam opracowywać unikalne i skuteczne rozwiązania",
  },

  aboutMissionFeature3: {
    RU: "Мы используем передовые технологии и подходы, чтобы всегда оставаться в тренде и предлагать лучшие решения",
    EN: "We use advanced technologies and approaches to always stay on trend and offer the best solutions",
    ES: "Utilizamos tecnologías y enfoques avanzados para estar siempre a la vanguardia y ofrecer las mejores soluciones",
    PL: "Korzystamy z zaawansowanych technologii i podejść, aby zawsze być na czasie i oferować najlepsze rozwiązania",
  },

  aboutMissionFeature4: {
    RU: "Мы обеспечиваем полную поддержку наших клиентов даже после завершения проектов",
    EN: "We provide full support to our clients even after the completion of projects",
    ES: "Brindamos soporte completo a nuestros clientes incluso después de la finalización de los proyectos",
    PL: "Zapewniamy pełne wsparcie naszym klientom nawet po zakończeniu projektów",
  },

  // AboutComand

  aboutComandTitleLg: {
    RU: (
      <>
        Наша <span className="primaryTxt">команда</span>
      </>
    ),
    EN: (
      <>
        Our <span className="primaryTxt">team</span>
      </>
    ),
    ES: (
      <>
        Nuestro <span className="primaryTxt">equipo</span>
      </>
    ),
    PL: (
      <>
        Nasz <span className="primaryTxt">zespół</span>
      </>
    ),
  },
  aboutComandSubtitleLg: {
    RU: "Каждый член команды вносит свой вклад в успех компании и работает над достижением наилучших результатов для клиентов.",
    EN: "Each team member contributes to the success of the company and works to achieve the best results for clients.",
    ES: "Cada miembro del equipo contribuye al éxito de la empresa y trabaja para lograr los mejores resultados para los clientes.",
    PL: "Każdy członek zespołu wnosi swój wkład w sukces firmy i dąży do osiągnięcia najlepszych wyników dla klientów.",
  },
  aboutComandDevelopers: {
    RU: "Разработчики",
    EN: "Developers",
    ES: "Desarrolladores",
    PL: "Programiści",
  },
  aboutComandDevelopersDescription: {
    RU: "Опытные специалисты, которые создают функциональные и стильные сайты",
    EN: "Experienced professionals who create functional and stylish websites",
    ES: "Profesionales experimentados que crean sitios web funcionales y elegantes",
    PL: "Doświadczeni specjaliści, którzy tworzą funkcjonalne i stylowe strony webowe",
  },
  aboutComandMarketers: {
    RU: "Маркетологи",
    EN: "Marketing experts",
    ES: "Expertos en marketing",
    PL: "Specjaliści ds. marketingu",
  },
  aboutComandrMarketersDescription: {
    RU: "Эксперты по таргетированной рекламе, понимающие потребности вашей аудитории",
    EN: "Targeted advertising experts who understand the needs of your audience",
    ES: "Expertos en publicidad dirigida que comprenden las necesidades de su audiencia",
    PL: "Eksperci od reklamy targetowanej, którzy rozumieją potrzeby Twojej grupy docelowej",
  },
  aboutComandAutomation: {
    RU: "Специалисты по автоматизации",
    EN: " Automation experts",
    ES: "Expertos en automatización",
    PL: "Eksperci ds. automatyzacji",
  },
  aboutComandAutomationDescription: {
    RU: "Профессионалы, которые внедряют чат-боты и системы интеграции CRM для вашего бизнеса",
    EN: "Professionals who implement chatbots and CRM integration systems for your business",
    ES: "Profesionales que implementan chatbots y sistemas de integración CRM para su negocio",
    PL: "Profesjonaliści wdrażający chatboty i systemy integracji CRM dla Twojej firmy",
  },

  //AboutFeatures

  aboutFeaturesTitleLg: {
    RU: (
      <>
        Почему <span className="primaryTxt">выбирают нас?</span>
      </>
    ),
    EN: (
      <>
        Why <span className="primaryTxt">choose us?</span>
      </>
    ),
    ES: (
      <>
        ¿Por qué <span className="primaryTxt">elegirnos?</span>
      </>
    ),
    PL: (
      <>
        Dlaczego <span className="primaryTxt">warto wybrać nas?</span>
      </>
    ),
  },

  aaboutFeature1: {
    RU: "Высокая квалификация и опыт сотрудников",
    EN: "Highly qualified and experienced employees",
    ES: "Empleados altamente calificados y con experiencia",
    PL: "Wykwalifikowani i doświadczeni pracownicy",
  },
  aaboutFeature2: {
    RU: "Комплексные решения от одного поставщика",
    EN: "Complete solutions from one supplier",
    ES: "Soluciones completas de un solo proveedor",
    PL: " Kompleksowe rozwiązania od jednego dostawcy",
  },
  aaboutFeature3: {
    RU: "Гибкий и индивидуальный подход к каждому проекту",
    EN: "Flexible and individual approach to each project",
    ES: "Enfoque flexible e individual para cada proyecto",
    PL: "Elastyczne i indywidualne podejście do każdego projektu",
  },
  aaboutFeature4: {
    RU: "Поддержка на всех этапах работы — от концепции до внедрения",
    EN: "Support at all stages of work - from concept to implementation",
    ES: "Soporte en todas las etapas del trabajo, desde la concepción hasta la implementación",
    PL: "Wsparcie na każdym etapie pracy — od koncepcji po wdrożenie",
  },

  // AboutContactBlock

  aboutContactBlockDescription: {
    RU: "Готовы начать? Свяжитесь с нами, чтобы обсудить ваш проект и узнать, как мы можем помочь вашему бизнесу расти и развиваться",
    EN: "Ready to get started? Contact us to discuss your project and find out how we can help your business grow and develop",
    ES: "¿Listo para empezar? Contáctenos para discutir su proyecto y descubrir cómo podemos ayudar a su negocio a crecer y desarrollarse",
    PL: "Gotowi, aby zacząć? Skontaktuj się z nami, aby omówić swój projekt i dowiedzieć się, jak możemy pomóc Twojej firmie rozwijać się i wzrastać",
  },

  // Services page

  // ServicesHeader

  servicesHeaderTitleLg: {
    RU: (
      <>
        Мы предлагаем широкий спектр{" "}
        <span className="primaryTxt">IT-услуг</span> для{" "}
        <span className="primaryTxt">Вашего бизнеса</span>
      </>
    ),
    EN: (
      <>
        We offer a wide range of <span className="primaryTxt">IT services</span>{" "}
        for <span className="primaryTxt">your business</span>
      </>
    ),
    ES: (
      <>
        Ofrecemos una amplia gama de{" "}
        <span className="primaryTxt">servicios de TI</span> para{" "}
        <span className="primaryTxt">su negocio</span>
      </>
    ),
    PL: (
      <>
        Oferujemy szeroki zakres <span className="primaryTxt">usług IT</span>{" "}
        dla <span className="primaryTxt">Twojej firmy</span>
      </>
    ),
  },

  servicesHeaderSubtitleLg: {
    RU: "Наша цель — помочь вам достичь успеха в цифровом мире, используя инновационные технологии и индивидуальный подход к каждому проекту",
    EN: "Our goal is to help you succeed in the digital world, using innovative technology and a personalised approach to each project",
    ES: "Nuestro objetivo es ayudarle a tener éxito en el mundo digital, utilizando tecnología innovadora y un enfoque personalizado para cada proyecto",
    PL: "Naszym celem jest pomóc Ci osiągnąć sukces w cyfrowym świecie, wykorzystując innowacyjne technologie i indywidualne podejście do każdego projektu",
  },

  // ServicesWebsite

  servicesWebsiteTitle: {
    RU: (
      <>
        Создание <span className="primaryTxt">веб-сайтов</span>
      </>
    ),
    EN: (
      <>
        <span className="primaryTxt">Website</span> development
      </>
    ),
    ES: (
      <>
        Desarrollo <span className="primaryTxt">de sitios web</span>
      </>
    ),
    PL: (
      <>
        Tworzenie <span className="primaryTxt">stron internetowych</span>
      </>
    ),
  },
  servicesWebsiteSubtitle: {
    RU: (
      <>
        Создаем веб-решения, которые помогают вашему бизнесу <br />
        выделиться и привлечь клиентов.
      </>
    ),
    EN: (
      <>
        Creating web solutions that help your business <br />
        stand out and attract customers.
      </>
    ),
    ES: (
      <>
        Creamos soluciones web que ayudan a su negocio <br />a destacar y atraer
        clientes.
      </>
    ),
    PL: (
      <>
        Tworzymy rozwiązania webowe, które pomagają Twojej firmie <br />
        wyróżnić się i przyciągnąć klientów.
      </>
    ),
  },

  // Sefvices Accordion

  // Landing Page

  landingName: {
    RU: ["Landing", " Page"],
    EN: ["Landing", " Page"],
    ES: ["Landing", " Page"],
    PL: ["Strona", " Landing Page"],
  },

  landingDescription: {
    RU: "для быстрой презентации вашего продукта",
    EN: "for a quick presentation of your product",
    ES: "para una presentación rápida de su producto",
    PL: "do szybkiej prezentacji Twojego produktu",
  },

  landingStep1Name: {
    RU: "Консультация и планирование",
    EN: "Consultation and planning",
    ES: "Consulta y planificación",
    PL: "Doradztwo i planowanie",
  },

  landingStep1Description: {
    RU: "Первые встречи для понимания ваших целей, аудитории и желаемых результатов. Это включает в себя создание плана проекта и временных рамок.",
    EN: "Initial meetings to understand your goals, audience and desired outcomes. This includes creating a project plan and timeline.",
    ES: "Reuniones iniciales para comprender sus objetivos, audiencia y resultados deseados. Esto incluye la creación de un plan de proyecto y un cronograma.",
    PL: "Początkowe spotkania w celu zrozumienia Twoich celów, odbiorców i oczekiwanych rezultatów. Obejmuje to stworzenie planu projektu i harmonogramu",
  },

  landingStep2Name: {
    RU: "Дизайн и разработка",
    EN: "Design and development",
    ES: "Diseño y desarrollo",
    PL: "Projektowanie i rozwój",
  },

  landingStep2Description: {
    RU: "Индивидуальные дизайнерские услуги для создания визуально привлекательного и удобного макета. Услуги по разработке для создания целевой страницы с использованием современных веб-технологий.",
    EN: "Customised design services to create a visually appealing and user-friendly layout. Development services to create a landing page using modern web technologies.",
    ES: "Servicios de diseño personalizados para crear un diseño visualmente atractivo y fácil de usar. Servicios de desarrollo para crear una página de destino utilizando tecnologías web modernas.",
    PL: "Spersonalizowane usługi projektowe w celu stworzenia atrakcyjnego wizualnie i przyjaznego dla użytkownika układu. Usługi programistyczne w zakresie tworzenia strony docelowej z wykorzystaniem nowoczesnych technologii internetowych.",
  },

  landingStep3Name: {
    RU: "Создание контента",
    EN: "Content Creation",
    ES: "Creación de contenido",
    PL: "Tworzenie treści",
  },

  landingStep3Description: {
    RU: "Профессиональное написание текстов и создание мультимедийного контента (изображения, видео, графика) для привлечения посетителей и эффективной передачи вашего сообщения.",
    EN: "Professional text writing and multimedia content creation (images, videos, graphics) to engage visitors and effectively communicate your message.",
    ES: "Redacción profesional de textos y creación de contenido multimedia (imágenes, videos, gráficos) para atraer visitantes y comunicar eficazmente su mensaje.",
    PL: "Profesjonalne tworzenie tekstów i treści multimedialnych (zdjęcia, filmy, grafiki) w celu zaangażowania odwiedzających i skutecznego przekazywania Twojego komunikatu.",
  },

  landingStep4Name: {
    RU: "SEO-оптимизация",
    EN: "SEO Optimization",
    ES: "Optimización SEO",
    PL: "Optymalizacja SEO",
  },

  landingStep4Description: {
    RU: "Реализация лучших практик для оптимизации поисковых систем с целью улучшения видимости вашей целевой страницы в поисковых системах.",
    EN: "Implementing best practices for search engine optimisation to improve the visibility of your landing page in search engines.",
    ES: "Implementación de las mejores prácticas para la optimización de motores de búsqueda con el fin de mejorar la visibilidad de su página de destino en los motores de búsqueda.",
    PL: "Wdrażanie najlepszych praktyk optymalizacji pod kątem wyszukiwarek w celu poprawy widoczności Twojej strony docelowej w wynikach wyszukiwania.",
  },

  landingStep5Name: {
    RU: "A/B-тестирование",
    EN: "A/B testing",
    ES: "Pruebas A/B",
    PL: "Testowanie A/B",
  },

  landingStep5Description: {
    RU: "Проведение тестов для сравнения различных версий целевой страницы, чтобы определить, какая из них показывает лучшие результаты в плане конверсий.",
    EN: "Conducting tests to compare different versions of a landing page to determine which one performs better in terms of conversions.",
    ES: "Realización de pruebas para comparar diferentes versiones de una página de destino y determinar cuál ofrece mejores resultados en términos de conversiones. ",
    PL: "Przeprowadzanie testów porównujących różne wersje strony docelowej w celu określenia, która z nich osiąga lepsze wyniki pod względem konwersji.",
  },

  landingStep6Name: {
    RU: "Аналитика и отчетность",
    EN: "Analytics and reporting",
    ES: "Análisis e informes",
    PL: "Analityka i raportowanie",
  },

  landingStep6Description: {
    RU: "Настройка инструментов для отслеживания поведения посетителей и предоставление подробных отчетов о производительности целевой страницы.",
    EN: "Set up tools to track visitor behaviour and provide detailed reports on landing page performance.",
    ES: "Configuración de herramientas para rastrear el comportamiento de los visitantes y proporcionar informes detallados sobre el rendimiento de la página de destino.",
    PL: "Konfiguracja narzędzi do śledzenia zachowań odwiedzających oraz dostarczanie szczegółowych raportów dotyczących wydajności strony docelowej.",
  },

  landingStep7Name: {
    RU: "Поддержка после запуска",
    EN: "Post-launch support",
    ES: "Soporte post-lanzamiento",
    PL: "Wsparcie po uruchomieniu",
  },

  landingStep7Description: {
    RU: "Постоянная поддержка и обслуживание, чтобы обеспечить хорошую производительность целевой страницы и внести необходимые обновления или изменения.",
    EN: "Ongoing support and maintenance to ensure good landing page performance and make any necessary updates or changes.",
    ES: "Soporte y mantenimiento continuos para garantizar un buen rendimiento de la página de destino y realizar actualizaciones o cambios necesarios.",
    PL: "Stałe wsparcie i konserwacja, aby zapewnić dobrą wydajność strony internetowej oraz wprowadzać niezbędne aktualizacje i zmiany.",
  },

  // Technologies - Landing Page

  landingTechnologiesName1: {
    RU: "Конструкторы целевых страниц",
    EN: "Landing page builders",
    ES: "Creadores de páginas de destino",
    PL: "Kreatory stron docelowych",
  },
  landingTechnologiesDescription1: {
    RU: "Инструменты, такие как Unbounce, Leadpages или Instapage, которые предлагают функциональность перетаскивания и предустановленные шаблоны для ускорения процесса создания",
    EN: "Tools such as Unbounce, Leadpages or Instapage that offer drag and drop functionality and pre-defined templates to speed up the creation process",
    ES: "Herramientas como Unbounce, Leadpages o Instapage que ofrecen funcionalidad de arrastrar y soltar y plantillas predefinidas para agilizar el proceso de creación.",
    PL: "Narzędzia takie jak Unbounce, Leadpages lub Instapage, które oferują funkcje przeciągania i upuszczania oraz gotowe szablony w celu przyspieszenia procesu tworzenia",
  },

  landingTechnologiesName2: {
    RU: "Системы управления контентом (CMS)",
    EN: "Content Management Systems (CMS)",
    ES: "Sistemas de gestión de contenido (CMS)",
    PL: "Systemy zarządzania treścią (CMS)",
  },
  landingTechnologiesDescription2: {
    RU: "Платформы, такие как WordPress, Joomla или Drupal, которые позволяют легко обновлять и управлять контентом",
    EN: "Platforms such as WordPress, Joomla or Drupal that make it easy to update and manage content",
    ES: "Plataformas como WordPress, Joomla o Drupal que facilitan la actualización y gestión del contenido",
    PL: "Platformy takie jak WordPress, Joomla lub Drupal, które umożliwiają łatwe aktualizowanie i zarządzanie treścią",
  },

  landingTechnologiesName3: {
    RU: "Фреймворки адаптивного дизайна",
    EN: "Adaptive design frameworks",
    ES: "Marcos de diseño adaptativo",
    PL: "Frameworki responsywnego designu",
  },
  landingTechnologiesDescription3: {
    RU: "Фреймворки, такие как Bootstrap или Foundation, которые обеспечивают хороший внешний вид целевой страницы на всех устройствах, от настольных компьютеров до смартфонов",
    EN: "Frameworks, such as Bootstrap or Foundation, that ensure a landing page looks good on all devices, from desktops to smartphones",
    ES: "Marcos como Bootstrap o Foundation que garantizan que una página de destino se vea bien en todos los dispositivos, desde computadoras de escritorio hasta teléfonos inteligentes",
    PL: "Frameworki, takie jak Bootstrap lub Foundation, które zapewniają dobry wygląd strony internetowej na wszystkich urządzeniach, od komputerów stacjonarnych po smartfony",
  },

  landingTechnologiesName4: {
    RU: "Инструменты A/B-тестирования",
    EN: "A/B testing tools",
    ES: "Herramientas de pruebas A/B",
    PL: "Narzędzia do testowania A/B",
  },
  landingTechnologiesDescription4: {
    RU: "Платформы, такие как Optimizely или VWO, для проведения экспериментов и определения наиболее эффективных элементов дизайна и контента",
    EN: "Platforms such as Optimizely or VWO to experiment and determine the most effective design and content elements",
    ES: "Plataformas como Optimizely o VWO para realizar experimentos y determinar los elementos de diseño y contenido más efectivos",
    PL: "Platformy takie jak Optimizely lub VWO do przeprowadzania eksperymentów i określenia najskuteczniejszych elementów designu i treści",
  },

 
  landingTechnologiesName5: {
    RU: "Основные технологии для создания и стилизации веб-страниц",
    EN: "Basic technologies for creating and styling web pages",
    ES: "Tecnologías básicas para crear y estilizar páginas web",
    PL: "Podstawowe technologie do tworzenia i stylizowania stron internetowych",
  },
  landingTechnologiesDescription5: {
    RU: "HTML, CSS и JavaScript",
    EN: "HTML, CSS and JavaScript",
    ES: "HTML, CSS y JavaScript",
    PL: "HTML, CSS i JavaScript",
  },

  landingTechnologiesName6: {
    RU: "Меры безопасности",
    EN: "Security measures",
    ES: "Medidas de seguridad",
    PL: "Środki bezpieczeństwa",
  },
  landingTechnologiesDescription6: {
    RU: "Реализация SSL-сертификатов, регулярные обновления и другие меры безопасности для защиты целевой страницы и ее посетителей",
    EN: "Implement SSL certificates, regular updates and other security measures to protect the landing page and its visitors",
    ES: "Implementación de certificados SSL, actualizaciones regulares y otras medidas de seguridad para proteger la página de destino y sus visitantes",
    PL: "Wdrażanie certyfikatów SSL, regularne aktualizacje i inne środki bezpieczeństwa w celu ochrony strony docelowej i jej odwiedzających",
  },

  landingTechnologiesName7: {
    RU: "SEO-инструменты",
    EN: "SEO tools",
    ES: "Herramientas de SEO ",
    PL: "Narzędzia SEO",
  },
  landingTechnologiesDescription7: {
    RU: "Инструменты, такие как Google Analytics, SEMrush или Ahrefs, для оптимизации целевой страницы для поисковых систем и отслеживания ее производительности",
    EN: "Tools such as Google Analytics, SEMrush or Ahrefs to optimise your landing page for search engines and track its performance",
    ES: "Herramientas como Google Analytics, SEMrush o Ahrefs para optimizar su página de destino para motores de búsqueda y rastrear su rendimiento",
    PL: "Narzędzia takie jak Google Analytics, SEMrush lub Ahrefs do optymalizacji strony docelowej pod kątem wyszukiwarek i monitorowania jej wydajności",
  },

  // E-commerce website

  ecommerceName: {
    RU: ["Интернет", " -магазин"],
    EN: ["E-commerce", " website"],
    ES: ["Sitio web", " de comercio electrónico"],
    PL: ["Strona internetowa", " e-commerce"],
  },

  ecommerceDescription: {
    RU: "для эффективных онлайн-продаж",
    EN: "for effective online sales",
    ES: "para ventas en línea efectivas",
    PL: "dla efektywnej sprzedaży online",
  },

  ecommerceStep1Name: {
    RU: "Консультация и планирование",
    EN: "Consultation and planning",
    ES: "Consulta y planificación",
    PL: "Konsultacja i planowanie",
  },

  ecommerceStep1Description: {
    RU: "Подробные обсуждения для понимания вашей бизнес-модели, целевой аудитории и конкретных требований. Это включает в себя создание всеобъемлющего плана проекта и временных рамок.",
    EN: "Detailed discussions to understand your business model, target audience and specific requirements. This includes creating a comprehensive project plan and timeline.",
    ES: "Discusiones detalladas para comprender su modelo de negocio, público objetivo y requisitos específicos. Esto incluye la creación de un plan de proyecto integral y un cronograma",
    PL: "Szczegółowe dyskusje w celu zrozumienia Twojego modelu biznesowego, grupy docelowej i konkretnych wymagań. Obejmuje to stworzenie kompleksowego planu projektu i harmonogramu.",
  },

  ecommerceStep2Name: {
    RU: "Индивидуальный дизайн и разработка",
    EN: "Customised design and development",
    ES: "Diseño y desarrollo personalizados",
    PL: "Indywidualny projekt i rozwój",
  },

  ecommerceStep2Description: {
    RU: "Индивидуальные дизайнерские услуги для создания уникального внешнего вида, соответствующего вашему бренду. Услуги по разработке для создания сайта с использованием надежных технологий, которые обеспечивают масштабируемость и производительность.",
    EN: "Customised design services to create a unique look and feel to match your brand. Development services to build your website using robust technologies that ensure scalability and performance.",
    ES: "Servicios de diseño personalizados para crear una apariencia única que coincida con su marca. Servicios de desarrollo para construir su sitio web utilizando tecnologías robustas que garanticen escalabilidad y rendimiento.",
    PL: "Spersonalizowane usługi projektowe do stworzenia unikalnego wyglądu zgodnego z Twoją marką. Usługi tworzenia strony internetowej z użyciem niezawodnych technologii zapewniających skalowalność i wydajność.",
  },

  ecommerceStep3Name: {
    RU: "Управление продуктами",
    EN: "Product Management",
    ES: "Gestión de productos",
    PL: "Zarządzanie produktami",
  },

  ecommerceStep3Description: {
    RU: "Помощь в настройке и управлении вашим каталогом продуктов, включая описания продуктов, изображения и категории.",
    EN: "Help you set up and manage your product catalogue, including product descriptions, images and categories.",
    ES: "Ayuda para configurar y gestionar su catálogo de productos, incluyendo descripciones de productos, imágenes y categorías.",
    PL: "Pomoc w konfiguracji i zarządzaniu katalogiem produktów, w tym opisami, zdjęciami i kategoriami.",
  },

  ecommerceStep4Name: {
    RU: "Интеграция платежных систем",
    EN: "Payment system integration",
    ES: "Integración de sistemas de pago",
    PL: "Integracja systemów płatności",
  },

  ecommerceStep4Description: {
    RU: "Безопасная интеграция нескольких вариантов оплаты (кредитные карты, PayPal и т. д.) для обеспечения гладких транзакций.",
    EN: "Securely integrate multiple payment options (credit cards, PayPal, etc.) to ensure smooth transactions.",
    ES: "Integración segura de múltiples opciones de pago (tarjetas de crédito, PayPal, etc.) para garantizar transacciones fluidas.",
    PL: "Bezpieczna integracja wielu opcji płatności (karty kredytowe, PayPal itp.) w celu zapewnienia płynnych transakcji.",
  },

  ecommerceStep5Name: {
    RU: "Корзина покупок и оформление заказа",
    EN: "Shopping basket and checkout",
    ES: "Cesta de la compra y pago",
    PL: "Koszyk zakupów i realizacja zamówienia",
  },

  ecommerceStep5Description: {
    RU: "Разработка удобной корзины покупок и процесса оформления заказа для минимизации отказов от покупки и улучшения пользовательского опыта.",
    EN: "Develop a user-friendly shopping cart and checkout process to minimise abandonment and improve user experience.",
    ES: "Desarrollo de un carrito de compras y un proceso de pago fáciles de usar para minimizar los abandonos y mejorar la experiencia del usuario.",
    PL: "Opracowanie przyjaznego dla użytkownika koszyka i procesu zamówienia w celu zmniejszenia liczby porzuceń i poprawy doświadczeń użytkownika.",
  },

  ecommerceStep6Name: {
    RU: "SEO и маркетинг",
    EN: "SEO and marketing",
    ES: "SEO y marketing",
    PL: "SEO i marketing",
  },

  ecommerceStep6Description: {
    RU: "Реализация лучших практик SEO для улучшения позиций в поисковых системах, а также маркетинговых инструментов, таких как email-кампании, интеграция с социальными сетями и рекламные баннеры.",
    EN: "Implementing SEO best practices to improve search engine positions as well as marketing tools such as email campaigns, social media integration and banner ads.",
    ES: "Implementación de las mejores prácticas de SEO para mejorar las posiciones en los motores de búsqueda, así como herramientas de marketing como campañas por correo electrónico, integración con redes sociales y banners publicitarios.",
    PL: "Wdrażanie najlepszych praktyk SEO w celu poprawy pozycji w wyszukiwarkach oraz narzędzi marketingowych, takich jak kampanie e-mailowe, integracja z mediami społecznościowymi i banery reklamowe.",
  },

  ecommerceStep7Name: {
    RU: "Аналитика и отчетность",
    EN: "Analytics and reporting",
    ES: "Análisis e informes",
    PL: "Analityka i raportowanie",
  },

  ecommerceStep7Description: {
    RU: "Настройка аналитических инструментов для отслеживания продаж, поведения клиентов и других ключевых метрик. Предоставление подробных отчетов, которые помогут вам принимать обоснованные бизнес-решения.",
    EN: "Set up analytical tools to track sales, customer behaviour and other key metrics. Provide detailed reports to help you make informed business decisions.",
    ES: "Configuración de herramientas analíticas para rastrear ventas, comportamiento de los clientes y otras métricas clave. Provisión de informes detallados para ayudarle a tomar decisiones comerciales informadas.",
    PL: "Konfiguracja narzędzi analitycznych do śledzenia sprzedaży, zachowań klientów i innych kluczowych wskaźników. Dostarczanie szczegółowych raportów, które wspierają podejmowanie świadomych decyzji biznesowych.",
  },

  ecommerceStep8Name: {
    RU: "Меры безопасности",
    EN: "Security measures",
    ES: "Medidas de seguridad",
    PL: "Środki bezpieczeństwa",
  },

  ecommerceStep8Description: {
    RU: "Реализация строгих протоколов безопасности, включая SSL-сертификаты, шифрование данных и регулярные аудиты безопасности для защиты данных клиентов и транзакций.",
    EN: "Implementation of strict security protocols including SSL certificates, data encryption and regular security audits to protect customer data and transactions.",
    ES: "Implementación de estrictos protocolos de seguridad, incluidos certificados SSL, encriptación de datos y auditorías de seguridad regulares para proteger los datos de los clientes y las transacciones.",
    PL: "Wdrażanie rygorystycznych protokołów bezpieczeństwa, takich jak certyfikaty SSL, szyfrowanie danych i regularne audyty bezpieczeństwa w celu ochrony danych klientów i transakcji.",
  },

  ecommerceStep9Name: {
    RU: "Мобильная адаптивность",
    EN: "Mobile Adaptability",
    ES: "Adaptabilidad móvil",
    PL: "Mobilna adaptacyjność",
  },

  ecommerceStep9Description: {
    RU: "Обеспечение полной адаптивности сайта и предоставление бесперебойного опыта на всех устройствах, включая смартфоны и планшеты.",
    EN: "Ensuring the site is fully adaptable and provides a seamless experience across all devices, including smartphones and tablets.",
    ES: "Garantizar que el sitio sea completamente adaptable y proporcione una experiencia fluida en todos los dispositivos, incluidos teléfonos inteligentes y tabletas.",
    PL: "Zapewnienie pełnej responsywności strony i płynnego działania na wszystkich urządzeniach, w tym smartfonach i tabletach.",
  },

  ecommerceStep10Name: {
    RU: "Поддержка после запуска",
    EN: "Post-launch support",
    ES: "Apoyo posterior al lanzamiento",
    PL: "Wsparcie po uruchomieniu",
  },

  ecommerceStep10Description: {
    RU: "Постоянные услуги поддержки и обслуживания для решения любых проблем, выполнения обновлений и обеспечения бесперебойной работы сайта.",
    EN: "Ongoing support and maintenance services to resolve any issues, perform updates and keep your site running smoothly.",
    ES: "Servicios de soporte y mantenimiento continuos para resolver problemas, realizar actualizaciones y mantener su sitio funcionando sin problemas.",
    PL: "Ciągłe usługi wsparcia i konserwacji w celu rozwiązywania problemów, przeprowadzania aktualizacji i zapewnienia płynnego działania strony.",
  },

  // Technologies - E-commerce

  ecommerceTechnologiesName1: {
    RU: "Платформы электронной коммерции",
    EN: "E-commerce platforms",
    ES: "Plataformas de comercio electrónico",
    PL: "Platformy e-commerce",
  },
  ecommerceTechnologiesDescription1: {
    RU: "Платформы, такие как Shopify, WooCommerce, Magento или BigCommerce, которые предоставляют надежные функции электронной коммерции",
    EN: "Platforms such as Shopify, WooCommerce, Magento or BigCommerce that provide robust e-commerce features",
    ES: "Plataformas como Shopify, WooCommerce, Magento o BigCommerce que ofrecen funciones sólidas de comercio electrónico",
    PL: "Platformy takie jak Shopify, WooCommerce, Magento lub BigCommerce, które oferują solidne funkcje e-commerce",
  },

  ecommerceTechnologiesName2: {
    RU: "Системы управления взаимоотношениями с клиентами (CRM)",
    EN: "Customer Relationship Management (CRM) systems",
    ES: "Sistemas de gestión de las relaciones con los clientes (CRM)",
    PL: "Systemy CRM",
  },
  ecommerceTechnologiesDescription2: {
    RU: "Интеграция с CRM-системами для эффективного управления взаимодействием и данными клиентов",
    EN: "Integration with CRM systems to effectively manage customer interactions and data",
    ES: "Integración con sistemas CRM para gestionar eficazmente las interacciones y datos de los clientes",
    PL: "Integracja z systemami CRM w celu efektywnego zarządzania interakcjami oraz danymi klientów",
  },

  ecommerceTechnologiesName3: {
    RU: "SEO-инструменты",
    EN: "SEO tools",
    ES: "Herramientas SEO",
    PL: "Narzędzia SEO",
  },
  ecommerceTechnologiesDescription3: {
    RU: "Инструменты, такие как Google Analytics, SEMrush или Ahrefs, для оптимизации сайта для поисковых систем и отслеживания производительности",
    EN: "Tools such as Google Analytics, SEMrush or Ahrefs to optimise your site for search engines and track performance",
    ES: "Herramientas como Google Analytics, SEMrush o Ahrefs para optimizar su sitio para motores de búsqueda y rastrear el rendimiento",
    PL: "Narzędzia takie jak Google Analytics, SEMrush lub Ahrefs do optymalizacji strony internetowej pod kątem wyszukiwarek i monitorowania wydajności",
  },

  ecommerceTechnologiesName4: {
    RU: "Фреймворки адаптивного дизайна",
    EN: "Adaptive design frameworks",
    ES: "Marcos de diseño adaptativo",
    PL: "Frameworki responsywnego designu",
  },
  ecommerceTechnologiesDescription4: {
    RU: "Фреймворки, такие как Bootstrap или Foundation, которые обеспечивают хороший внешний вид сайта на всех устройствах",
    EN: "Frameworks, such as Bootstrap or Foundation, that ensure that the site looks good on all devices",
    ES: "Frameworks como Bootstrap o Foundation que garantizan que el sitio luzca bien en todos los dispositivos",
    PL: "Frameworki takie jak Bootstrap lub Foundation, które zapewniają dobry wygląd strony na wszystkich urządzeniach",
  },

  ecommerceTechnologiesName5: {
    RU: "Системы управления запасами",
    EN: "Inventory management systems",
    ES: "Sistemas de gestión de inventario",
    PL: "Systemy zarządzania zapasam",
  },
  ecommerceTechnologiesDescription5: {
    RU: "Инструменты для управления уровнями запасов, заказами и логистикой цепочки поставок",
    EN: "Tools for managing inventory levels, orders and supply chain logistics.",
    ES: "Herramientas para gestionar niveles de inventario, pedidos y logística de la cadena de suministro",
    PL: "Narzędzia do zarządzania poziomami zapasów, zamówieniami i logistyką łańcucha dostaw",
  },

  ecommerceTechnologiesName6: {
    RU: "Системы управления контентом (CMS)",
    EN: "Content Management Systems (CMS)",
    ES: "Content Management Systems (CMS)",
    PL: "Systemy CMS",
  },
  ecommerceTechnologiesDescription6: {
    RU: "Системы, такие как WordPress или Joomla, которые позволяют легко обновлять и управлять контентом",
    EN: "Systems such as WordPress or Joomla that make it easy to update and manage content",
    ES: "Sistemas como WordPress o Joomla que facilitan la actualización y gestión de contenidos",
    PL: "Systemy takie jak WordPress lub Joomla, które umożliwiają łatwe zarządzanie treścią oraz jej aktualizację",
  },

  ecommerceTechnologiesName7: {
    RU: "Платежные шлюзы",
    EN: "Payment gateways",
    ES: "Pasarelas de pago",
    PL: "Bramki płatnicze ",
  },
  ecommerceTechnologiesDescription7: {
    RU: "Интеграция с процессорами платежей, такими как Stripe, PayPal или Square, для безопасной обработки транзакций",
    EN: "Integration with payment processors such as Stripe, PayPal or Square for secure transaction processing",
    ES: "ntegración con procesadores de pago como Stripe, PayPal o Square para el procesamiento seguro de transacciones",
    PL: "Integracja z procesorami płatności, takimi jak Stripe, PayPal lub Square, w celu bezpiecznego przetwarzania transakcji",
  },

  ecommerceTechnologiesName8: {
    RU: "Технологии безопасности",
    EN: "Security Technologies",
    ES: "Tecnologías de seguridad",
    PL: "Technologie bezpieczeństwa",
  },
  ecommerceTechnologiesDescription8: {
    RU: "SSL-сертификаты, файрволы и регулярные обновления безопасности для защиты сайта и данных клиентов",
    EN: "SSL certificates, firewalls and regular security updates to protect the site and customer data",
    ES: "Certificados SSL, cortafuegos y actualizaciones de seguridad regulares para proteger el sitio y los datos de los clientes",
    PL: "Certyfikaty SSL, zapory ogniowe i regularne aktualizacje bezpieczeństwa w celu ochrony strony i danych klientów",
  },

  // Redesign

  redesignName: {
    RU: ["Ремонт и редизайн", " существующих сайтов"],
    EN: ["Fix & Redesign", " existing website"],
    ES: ["Reparación y rediseño", " de sitios web existentes"],
    PL: ["Naprawa i przeprojektowanie", " istniejących stron internetowych"],
  },

  redesignDescription: {
    RU: "для улучшения функциональности и дизайна",
    EN: "to improve functionality and design",
    ES: "para mejorar la funcionalidad y el diseño",
    PL: "w celu poprawy funkcjonalności i wyglądu",
  },

  redesignStep1Name: {
    RU: "Консультация и оценка",
    EN: "Consultation and assessment",
    ES: "Consulta y evaluación",
    PL: "Konsultacja i ocena",
  },

  redesignStep1Description: {
    RU: "Первые встречи для понимания текущих проблем, целей и желаемых результатов. Это включает в себя тщательную оценку производительности и дизайна существующего сайта.",
    EN: "Initial meetings to understand current issues, goals and desired outcomes. This includes a thorough evaluation of the performance and design of the existing site.",
    ES: "Reuniones iniciales para comprender los problemas actuales, los objetivos y los resultados deseados. Esto incluye una evaluación exhaustiva del rendimiento y el diseño del emplazamiento existente.",
    PL: "Pierwsze spotkania mające na celu zrozumienie obecnych problemów, celów i oczekiwanych rezultatów. Obejmuje to szczegółową ocenę wydajności i designu istniejącej strony.",
  },

  redesignStep2Name: {
    RU: "Стратегия редизайна",
    EN: "Redesign strategy",
    ES: "Estrategia de rediseño",
    PL: "Strategia redesignu",
  },

  redesignStep2Description: {
    RU: "Разработка комплексного плана редизайна, включая установку четких и количественных целей (например, улучшение пользовательского опыта, увеличение конверсий).",
    EN: "Develop a comprehensive redesign plan, including setting clear and quantifiable goals (e.g., improve user experience, increase conversions).",
    ES: "Desarrollar un plan de rediseño completo, que incluya el establecimiento de objetivos claros y cuantificables (por ejemplo, mejorar la experiencia del usuario, aumentar las conversiones).",
    PL: "Opracowanie kompleksowego planu redesignu, obejmującego ustalenie jasnych i mierzalnych celów (np. poprawa doświadczeń użytkownika, zwiększenie konwersji).",
  },

  redesignStep3Name: {
    RU: "Аудит контента и обновление",
    EN: "Content Audit and Updates",
    ES: "Auditoría y actualización de contenidos",
    PL: "Audyt treści i aktualizacja",
  },

  redesignStep3Description: {
    RU: "Анализ существующего контента для определения того, что можно использовать повторно, что нужно обновить или создать заново. Это включает в себя текст, изображения, видео и другие медиа.",
    EN: "Analysing existing content to determine what can be reused, what needs to be updated or re-created. This includes text, images, video and other media.",
    ES: "Análisis del contenido existente para determinar qué puede reutilizarse y qué debe actualizarse o crearse de nuevo. Esto incluye texto, imágenes, vídeo y otros medios.",
    PL: "Analiza istniejących treści w celu określenia, co można ponownie wykorzystać, co wymaga aktualizacji lub stworzenia od nowa. Obejmuje to teksty, obrazy, wideo i inne media.",
  },

  redesignStep4Name: {
    RU: "Дизайн и разработка",
    EN: "Design and development",
    ES: "Diseño y desarrollo",
    PL: "Projektowanie i rozwój",
  },

  redesignStep4Description: {
    RU: "Индивидуальные дизайнерские услуги для создания современного, удобного макета. Услуги по разработке для реализации нового дизайна с использованием современных технологий.",
    EN: "Customised design services to create a modern, user-friendly layout. Development services to realise a new design using modern technology.",
    ES: "Servicios de diseño personalizados para crear un diseño moderno y fácil de usar. Servicios de desarrollo para realizar un nuevo diseño utilizando tecnología moderna.",
    PL: "Indywidualne usługi projektowe do stworzenia nowoczesnego, intuicyjnego układu. Usługi rozwojowe do wdrożenia nowego projektu z wykorzystaniem nowoczesnych technologii.",
  },

  redesignStep5Name: {
    RU: "SEO и оптимизация производительности",
    EN: "SEO and performance optimisation",
    ES: "SEO y optimización del rendimiento",
    PL: "SEO i optymalizacja wydajności",
  },

  redesignStep5Description: {
    RU: "Реализация лучших практик SEO и оптимизация сайта для более быстрого времени загрузки и лучшей производительности.",
    EN: "Implement SEO best practices and optimise your site for faster load times and better performance.",
    ES: "Aplique las mejores prácticas de SEO y optimice su sitio web para conseguir tiempos de carga más rápidos y un mejor rendimiento.",
    PL: "Wdrażanie najlepszych praktyk SEO i optymalizacja strony pod kątem szybszego czasu ładowania i lepszej wydajności.",
  },

  redesignStep6Name: {
    RU: "Тестирование и обеспечение качества",
    EN: "Testing and Quality Assurance",
    ES: "Pruebas y control de calidad",
    PL: "Testowanie i zapewnienie jakości",
  },

  redesignStep6Description: {
    RU: "Тщательное тестирование для обеспечения бесшовной работы нового дизайна на всех устройствах и браузерах. Это включает в себя тестирование удобства использования и исправление любых ошибок или проблем,",
    EN: "Thorough testing to ensure the new design works seamlessly across all devices and browsers. This includes usability testing and fixing any bugs or issues.",
    ES: "Pruebas exhaustivas para garantizar que el nuevo diseño funciona a la perfección en todos los dispositivos y navegadores. Esto incluye pruebas de usabilidad y corrección de errores o problemas.",
    PL: "Skrupulatne testowanie, aby zapewnić płynne działanie nowego projektu na wszystkich urządzeniach i przeglądarkach. Obejmuje to testowanie użyteczności i naprawę wszelkich błędów lub problemów.",
  },

  redesignStep7Name: {
    RU: "Миграция и запуск",
    EN: "Migration and launch",
    ES: "Migración y lanzamiento",
    PL: "Migracja i uruchomienie",
  },

  redesignStep7Description: {
    RU: "Безопасная миграция существующего контента на новый дизайн и обеспечение плавного запуска с минимальными перерывами в работе сайта",
    EN: "Securely migrate existing content to the new design and ensure a smooth launch with minimal site disruption.",
    ES: "Migre de forma segura el contenido existente al nuevo diseño y garantice un lanzamiento sin problemas con la mínima interrupción del sitio.",
    PL: "Bezpieczna migracja istniejących treści do nowego projektu oraz zapewnienie płynnego uruchomienia przy minimalnych zakłóceniach w działaniu strony.",
  },

  redesignStep8Name: {
    RU: "Поддержка после запуска",
    EN: "Post-launch support",
    ES: "Asistencia posterior al lanzamiento",
    PL: "Wsparcie po uruchomieniu",
  },

  redesignStep8Description: {
    RU: "Постоянная поддержка и обслуживание для решения любых проблем, выполнения обновлений и обеспечения бесперебойной работы сайта",
    EN: "Ongoing support and maintenance to resolve any issues, perform updates and keep your site running smoothly.",
    ES: "Asistencia y mantenimiento continuos para resolver cualquier problema, realizar actualizaciones y mantener su sitio web en perfecto funcionamiento.",
    PL: "Stałe wsparcie i konserwacja w celu rozwiązywania problemów, wykonywania aktualizacji i zapewnienia płynnego działania strony.",
  },

  // Technologies - Redesign

  redesignTechnologiesName1: {
    RU: "Технологии безопасности",
    EN: "Security Technologies",
    ES: "Tecnologías de seguridad",
    PL: "Technologie bezpieczeństwa",
  },
  redesignTechnologiesDescription1: {
    RU: "SSL-сертификаты, файрволы и регулярные обновления безопасности для защиты сайта и данных пользователей",
    EN: "SSL certificates, firewalls and regular security updates to protect the site and user data",
    ES: "Certificados SSL, cortafuegos y actualizaciones periódicas de seguridad para proteger el sitio y los datos de los usuarios",
    PL: "Certyfikaty SSL, zapory ogniowe i regularne aktualizacje bezpieczeństwa w celu ochrony strony i danych użytkowników",
  },

  redesignTechnologiesName2: {
    RU: "SEO-инструменты",
    EN: "SEO tools",
    ES: "Herramientas SEO",
    PL: "Narzędzia SEO ",
  },
  redesignTechnologiesDescription2: {
    RU: "Инструменты, такие как Google Analytics, SEMrush или Ahrefs, для оптимизации сайта для поисковых систем и отслеживания производительности",
    EN: "Tools such as Google Analytics, SEMrush or Ahrefs to optimise your site for search engines and track performance",
    ES: "Herramientas como Google Analytics, SEMrush o Ahrefs para optimizar su sitio para los motores de búsqueda y realizar un seguimiento del rendimiento",
    PL: "Narzędzia takie jak Google Analytics, SEMrush lub Ahrefs do optymalizacji strony internetowej pod kątem wyszukiwarek i monitorowania wydajności",
  },

  redesignTechnologiesName3: {
    RU: "Инструменты A/B-тестирования",
    EN: "A/B testing tools",
    ES: "Herramientas de pruebas A/B",
    PL: "Narzędzia do testów A/B",
  },
  redesignTechnologiesDescription3: {
    RU: "Платформы, такие как Optimizely или VWO, для проведения экспериментов и определения наиболее эффективных элементов дизайна и контента",
    EN: "Platforms such as Optimizely or VWO to experiment and determine the most effective design and content elements",
    ES: "Plataformas como Optimizely o VWO para experimentar y determinar los elementos de diseño y contenido más eficaces",
    PL: "Platformy takie jak Optimizely lub VWO do przeprowadzania eksperymentów i identyfikacji najbardziej efektywnych elementów projektu i treści",
  },

  redesignTechnologiesName4: {
    RU: "Инструменты оптимизации производительности",
    EN: "Performance Optimisation Tools",
    ES: "Herramientas de optimización del rendimiento",
    PL: "Narzędzia optymalizacji wydajności",
  },
  redesignTechnologiesDescription4: {
    RU: "Инструменты, такие как Google PageSpeed Insights, для анализа и улучшения скорости и производительности сайта",
    EN: "Tools such as Google PageSpeed Insights to analyse and improve site speed and performance",
    ES: "Herramientas como Google PageSpeed Insights para analizar y mejorar la velocidad y el rendimiento del sitio",
    PL: "Narzędzia takie jak Google PageSpeed Insights do analizy i poprawy szybkości oraz wydajności strony",
  },

  redesignTechnologiesName5: {
    RU: "Системы управления контентом (CMS)",
    EN: "Content Management Systems (CMS)",
    ES: "Sistemas de gestión de contenidos (CMS)",
    PL: "Systemy zarządzania treścią (CMS)",
  },
  redesignTechnologiesDescription5: {
    RU: "Платформы, такие как WordPress, Joomla или Drupal, которые позволяют легко обновлять и управлять контентом",
    EN: "Platforms such as WordPress, Joomla or Drupal that make it easy to update and manage content",
    ES: "Plataformas como WordPress, Joomla o Drupal que facilitan la actualización y gestión de contenidos",
    PL: "Platformy takie jak WordPress, Joomla lub Drupal, które umożliwiają łatwe aktualizowanie i zarządzanie treścią",
  },

  redesignTechnologiesName6: {
    RU: "Фреймворки адаптивного дизайна",
    EN: "Adaptive design frameworks",
    ES: "Marcos de diseño adaptable",
    PL: "Frameworki responsywnego designu",
  },
  redesignTechnologiesDescription6: {
    RU: "Фреймворки, такие как Bootstrap или Foundation, которые обеспечивают хороший внешний вид сайта на всех устройствах",
    EN: "Frameworks, such as Bootstrap or Foundation, that ensure that the site looks good on all devices",
    ES: "Marcos, como Bootstrap o Foundation, que garantizan que el sitio se vea bien en todos los dispositivos",
    PL: "Frameworki takie jak Bootstrap lub Foundation, które zapewniają atrakcyjny wygląd strony na wszystkich urządzeniach",
  },

  // SEO

  seoName: {
    RU: ["Оптимизация", " для поисковых систем (SEO)"],
    EN: ["Optimisation", " for search engines (SEO)"],
    ES: ["Optimización ", " para motores de búsqueda (SEO)"],
    PL: ["Optymalizacja", " dla wyszukiwarek (SEO)"],
  },

  seoDescription: {
    RU: "для увеличения трафика и видимости сайта",
    EN: "to increase traffic and website visibility",
    ES: "para aumentar el tráfico y la visibilidad del sitio web",
    PL: "zwiększenie ruchu i widoczności strony",
  },

  seoStep1Name: {
    RU: "SEO-аудит",
    EN: "SEO Audit",
    ES: "Auditoría SEO",
    PL: "Audyt SEO",
  },

  seoStep1Description: {
    RU: "Комплексный анализ текущего веб-сайта для выявления сильных и слабых сторон, а также возможностей для улучшения. Это включает в себя техническое SEO, on-page SEO и off-page SEO.",
    EN: "A comprehensive analysis of your current website to identify strengths, weaknesses, and opportunities for improvement. This includes technical SEO, on-page SEO and off-page SEO.",
    ES: "Un análisis exhaustivo de su sitio web actual para identificar puntos fuertes, puntos débiles y oportunidades de mejora. Esto incluye SEO técnico, SEO on-page y SEO off-page.",
    PL: "Kompleksowa analiza obecnej strony internetowej w celu zidentyfikowania mocnych i słabych stron oraz możliwości poprawy. Obejmuje to techniczne SEO, on-page SEO i off-page SEO.",
  },

  seoStep2Name: {
    RU: "Исследование ключевых слов",
    EN: "Keyword research",
    ES: "Búsqueda de palabras clave",
    PL: "Badanie słów kluczowych",
  },

  seoStep2Description: {
    RU: "Определение наиболее релевантных и высоко посещаемых ключевых слов для вашей компании. Это помогает оптимизировать контент для привлечения нужной аудитории.",
    EN: "Identifying the most relevant and highly visited keywords for your company. This helps optimise content to attract the right audience.",
    ES: "Identificar las palabras clave más relevantes y visitadas para su empresa. Esto ayuda a optimizar el contenido para atraer al público adecuado.",
    PL: "Identyfikacja najbardziej istotnych i popularnych słów kluczowych dla Twojej firmy. Pomaga to w optymalizacji treści w celu przyciągnięcia odpowiedniej grupy odbiorców.",
  },

  seoStep3Name: {
    RU: "On-Page SEO",
    EN: "On-Page SEO",
    ES: "SEO en la página",
    PL: "On-Page SEO",
  },

  seoStep3Description: {
    RU: "Оптимизация отдельных страниц на вашем сайте для улучшения их позиций в поисковых системах. Это включает в себя оптимизацию заголовков, метаописаний, заголовков и контента.",
    EN: "Optimising individual pages on your website to improve their position in search engines. This includes optimising headings, meta descriptions, titles and content.",
    ES: "Optimización de páginas individuales de su sitio web para mejorar su posición en los motores de búsqueda. Esto incluye la optimización de encabezados, meta descripciones, títulos y contenido.",
    PL: "Optymalizacja poszczególnych stron na Twojej witrynie w celu poprawy ich pozycji w wyszukiwarkach. Obejmuje to optymalizację nagłówków, metaopisów, nagłówków i treści.",
  },

  seoStep4Name: {
    RU: "Техническое SEO",
    EN: "Technical SEO",
    ES: "SEO técnico",
    PL: "Techniczne SEO",
  },

  seoStep4Description: {
    RU: "Обеспечение оптимизации технических аспектов сайта для поисковых систем. Это включает в себя улучшение скорости загрузки сайта, мобильной совместимости и исправление ошибок индексации.",
    EN: "Ensuring that the technical aspects of a website are optimised for search engines. This includes improving site loading speed, mobile compatibility and fixing indexing errors.",
    ES: "Garantizar que los aspectos técnicos de un sitio web estén optimizados para los motores de búsqueda. Esto incluye la mejora de la velocidad de carga del sitio, la compatibilidad móvil y la corrección de errores de indexación.",
    PL: "Zapewnienie optymalizacji technicznych aspektów strony dla wyszukiwarek. Obejmuje to poprawę szybkości ładowania strony, kompatybilności mobilnej oraz naprawę błędów indeksowania.",
  },

  seoStep5Name: {
    RU: "Создание и оптимизация контента",
    EN: "Content creation and optimisation",
    ES: "Creación y optimización de contenidos",
    PL: "Tworzenie i optymalizacja treści",
  },

  seoStep5Description: {
    RU: "Создание качественного, актуального контента, который вовлекает пользователей и оптимизирован для поисковых систем. Это включает в себя блоги, статьи и другие типы контента.",
    EN: "Creating quality, relevant content that engages users and is optimized for search engines. This includes blogs, articles and other types of content.",
    ES: "Creación de contenidos relevantes y de calidad que atraigan a los usuarios y estén optimizados para los motores de búsqueda. Esto incluye blogs, artículos y otros tipos de contenido.",
    PL: "Tworzenie wysokiej jakości, odpowiednich treści, które angażują użytkowników i są zoptymalizowane pod kątem wyszukiwarek. Obejmuje to blogi, artykuły i inne rodzaje treści.",
  },

  seoStep6Name: {
    RU: "Ссылочное строительство",
    EN: "Link Building",
    ES: "Creación de enlaces",
    PL: "Budowanie linków",
  },

  seoStep6Description: {
    RU: "Получение высококачественных обратных ссылок с авторитетных веб-сайтов для улучшения авторитета вашего сайта и его позиций в поисковых системах.",
    EN: "Obtaining high quality backlinks from authoritative websites to improve your website's authority and position in search engines.",
    ES: "Obtención de vínculos de retroceso de alta calidad de sitios web autorizados para mejorar la autoridad y la posición de su sitio web en los motores de búsqueda.",
    PL: "Pozyskiwanie wysokiej jakości linków zwrotnych z autorytatywnych stron internetowych w celu poprawy autorytetu Twojej witryny i jej pozycji w wyszukiwarkach.",
  },

  seoStep7Name: {
    RU: "Локальное SEO",
    EN: "Local SEO",
    ES: "SEO local",
    PL: "Lokalne SEO",
  },

  seoStep7Description: {
    RU: "Оптимизация вашего веб-сайта для привлечения местных клиентов. Это включает в себя оптимизацию профиля в Google My Business и обеспечение консистентности информации о вашем NAP (название, адрес, номер телефона) на всех ресурсах.",
    EN: "Optimising your website to attract local customers. This includes optimising your Google My Business profile and ensuring your NAP information (name, address, phone number) is consistent across all resources.",
    ES: "Optimización de su sitio web para atraer clientes locales. Esto incluye la optimización de su perfil de Google My Business y asegurarse de que su información NAP (nombre, dirección, número de teléfono) es coherente en todos los recursos.",
    PL: "Optymalizacja strony internetowej w celu przyciągnięcia lokalnych klientów. Obejmuje to optymalizację profilu w Google My Business oraz zapewnienie spójności informacji NAP (nazwa, adres, telefon) we wszystkich źródłach.",
  },

  seoStep8Name: {
    RU: "Аналитика и отчетность",
    EN: "Analytics and reporting",
    ES: "Análisis e informes",
    PL: "Analiza i raportowanie",
  },

  seoStep8Description: {
    RU: "Настройка инструментов для отслеживания результатов ваших SEO-усилий и предоставление регулярных отчетов о ключевых показателях, таких как трафик, позиции и конверсии.",
    EN: "Set up tools to track the results of your SEO efforts and provide regular reports on key metrics such as traffic, positions and conversions.",
    ES: "Configure herramientas para realizar un seguimiento de los resultados de sus esfuerzos de SEO y proporcione informes periódicos sobre métricas clave como el tráfico, las posiciones y las conversiones.",
    PL: "Konfiguracja narzędzi do monitorowania efektów działań SEO i regularne raportowanie kluczowych wskaźników, takich jak ruch, pozycje i konwersje.",
  },

  seoStep9Name: {
    RU: "Постоянные консультации и поддержка",
    EN: "Ongoing advice and suppor",
    ES: "Asesoramiento y apoyo continuos",
    PL: "Stałe konsultacje i wsparcie",
  },

  seoStep9Description: {
    RU: "Непрерывная поддержка и консультации для адаптации вашей SEO-стратегии на основе данных о производительности и изменений в алгоритмах поисковых систем.",
    EN: "Ongoing support and advice to adapt your SEO strategy based on performance data and changes in search engine algorithms.",
    ES: "Asistencia y asesoramiento continuos para adaptar su estrategia SEO en función de los datos de rendimiento y los cambios en los algoritmos de los motores de búsqueda.",
    PL: "Ciągłe wsparcie i doradztwo w celu dostosowywania strategii SEO na podstawie danych o wydajności i zmian w algorytmach wyszukiwarek.",
  },

  // Technologies - SEO

  seoTechnologiesName1: {
    RU: "SEO-инструменты",
    EN: "SEO tools",
    ES: "Herramientas SEO",
    PL: "Narzędzia SEO",
  },
  seoTechnologiesDescription1: {
    RU: "Инструменты, такие как Google Analytics, Google Search Console, SEMrush, Ahrefs и Moz, для отслеживания производительности, проведения исследования ключевых слов и анализа обратных ссылок",
    EN: "Tools such as Google Analytics, Google Search Console, SEMrush, Ahrefs and Moz to track performance, conduct keyword research and analyse backlinks",
    ES: "Herramientas como Google Analytics, Google Search Console, SEMrush, Ahrefs y Moz para hacer un seguimiento del rendimiento, realizar estudios de palabras clave y analizar los vínculos de retroceso",
    PL: "Narzędzia takie jak Google Analytics, Google Search Console, SEMrush, Ahrefs i Moz do monitorowania wydajności, badania słów kluczowych i analizy linków zwrotnych",
  },

  seoTechnologiesName2: {
    RU: "Инструменты технического SEO",
    EN: "Technical SEO tools",
    ES: "Herramientas de SEO técnico",
    PL: "Narzędzia do technicznego SEO",
  },
  seoTechnologiesDescription2: {
    RU: "Инструменты, такие как Screaming Frog, GTmetrix и PageSpeed Insights, для анализа и улучшения технических аспектов веб-сайта",
    EN: "Tools such as Screaming Frog, GTmetrix and PageSpeed Insights to analyse and improve the technical aspects of a website",
    ES: "Herramientas como Screaming Frog, GTmetrix y PageSpeed Insights para analizar y mejorar los aspectos técnicos de un sitio web",
    PL: "Narzędzia takie jak Screaming Frog, GTmetrix i PageSpeed Insights do analizy i poprawy technicznych aspektów strony",
  },

  seoTechnologiesName3: {
    RU: "Инструменты для ссылочного строительства",
    EN: "Link building tools",
    ES: "Herramientas de construcción de enlace",
    PL: "Narzędzia do budowania linków",
  },
  seoTechnologiesDescription3: {
    RU: "Инструменты, такие как Ahrefs, Moz и Majestic, для определения и получения высококачественных обратных ссылок.",
    EN: "Tools such as Ahrefs, Moz and Majestic to identify and get high quality backlinks.",
    ES: "Herramientas como Ahrefs, Moz y Majestic para identificar y obtener backlinks de alta calidad",
    PL: "Narzędzia takie jak Ahrefs, Moz i Majestic do identyfikacji i pozyskiwania wysokiej jakości linków zwrotnych.",
  },

  seoTechnologiesName4: {
    RU: "Инструменты для локального SEO",
    EN: "Local SEO tools",
    ES: "Herramientas de SEO loca",
    PL: "Narzędzia do lokalnego SEO",
  },
  seoTechnologiesDescription4: {
    RU: "Инструменты, такие как BrightLocal и Yext, для управления локальными списками и оптимизации для локального поиска",
    EN: "Tools such as BrightLocal and Yext to manage local listings and optimise for local search",
    ES: "Herramientas como BrightLocal y Yext para gestionar listados locales y optimizar la búsqueda local",
    PL: "Narzędzia takie jak BrightLocal i Yext do zarządzania lokalnymi listami i optymalizacji pod kątem lokalnych wyszukiwań",
  },

  seoTechnologiesName5: {
    RU: "Системы управления контентом (CMS)",
    EN: "Content Management Systems (CMS) ",
    ES: "Sistemas de gestión de contenidos (CMS)",
    PL: "Systemy zarządzania treścią (CMS)",
  },
  seoTechnologiesDescription5: {
    RU: "Платформы, такие как WordPress, Joomla или Drupal, которые позволяют легко обновлять и управлять контентом",
    EN: "Platforms such as WordPress, Joomla or Drupal that make it easy to update and manage content",
    ES: "Plataformas como WordPress, Joomla o Drupal que facilitan la actualización y gestión de contenidos",
    PL: "Platformy takie jak WordPress, Joomla lub Drupal, które umożliwiają łatwe aktualizowanie i zarządzanie treścią",
  },

  // Multi Page website

  multipageName: {
    RU: ["Многостраничный", " сайт"],
    EN: ["Multi Page", " website"],
    ES: ["Sitio web", " de múltiples páginas"],
    PL: ["Wielostronicowa", " strona internetowa"],
  },

  multipageDescription: {
    RU: "для компаний с широким спектром информации",
    EN: "for companies with a wide range of information",
    ES: "para empresas con una amplia gama de información",
    PL: "dla firm oferujących szeroki zakres informacji ",
  },

  multipageStep1Name: {
    RU: "Консультация и планирование",
    EN: "Consultation and planning",
    ES: "Consulta y planificación",
    PL: "Konsultacja i planowanie",
  },

  multipageStep1Description: {
    RU: "Подробные обсуждения для понимания ваших бизнес-целей, целевой аудитории и конкретных требований. Это включает в себя создание всеобъемлющего плана проекта и временных рамок.",
    EN: "Detailed discussions to understand your business objectives, target audience and specific requirements. This includes creating a comprehensive project plan and timeline.",
    ES: "Conversaciones detalladas para comprender los objetivos de su empresa, el público al que se dirige y sus requisitos específicos. Esto incluye la creación de un plan de proyecto y un calendario exhaustivos.",
    PL: "Szczegółowe dyskusje mające na celu zrozumienie Twoich celów biznesowych, docelowej grupy odbiorców oraz konkretnych wymagań. Obejmuje to stworzenie kompleksowego planu projektu i harmonogramu.",
  },

  multipageStep2Name: {
    RU: "Индивидуальный дизайн и разработка",
    EN: "Customised design and development",
    ES: "Diseño y desarrollo personalizados",
    PL: "Indywidualny projekt i rozwój",
  },

  multipageStep2Description: {
    RU: "Индивидуальные дизайнерские услуги для создания уникального внешнего вида, соответствующего вашему бренду. Услуги по разработке для создания сайта с использованием надежных технологий, которые обеспечивают масштабируемость и производительность.",
    EN: "Custom design services to create a unique look and feel that matches your brand. Development services to build a website using robust technologies that ensure scalability and performance.",
    ES: "Servicios de diseño personalizados para crear un aspecto único que se adapte a su marca. Servicios de desarrollo para crear un sitio web con tecnologías robustas que garanticen la escalabilidad y el rendimiento.",
    PL: "Spersonalizowane usługi projektowe w celu stworzenia unikalnego wyglądu i stylu zgodnego z Twoją marką. Usługi programistyczne do budowy strony internetowej z wykorzystaniem solidnych technologii zapewniających skalowalność i wydajność.",
  },

  multipageStep3Name: {
    RU: "Стратегия контента и создание",
    EN: "Content Strategy and Creation",
    ES: "Estrategia y creación de contenidos",
    PL: "Strategia i tworzenie treśc",
  },

  multipageStep3Description: {
    RU: "Помощь в разработке контентной стратегии и создании высококачественного контента для каждой страницы, включая текст, изображения, видео и графику.",
    EN: "Help you develop a content strategy and create high quality content for each page, including text, images, video and graphics.",
    ES: "Le ayudamos a desarrollar una estrategia de contenidos y a crear contenidos de alta calidad para cada página, incluyendo texto, imágenes, vídeo y gráficos.",
    PL: "Pomoc w opracowaniu strategii treści i tworzeniu wysokiej jakości materiałów dla każdej strony, w tym tekstów, zdjęć, wideo i grafik.",
  },

  multipageStep4Name: {
    RU: "SEO и маркетинг",
    EN: "SEO and marketing",
    ES: "SEO y marketing",
    PL: "SEO i marketing",
  },

  multipageStep4Description: {
    RU: "Реализация лучших практик SEO для улучшения позиций в поисковых системах, а также маркетинговых инструментов, таких как email-кампании, интеграция с социальными сетями и рекламные баннеры.",
    EN: "Implementing SEO best practices to improve search engine positions as well as marketing tools such as email campaigns, social media integration and banner ads.",
    ES: "Aplicación de las mejores prácticas de SEO para mejorar las posiciones en los motores de búsqueda, así como herramientas de marketing como campañas de correo electrónico, integración en redes sociales y anuncios en banners.",
    PL: "Wdrażanie najlepszych praktyk SEO w celu poprawy pozycji w wyszukiwarkach oraz narzędzi marketingowych, takich jak kampanie e-mailowe, integracja z mediami społecznościowymi i banery reklamowe.",
  },

  multipageStep5Name: {
    RU: "Адаптивный дизайн",
    EN: "Adaptive Design",
    ES: "Diseño adaptable",
    PL: "Projekt responsywny",
  },

  multipageStep5Description: {
    RU: "Обеспечение полной адаптивности сайта и предоставление бесперебойного опыта на всех устройствах, включая настольные компьютеры, планшеты и смартфоны.",
    EN: "Ensuring your site is fully adaptable and provides a seamless experience across all devices including desktops, tablets and smartphones.",
    ES: "Garantizamos que su sitio web sea totalmente adaptable y ofrezca una experiencia fluida en todos los dispositivos, incluidos ordenadores de sobremesa, tabletas y teléfonos inteligentes.",
    PL: "Zapewnienie pełnej responsywności strony i bezproblemowego działania na wszystkich urządzeniach, w tym komputerach stacjonarnych, tabletach i smartfonach.",
  },

  multipageStep6Name: {
    RU: "Навигация и пользовательский опыт (UX)",
    EN: "Navigation and User Experience (UX)",
    ES: "Navegación y experiencia del usuario (UX)",
    PL: "Nawigacja i doświadczenie użytkownika (UX)",
  },

  multipageStep6Description: {
    RU: "Проектирование интуитивно понятной структуры навигации и удобного интерфейса для улучшения общего пользовательского опыта.",
    EN: "Designing an intuitive navigation structure and user-friendly interface to improve the overall user experience.",
    ES: "Diseño de una estructura de navegación intuitiva y una interfaz fácil de usar para mejorar la experiencia general del usuario",
    PL: "Projektowanie intuicyjnej struktury nawigacji oraz przyjaznego interfejsu w celu poprawy ogólnego doświadczenia użytkownika",
  },

  multipageStep7Name: {
    RU: "Аналитика и отчетность",
    EN: "Analytics and reporting",
    ES: "Análisis e informes",
    PL: "Analityka i raportowanie",
  },

  multipageStep7Description: {
    RU: "Настройка аналитических инструментов для отслеживания поведения посетителей и предоставление подробных отчетов о производительности сайта.",
    EN: "Set up analytics tools to track visitor behaviour and provide detailed reports on site performance.",
    ES: "Configure herramientas de análisis para seguir el comportamiento de los visitantes y obtener informes detallados sobre el rendimiento del sitio.",
    PL: "Konfiguracja narzędzi analitycznych do śledzenia zachowań odwiedzających oraz dostarczanie szczegółowych raportów na temat wydajności strony.",
  },

  multipageStep8Name: {
    RU: "Меры безопасности",
    EN: "Security measures",
    ES: "Medidas de seguridad",
    PL: "Środki bezpieczeństwa",
  },

  multipageStep8Description: {
    RU: "Реализация строгих протоколов безопасности, включая SSL-сертификаты, шифрование данных и регулярные аудиты безопасности для защиты данных пользователей и транзакций.",
    EN: "Implementation of strict security protocols including SSL certificates, data encryption and regular security audits to protect user data and transactions.",
    ES: "Aplicación de protocolos de seguridad estrictos, incluidos certificados SSL, cifrado de datos y auditorías de seguridad periódicas para proteger los datos y las transacciones de los usuarios.",
    PL: "Wdrażanie rygorystycznych protokołów bezpieczeństwa, w tym certyfikatów SSL, szyfrowania danych i regularnych audytów bezpieczeństwa w celu ochrony danych użytkowników i transakcji.",
  },

  multipageStep9Name: {
    RU: "Поддержка после запуска",
    EN: "Post-launch support",
    ES: "Asistencia posterior al lanzamiento",
    PL: "Wsparcie po uruchomieniu",
  },

  multipageStep9Description: {
    RU: "Постоянные услуги поддержки и обслуживания для решения любых проблем, выполнения обновлений и обеспечения бесперебойной работы сайта.",
    EN: "Ongoing support and maintenance services to resolve any issues, perform updates and keep your site running smoothly.",
    ES: "Servicios continuos de asistencia y mantenimiento para resolver cualquier problema, realizar actualizaciones y mantener su sitio web en perfecto funcionamiento.",
    PL: "Stałe usługi wsparcia i konserwacji, aby rozwiązywać wszelkie problemy, przeprowadzać aktualizacje i zapewniać płynne działanie strony.",
  },

  // Technologies - Multi Page website

  multipageTechnologiesName1: {
    RU: "Управление базами данных",
    EN: "Database management",
    ES: "Gestión de bases de datos",
    PL: "Zarządzanie bazami danych",
  },
  multipageTechnologiesDescription1: {
    RU: "Использование надежных систем управления базами данных, таких как MySQL или PostgreSQL, для обработки хранения и извлечения данных",
    EN: "Using reliable database management systems such as MySQL or PostgreSQL to handle data storage and retrieval",
    ES: "Utilización de sistemas de gestión de bases de datos fiables, como MySQL o PostgreSQL, para gestionar el almacenamiento y la recuperación de datos",
    PL: "Wykorzystanie niezawodnych systemów zarządzania bazami danych, takich jak MySQL lub PostgreSQL, do obsługi przechowywania i pobierania danych",
  },

  multipageTechnologiesName2: {
    RU: "Системы управления контентом (CMS)",
    EN: "Content Management Systems (CMS)",
    ES: "Sistemas de gestión de contenidos (CMS",
    PL: "Systemy zarządzania treścią (CMS)",
  },
  multipageTechnologiesDescription2: {
    RU: "Платформы, такие как WordPress, Joomla или Drupal, которые позволяют легко обновлять и управлять контентом",
    EN: "Platforms such as WordPress, Joomla or Drupal that make it easy to update and manage content",
    ES: "Plataformas como WordPress, Joomla o Drupal que facilitan la actualización y gestión de contenidos",
    PL: "Platformy takie jak WordPress, Joomla lub Drupal, które umożliwiają łatwe aktualizowanie i zarządzanie treścią",
  },

  multipageTechnologiesName3: {
    RU: "Фреймворки адаптивного дизайна",
    EN: "Adaptive design frameworks",
    ES: "Marcos de diseño adaptable",
    PL: "Frameworki responsywnego designu",
  },
  multipageTechnologiesDescription3: {
    RU: "Фреймворки, такие как Bootstrap или Foundation, которые обеспечивают хороший внешний вид сайта на всех устройствах",
    EN: "Frameworks, such as Bootstrap or Foundation, ensure that the site looks good on all devices",
    ES: "Los frameworks, como Bootstrap o Foundation, garantizan que el sitio se vea bien en todos los dispositivos",
    PL: "Frameworki takie jak Bootstrap lub Foundation zapewniają, że strona prezentuje się dobrze na wszystkich urządzeniach",
  },

  multipageTechnologiesName4: {
    RU: "Системы управления взаимоотношениями с клиентами (CRM)",
    EN: "Customer Relationship Management (CRM) systems",
    ES: "Sistemas de gestión de las relaciones con los clientes (CRM)",
    PL: "Systemy zarządzania relacjami z klientami (CRM)",
  },
  multipageTechnologiesDescription4: {
    RU: "Интеграция с CRM-системами для эффективного управления взаимодействиями и данными клиентов",
    EN: "Integrate with CRM systems to effectively manage customer interactions and data",
    ES: "Integración con sistemas CRM para gestionar eficazmente las interacciones y los datos de los clientes",
    PL: "Integracja z systemami CRM w celu efektywnego zarządzania interakcjami oraz danymi klientów",
  },

  multipageTechnologiesName5: {
    RU: "Основные технологии для создания и стилизации веб-страниц",
    EN: "Basic technologies for creating and styling web pages",
    ES: "Tecnologías básicas para crear y estilizar páginas web",
    PL: "Podstawowe technologie tworzenia i stylizacji stron internetowych",
  },

  multipageTechnologiesDescription5: {
    RU: "HTML, CSS и JavaScript",
    EN: "HTML, CSS and JavaScript",
    ES: "HTML, CSS y JavaScript",
    PL: "HTML, CSS i JavaScript",
  },  

  multipageTechnologiesName6: {
    RU: "Меры безопасности",
    EN: "Security measures",
    ES: "Medidas de seguridad",
    PL: "Środki bezpieczeństwa",
  },
  multipageTechnologiesDescription6: {
    RU: "Реализация SSL-сертификатов, регулярные обновления и другие меры безопасности для защиты целевой страницы и ее посетителей",
    EN: "Implement SSL certificates, regular updates and other security measures to protect the landing page and its visitors",
    ES: "Implemente certificados SSL, actualizaciones periódicas y otras medidas de seguridad para proteger la página de destino y a sus visitantes",
    PL: "Wdrażanie certyfikatów SSL, regularne aktualizacje i inne środki bezpieczeństwa w celu ochrony strony docelowej i jej odwiedzających.",
  },

  multipageTechnologiesName7: {
    RU: "SEO-инструменты",
    EN: "SEO tools",
    ES: "Herramientas SEO",
    PL: "Narzędzia SEO",
  },
  multipageTechnologiesDescription7: {
    RU: "Инструменты, такие как Google Analytics, SEMrush или Ahrefs, для оптимизации сайта для поисковых систем и отслеживания производительности",
    EN: "Tools such as Google Analytics, SEMrush or Ahrefs to optimize your site for search engines and track performance",
    ES: "Herramientas como Google Analytics, SEMrush o Ahrefs para optimizar su sitio para los motores de búsqueda y realizar un seguimiento del rendimiento",
    PL: "Narzędzia takie jak Google Analytics, SEMrush lub Ahrefs do optymalizacji strony pod kątem wyszukiwarek i monitorowania jej wydajności",
  },

  // ServicesTargetting

  servicesTargettingTitleMd: {
    RU: (
      <>
        Услуги <span className="primaryTxt">таргетированной рекламы</span>
      </>
    ),
    EN: (
      <>
        <span className="primaryTxt">Targeted advertising</span> services
      </>
    ),
    ES: (
      <>
        Servicios <span className="primaryTxt">de publicidad dirigida</span>
      </>
    ),
    PL: (
      <>
        Usługi <span className="primaryTxt">reklamy targetowanej</span>
      </>
    ),
  },

  servicesTargettingSubtitle: {
    RU: "Помогаем вам привлечь нужную аудиторию и увеличить продажи через эффективные рекламные кампании",
    EN: "Helping you attract the right audience and increase sales through effective advertising campaigns",
    ES: "Le ayudamos a atraer al público adecuado y a aumentar las ventas mediante campañas publicitarias eficaces",
    PL: "Pomagamy przyciągnąć odpowiednią grupę odbiorców i zwiększyć sprzedaż dzięki skutecznym kampaniom reklamowym",
  },

  servicesTargettingTitle1: {
    RU: "Продвинутый таргетинг",
    EN: "Advanced targeting",
    ES: "Orientación avanzada",
    PL: "Zaawansowany targeting",
  },
  servicesTargettingDescription1: {
    RU: "Углубленные настройки для более точного попадания в целевую аудиторию",
    EN: "In-depth customizations for better targeting of your audience",
    ES: "Personalizaciones en profundidad para una mejor segmentación de su público",
    PL: "Zaawansowane ustawienia dla dokładniejszego dotarcia do grupy docelowej",
  },
  servicesTargettingFeature1: {
    RU: "Максимизация результатов при минимальных затратах",
    EN: "Maximising results at minimum cost",
    ES: "Máximos resultados con el mínimo coste",
    PL: "Maksymalizacja wyników przy minimalnych kosztach",
  },

  servicesTargettingTitle2: {
    RU: "Базовый таргетинг",
    EN: "Basic targeting",
    ES: "Orientación básica",
    PL: "Podstawowy targeting",
  },
  servicesTargettingDescription2: {
    RU: "Основные настройки для получения первой аудитории",
    EN: "Basic settings to get your first audience",
    ES: "Ajustes básicos para conseguir tu primera audiencia",
    PL: "Podstawowe ustawienia umożliwiające dotarcie do pierwszej grupy odbiorców",
  },
  servicesTargettingFeature2: {
    RU: "Доступный способ для начала работы с рекламой",
    EN: "An affordable way to get started with advertising",
    ES: "Una forma asequible de iniciarse en la publicidad",
    PL: "Przystępny sposób na rozpoczęcie działań reklamowych",
  },

  servicesTargettingTitle3: {
    RU: "Таргетинг для интернет-магазинов",
    EN: "Targeting for online shops",
    ES: "Segmentación para tiendas en línea",
    PL: "Targetowanie dla sklepów internetowych",
  },
  servicesTargettingDescription3: {
    RU: "Специальные кампании, нацеленные на увеличение продаж товаров онлайн",
    EN: "Special campaigns aimed at increasing sales of goods online",
    ES: "Campañas especiales destinadas a aumentar las ventas de productos en línea",
    PL: "Specjalne kampanie ukierunkowane na zwiększenie sprzedaży produktów online",
  },
  servicesTargettingFeature3: {
    RU: "Повышение видимости товаров и привлечение покупателей",
    EN: "Increase product visibility and customer engagement",
    ES: "Aumentar la visibilidad de los productos y el compromiso de los clientes",
    PL: "Zwiększenie widoczności produktów i zaangażowania klientów",
  },

  servicesTargettingTitle4: {
    RU: "Полный таргетинг под ключ",
    EN: "Full turnkey targeting",
    ES: "Orientación completa llave en mano",
    PL: "Kompleksowy targeting pod klucz",
  },
  servicesTargettingDescription4: {
    RU: "Комплексное решение для бизнеса, включающее разработку и сопровождение рекламной стратегии",
    EN: "Comprehensive solution for business, including development and support of advertising strategy",
    ES: "Solución integral para las empresas, incluido el desarrollo y el apoyo a la estrategia publicitaria",
    PL: "Kompleksowe rozwiązanie dla biznesu obejmujące opracowanie i wsparcie strategii reklamowej",
  },
  servicesTargettingFeature4: {
    RU: "Максимальная эффективность за счёт индивидуального подхода и экспертизы",
    EN: "Maximum efficiency through personalized approach and expertise",
    ES: "Máxima eficacia gracias a un enfoque y unos conocimientos personalizados",
    PL: "Maksymalna efektywność dzięki indywidualnemu podejściu i ekspertyzie",
  },

  // ServicesAutoCommunication

  servicesAutoCommunicationTitle: {
    RU: (
      <>
        Автоматизация общения с <br />
        клиентами <span className="primaryTxt">через мессенджеры</span>
      </>
    ),
    EN: (
      <>
        Automating communication with <br />
        customers <span className="primaryTxt">via messengers</span>
      </>
    ),
    ES: (
      <>
        Automatización de la comunicación <br />
        con los clientes{" "}
        <span className="primaryTxt">a través de mensajeros</span>
      </>
    ),
    PL: (
      <>
        Automatyzacja komunikacji z <br />
        klientami <span className="primaryTxt">przez komunikatory </span>
      </>
    ),
  },

  servicesAutoCommunicationSubtitle: {
    RU: "Упрощаем взаимодействие с клиентами и увеличиваем их удовлетворенность через современные технологии",
    EN: "Simplify customer interactions and increase customer satisfaction through modern technology",
    ES: "Simplifique las interacciones con los clientes y aumente su satisfacción gracias a una tecnología moderna",
    PL: "Ułatwiamy interakcje z klientami i zwiększamy ich satysfakcję dzięki nowoczesnym technologiom",
  },

  servicesAutoCommunicationName1: {
    RU: "Базовый чат-бот",
    EN: "Basic chatbot",
    ES: "Chatbot básico",
    PL: "Podstawowy chatbo",
  },
  servicesAutoCommunicationDescription1: {
    RU: "Простые чат-боты для автоматизации базовых запросов клиентов",
    EN: "Simple chatbots to automate basic customer queries",
    ES: "Chatbots sencillos para automatizar las consultas básicas de los clientes",
    PL: "Proste chatboty do automatyzacji podstawowych zapytań klientów",
  },
  sservicesAutoCommunicationFeature1: {
    RU: "Экономия времени и ресурсов на обработку запросов",
    EN: "Saving time and resources for processing requests",
    ES: "Ahorro de tiempo y recursos para tramitar las solicitudes",
    PL: "Oszczędność czasu i zasobów przy obsłudze zapytań",
  },

  servicesAutoCommunicationName2: {
    RU: "Интерактивный чат-бот с интеграцией CRM",
    EN: "Interactive chatbot with CRM integration",
    ES: "Chatbot interactivo con integración CRM",
    PL: "Interaktywny chatbot z integracją CRM",
  },
  servicesAutoCommunicationDescription2: {
    RU: "Чат-боты с возможностью интеграции с вашими системами управления клиентами",
    EN: "Chatbots with the ability to integrate with your customer management systems",
    ES: "Chatbots con capacidad de integración con sus sistemas de gestión de clientes",
    PL: "Chatboty z możliwością integracji z systemami zarządzania klientami (CRM)",
  },
  sservicesAutoCommunicationFeature2: {
    RU: "Эффективная обработка запросов и сбор данных о клиентах",
    EN: "Efficient processing of enquiries and collection of customer data",
    ES: "Tramitación eficaz de las consultas y recopilación de datos de los clientes",
    PL: "Efektywna obsługa zapytań i gromadzenie danych o klientach",
  },

  servicesAutoCommunicationName3: {
    RU: "Продвинутый чат-бот с аналитикой",
    EN: "Advanced chatbot with analytics",
    ES: "Chatbot avanzado con funciones analíticas",
    PL: "Zaawansowany chatbot z analizą danych",
  },
  servicesAutoCommunicationDescription3: {
    RU: "Чат-боты, которые собирают и анализируют данные о взаимодействии с клиентами",
    EN: "Chatbots that collect and analyse customer interaction data",
    ES: "Chatbots que recopilan y analizan datos sobre la interacción con el cliente",
    PL: "Chatboty, które zbierają i analizują dane o interakcjach z klientami",
  },
  sservicesAutoCommunicationFeature3: {
    RU: "Глубокое понимание потребностей клиентов и оптимизация общения",
    EN: "Deep understanding of customer needs and optimising communication",
    ES: "Profundo conocimiento de las necesidades del cliente y optimización de la comunicación",
    PL: "Dogłębne zrozumienie potrzeb klientów i optymalizacja komunikacji",
  },

  servicesAutoCommunicationName4: {
    RU: "Чат-бот под ключ с кастомной интеграцией",
    EN: "Turnkey chatbot with custom integration",
    ES: "Chatbot llave en mano con integración personalizada",
    PL: "Chatbot na zamówienie z niestandardową integracją",
  },
  servicesAutoCommunicationDescription4: {
    RU: "Индивидуальные решения, разработанные специально для вашего бизнеса",
    EN: "Customised solutions designed specifically for your business",
    ES: "Soluciones personalizadas diseñadas específicamente para su empresa",
    PL: "Indywidualne rozwiązania zaprojektowane specjalnie dla Twojego biznesu",
  },
  sservicesAutoCommunicationFeature4: {
    RU: "Полное соответствие уникальным требованиям и процессам вашего бизнеса",
    EN: "Fully aligned with the unique requirements and processes of your business",
    ES: "Totalmente adaptado a los requisitos y procesos exclusivos de su empresa",
    PL: "Pełne dostosowanie do unikalnych wymagań i procesów Twojej firmy",
  },

  // ServicesContactBlock

  serviceContactBlockTitle: {
    RU: (
      <>
        Готовы к <span className="primaryTxt">сотрудничеству?</span>
      </>
    ),
    EN: (
      <>
        Are you ready to <span className="primaryTxt">cooperate?</span>
      </>
    ),
    ES: (
      <>
        ¿Estás dispuesto a <span className="primaryTxt">cooperar</span>
      </>
    ),
    PL: (
      <>
        Czy jesteś gotowy do <span className="primaryTxt">współpracy?</span>
      </>
    ),
  },

  serviceContactBlockDescription: {
    RU: "Свяжитесь с нами для получения более подробной информации о наших услугах и начните свой путь к успеху с нами!",
    EN: "Contact us for more information about our services and start your journey to success with us!",
    ES: "Póngase en contacto con nosotros para obtener más información sobre nuestros servicios y comience su viaje hacia el éxito con nosotros",
    PL: "Skontaktuj się z nami, aby uzyskać więcej informacji o naszych usługach i rozpocznij swoją drogę do sukcesu razem z nami!",
  },

  // Our Projects page

  // ProjectsHeader

  projectsHeaderTitleLg: {
    RU: (
      <>
        Наши <span className="primaryTxt">проекты</span>
      </>
    ),
    EN: (
      <>
        Our <span className="primaryTxt">projects</span>
      </>
    ),
    ES: (
      <>
        Nuestros <span className="primaryTxt">proyectos</span>
      </>
    ),
    PL: (
      <>
        Nasze <span className="primaryTxt">projekty</span>
      </>
    ),
  },
  projectsHeaderSubtitleLg: {
    RU: "Мы гордимся результатами, которых добились для наших клиентов. Каждый проект — это уникальная история успеха, основанная на тесном сотрудничестве и внимании к деталям",
    EN: "We are proud of the results we have achieved for our clients. Each project is a unique success story based on close co-operation and attention to detail",
    ES: "Estamos orgullosos de los resultados que hemos conseguido para nuestros clientes. Cada proyecto es una historia de éxito única basada en una estrecha colaboración y en la atención al detalle",
    PL: "Jesteśmy dumni z wyników, jakie osiągnęliśmy dla naszych klientów. Każdy projekt to unikalna historia sukcesu oparta na bliskiej współpracy i dbałości o szczegóły",
  },

  // ProjectsCompletedCases

  projectsCompletedCasesTitle: {
    RU: (
      <>
        Выполненные <span className="primaryTxt">кейсы</span>
      </>
    ),
    EN: (
      <>
        Completed <span className="primaryTxt">projects</span>
      </>
    ),
    ES: (
      <>
        Proyectos <span className="primaryTxt">finalizados</span>
      </>
    ),
    PL: (
      <>
        Zrealizowane <span className="primaryTxt">projekty</span>
      </>
    ),
  },

  // ProjectsCompletedCases/ CompletedCases

  completedCasesSubtitle: {
    RU: "Разработка сайта для компании",
    EN: "Website development for the company",
    ES: "Desarrollo del sitio web de la empresa",
    PL: "Opracowanie strony internetowej dla firmy",
  },

  projectsCompletedCasesResultTitle: {
    RU: "Результаты",
    EN: "Results",
    ES: "Resultados",
    PL: "Wyniki",
  },

  // Rise Partner

  risePartnerDescription: {
    RU: "Основная задача заключалась в создании современного корпоративного сайта для Rise Partner — компании, занимающейся арендой автомобилей и поддержкой водителей Uber, Bolt и FreeNow. Цель сайта — повысить доверие к компании, представить ее услуги и помочь водителям легко находить информацию и связываться для консультаций",
    EN: "The main task was to create a modern corporate website for Rise Partner - a car hire and driver support company for Uber, Bolt and FreeNow. The aim of the site was to increase trust in the company, showcase its services and help drivers easily find information and get in touch for consultations",
    ES: "La tarea principal era crear un sitio web corporativo moderno para Rise Partner, una empresa de alquiler de coches y asistencia a conductores de Uber, Bolt y FreeNow. El objetivo del sitio era aumentar la confianza en la empresa, mostrar sus servicios y ayudar a los conductores a encontrar fácilmente información y ponerse en contacto para realizar consultas",
    PL: "Głównym zadaniem było stworzenie nowoczesnej strony korporacyjnej dla Rise Partner – firmy zajmującej się wynajmem samochodów i wsparciem kierowców Uber, Bolt i FreeNow. Celem strony było zwiększenie zaufania do firmy, zaprezentowanie jej usług oraz pomoc kierowcom w łatwym znajdowaniu informacji i nawiązywaniu kontaktu w celu uzyskania konsultacji",
  },

  risePartnerStep1Name: {
    RU: "Изучение потребностей клиента и целевой аудитории",
    EN: "Study of the client's needs and target audience",
    ES: "Estudio de las necesidades del cliente y del público destinatario",
    PL: "Badanie potrzeb klienta i grupy docelowej ",
  },

  risePartnerStep1Description: {
    RU: "Анализ целевой аудитории, а также особенностей такого вида деятельности помог определить ключевые элементы, необходимые для успешного представления компании в Интернете",
    EN: "Analysing the target audience as well as the peculiarities of this type of activity helped to identify the key elements required to successfully represent a company on the Interne",
    ES: "El análisis del público destinatario y de las peculiaridades de este tipo de actividad permitió determinar los elementos clave para representar con éxito a una empresa en Internet",
    PL: "Analiza grupy docelowej oraz specyfiki tego rodzaju działalności pomogła określić kluczowe elementy niezbędne do skutecznego przedstawienia firmy w Internecie",
  },

  risePartnerStep2Name: {
    RU: "Разработка структуры и UX-дизайна",
    EN: "Developing the structure and UX design",
    ES: "Desarrollo de la estructura y diseño UX",
    PL: "Opracowanie struktury i projektu UX",
  },

  risePartnerStep2Description: {
    RU: "Создана логичная и интуитивно понятная структура сайта, включающая основные страницы — «О нас», «Услуги», «Контакты» — для удобства пользователей",
    EN: "We have created a logical and intuitive structure of the site, including the main pages - ‘About Us’, ‘Services’, ‘Contacts’ - for the convenience of users",
    ES: "Hemos creado una estructura lógica e intuitiva del sitio, que incluye las páginas principales - «Quiénes somos», «Servicios», «Contactos»- para mayor comodidad de los usuarios",
    PL: "Stworzono logiczną i intuicyjną strukturę strony, zawierającą główne podstrony – „O nas”, „Usługi”, „Kontakt” – dla wygody użytkowników",
  },

  risePartnerStep3Name: {
    RU: "Создание визуального дизайна",
    EN: "Creation of visual design",
    ES: "Создание визуального дизайна",
    PL: "Tworzenie projektu wizualnego",
  },

  risePartnerStep3Description: {
    RU: "Подобран строгий и профессиональный дизайн с акцентом на надежность и доверие. Цветовая палитра и типографика были выбраны так, чтобы передавать корпоративный стиль и создать положительное первое впечатление",
    EN: "A strict and professional design with an emphasis on reliability and trust was chosen. The colour palette and typography were chosen to convey the corporate style and create a positive first impression",
    ES: "Se optó por un diseño estricto y profesional, con énfasis en la fiabilidad y la confianza. La paleta de colores y la tipografía se eligieron para transmitir el estilo corporativo y crear una primera impresión positiva",
    PL: "Wybrano surowy i profesjonalny projekt z naciskiem na niezawodność i zaufanie. Paleta kolorów i typografia zostały dobrane tak, aby odzwierciedlać styl korporacyjny i wywołać pozytywne pierwsze wrażenie",
  },

  risePartnerStep4Name: {
    RU: "Интеграция формы для связи",
    EN: "Integration of a contact form",
    ES: "Integración de un formulario de contacto",
    PL: "Integracja formularza kontaktowego",
  },

  risePartnerStep4Description: {
    RU: "Добавили удобные способы связи, включая контактные формы и возможность быстрой отправки запроса на консультацию",
    EN: "Added convenient ways to get in touch, including contact forms and the ability to quickly submit a consultation request",
    ES: "Se han añadido formas cómodas de ponerse en contacto, como formularios de contacto y la posibilidad de enviar rápidamente una solicitud de consulta",
    PL: "Dodano wygodne metody kontaktu, w tym formularze kontaktowe oraz możliwość szybkiego wysyłania zapytań o konsultacje",
  },

  risePartnerStep5Name: {
    RU: "Информационный блок об услугах компании",
    EN: "Information block about the company's services",
    ES: "Bloque informativo sobre los servicios de la empresa",
    PL: "Blok informacyjny o usługach firmy",
  },

  risePartnerStep5Description: {
    RU: "Подробно расписаны услуги компании для привлечения водителей, заинтересованных начать работать в такси",
    EN: "The company's services are detailed to attract drivers interested in starting out in the taxi industry",
    ES: "Los servicios de la empresa se detallan para atraer a conductores interesados en iniciarse en el sector del taxi",
    PL: "Szczegółowo opisano usługi firmy w celu przyciągnięcia kierowców zainteresowanych rozpoczęciem pracy w branży taksówkowej",
  },

  risePartnerStep6Name: {
    RU: "Адаптация для мобильных устройств",
    EN: "Adaptation for mobile devices",
    ES: "Adaptación a dispositivos móviles",
    PL: "Dostosowanie do urządzeń mobilnych",
  },

  risePartnerStep6Description: {
    RU: "Обеспечили корректное отображение сайта на всех устройствах, чтобы пользователи могли удобно работать с ним как с компьютеров, так и со смартфонов",
    EN: "Ensured the correct display of the site on all devices, so that users can conveniently work with it from both computers and smartphones",
    ES: "Garantizar la correcta visualización del sitio en todos los dispositivos, para que los usuarios puedan trabajar cómodamente con él tanto desde ordenadores como desde smartphones",
    PL: "Zapewniono prawidłowe wyświetlanie strony na wszystkich urządzeniach, aby użytkownicy mogli wygodnie korzystać z niej zarówno na komputerach, jak i smartfonach",
  },

  risePartnerResultText: {
    RU: [
      "Сайт позволил улучшить взаимодействие с клиентами и упростить процесс обращения.",
      "Количество заявок и запросов на консультацию увеличилось на 25%, что говорит о повышении интереса к услугам компании.",
      "Положительные отзывы клиентов отмечают удобство навигации и профессиональный вид сайта, что способствует созданию доверия к бренду Rise Partner.",
    ],
    EN: [
      "The website has improved interaction with clients and simplified the process of contacting them.",
      "The number of requests and enquiries for consultation has increased by 25%, indicating an increase in interest in the company's services.",
      "Positive feedback from clients notes the ease of navigation and professional appearance of the website, which helps to build trust in the Rise Partner brand.",
    ],
    ES: [
      "El sitio web ha mejorado la interacción con los clientes y ha simplificado el proceso de contacto.",
      "El número de solicitudes y consultas de asesoramiento ha aumentado un 25%, lo que indica un mayor interés por los servicios de la empresa.",
      "Los comentarios positivos de los clientes destacan la facilidad de navegación y el aspecto profesional del sitio web, que contribuye a generar confianza en la marca Rise Partner.",
    ],
    PL: [
      "Strona pozwoliła poprawić interakcję z klientami i uprościć proces kontaktu.",
      "Liczba zgłoszeń i zapytań o konsultacje wzrosła o 25%, co świadczy o większym zainteresowaniu usługami firmy.",
      "Pozytywne opinie klientów podkreślają wygodę nawigacji i profesjonalny wygląd strony, co przyczynia się do budowania zaufania do marki Rise Partner.",
    ],
  },

  // Reality Bulgaria

  realityBulgariaDescription: {
    RU: "Основной целью проекта было создание сайта для компании Reality Bulgaria, которая занимается продажей и арендой недвижимости в Болгарии для клиентов из Польши. Ключевые задачи включали представление объектов недвижимости, удобную навигацию для поиска недвижимости по параметрам, а также предоставление информации о процессах покупки и аренды",
    EN: "The main goal of the project was to create a website for Reality Bulgaria, a company that sells and rents properties in Bulgaria for clients from Poland. Key tasks included presentation of properties, easy navigation for property search by parameters, as well as providing information about the buying and renting processes",
    ES: "El objetivo principal del proyecto era crear un sitio web para Reality Bulgaria, una empresa que vende y alquila propiedades en Bulgaria para clientes de Polonia. Las tareas clave incluían la presentación de las propiedades, una navegación fácil para la búsqueda de propiedades por parámetros, así como proporcionar información sobre los procesos de compra y alquiler",
    PL: "Głównym celem projektu było stworzenie strony internetowej dla firmy Reality Bulgaria, zajmującej się sprzedażą i wynajmem nieruchomości w Bułgarii dla klientów z Polski. Kluczowe zadania obejmowały prezentację nieruchomości, łatwą nawigację w celu wyszukiwania nieruchomości według parametrów oraz dostarczenie informacji o procesach zakupu i wynajmu",
  },

  realityBulgariaStep1Name: {
    RU: "Изучение потребностей клиента и целевой аудитории",
    EN: "Study of customer needs and target audience",
    ES: "Estudio de las necesidades de los clientes y del público destinatario",
    PL: "Badanie potrzeb klienta i grupy docelowej",
  },

  realityBulgariaStep1Description: {
    RU: "Определили предпочтения польских клиентов, заинтересованных в недвижимости в Болгарии, и определили ключевые факторы, которые помогут им быстрее найти и выбрать нужные объекты",
    EN: "Determined the preferences of Polish clients interested in property in Bulgaria and identified key factors that would help them find and select the right properties faster",
    ES: "Determinó las preferencias de los clientes polacos interesados en propiedades en Bulgaria e identificó los factores clave que les ayudarían a encontrar y seleccionar las propiedades adecuadas con mayor rapidez",
    PL: "Określono preferencje polskich klientów zainteresowanych nieruchomościami w Bułgarii oraz zidentyfikowano kluczowe czynniki, które pomogą im szybciej znaleźć i wybrać odpowiednie nieruchomości",
  },

  realityBulgariaStep2Name: {
    RU: "Разработка структуры и UX-дизайна",
    EN: "Development of structure and UX-design",
    ES: "Desarrollo de la estructura y diseño UX",
    PL: "Opracowanie struktury i projektu UX",
  },

  realityBulgariaStep2Description: {
    RU: "Сайт был структурирован таким образом, чтобы пользователи могли легко искать и просматривать объекты по разным критериям (тип недвижимости, цена, расположение и т.д.)",
    EN: "The site was structured in such a way that users could easily search and browse properties by different criteria (property type, price, location, etc.)",
    ES: "El sitio se estructuró de tal forma que los usuarios pudieran buscar y examinar fácilmente las propiedades según distintos criterios (tipo de propiedad, precio, ubicación, etc.)",
    PL: "Strona została zaprojektowana w taki sposób, aby użytkownicy mogli łatwo wyszukiwać i przeglądać nieruchomości według różnych kryteriów (rodzaj nieruchomości, cena, lokalizacja itp.)",
  },

  realityBulgariaStep3Name: {
    RU: "Создание визуального дизайна",
    EN: "Creation of visual design",
    ES: "Creación del diseño visual",
    PL: "Tworzenie projektu wizualnego",
  },

  realityBulgariaStep3Description: {
    RU: "В дизайне был использован чистый, современный стиль с акцентом на фотографии недвижимости, чтобы создать визуально привлекательную и профессиональную платформу",
    EN: "The design used a clean, modern style with an emphasis on property photography to create a visually appealing and professional platform",
    ES: "El diseño utiliza un estilo limpio y moderno, con énfasis en la fotografía de propiedades, para crear una plataforma visualmente atractiva y profesional",
    PL: "Projekt wykorzystuje czysty, nowoczesny styl z naciskiem na fotografię nieruchomości, aby stworzyć wizualnie atrakcyjną i profesjonalną platformę",
  },

  realityBulgariaStep4Name: {
    RU: "Интеграция базы объектов и фильтров поиска",
    EN: "Integration of object database and search filters",
    ES: "Integración de la base de datos de objetos y filtros de búsqueda",
    PL: "Integracja bazy nieruchomości i filtrów wyszukiwania",
  },

  realityBulgariaStep4Description: {
    RU: "Добавлены функциональные фильтры, позволяющие пользователям легко находить объекты по заданным параметрам, что ускоряет процесс поиска",
    EN: "Functional filters have been added to allow users to easily find objects by specified parameters, which speeds up the search process",
    ES: "Se han añadido filtros funcionales para permitir a los usuarios encontrar fácilmente objetos por parámetros especificados, lo que acelera el proceso de búsqueda",
    PL: "Dodano funkcjonalne filtry, które pozwalają użytkownikom łatwo znajdować nieruchomości według określonych parametrów, co przyspiesza proces wyszukiwania",
  },

  realityBulgariaStep5Name: {
    RU: "Информационный раздел",
    EN: "Information section",
    ES: "Sección de información",
    PL: "Sekcja informacyjna",
  },

  realityBulgariaStep5Description: {
    RU: "Создали разделы с полезной информацией о процессах покупки, аренды, а также правовых аспектах недвижимости в Болгарии для польских клиентов",
    EN: "Created sections with useful information on the processes of buying, renting and legal aspects of property in Bulgaria for Polish clients",
    ES: "Secciones creadas con información útil sobre los procesos de compra, alquiler y aspectos legales de la propiedad en Bulgaria para clientes polacos",
    PL: "Utworzono sekcje z przydatnymi informacjami o procesach zakupu, wynajmu oraz aspektach prawnych nieruchomości w Bułgarii dla polskich klientów",
  },

  realityBulgariaStep6Name: {
    RU: "Оптимизация под мобильные устройства",
    EN: "Optimisation for mobile devices",
    ES: "Optimización para dispositivos móviles",
    PL: "Optymalizacja dla urządzeń mobilnych",
  },

  realityBulgariaStep6Description: {
    RU: "Сайт адаптирован для корректного отображения на всех устройствах, что делает его удобным для пользователей, независимо от того, заходят они с телефона, планшета или компьютера",
    EN: "The site is adapted to display correctly on all devices, making it user-friendly whether they are accessing it from a phone, tablet or compute",
    ES: "El sitio está adaptado para mostrarse correctamente en todos los dispositivos, por lo que es fácil de usar tanto si se accede desde un teléfono, una tableta o un ordenador",
    PL: "Strona została zoptymalizowana do prawidłowego wyświetlania na wszystkich urządzeniach, co czyni ją wygodną dla użytkowników, niezależnie od tego, czy korzystają z telefonu, tabletu czy komputera",
  },

  realityBulgariaResultText: {
    RU: [
      "Сайт обеспечил клиентам из Польши удобный доступ к базе недвижимости в Болгарии и упростил процесс поиска.",
      "Функциональные фильтры позволили сократить время на поиск нужных объектов, что повысило удовлетворённость пользователей.",
      "Количество заявок на покупку и аренду недвижимости возросло на 11% после запуска сайта.",
      "Положительные отзывы пользователей за простоту поиска и навигации, а также за информативность разделов о процессах покупки и аренды.",
    ],
    EN: [
      "The site provided Polish clients with convenient access to the property database in Bulgaria and simplified the search process.",
      "Functional filters reduced the time spent on searching for the right property, which increased user satisfaction.",
      " The number of applications for buying and renting property increased by 11% after the launch of the site.",
      "Positive feedback from users for the ease of search and navigation, as well as for the informative sections on the buying and renting processes.",
    ],
    ES: [
      "El sitio proporcionó a los clientes polacos un cómodo acceso a la base de datos inmobiliaria de Bulgaria y simplificó el proceso de búsqueda.",
      "Los filtros funcionales redujeron el tiempo dedicado a buscar la propiedad adecuada, lo que aumentó la satisfacción de los usuarios.",
      "El número de solicitudes de compra y alquiler de inmuebles aumentó un 11% tras la puesta en marcha del sitio.",
      "Comentarios positivos de los usuarios por la facilidad de búsqueda y navegación, así como por las secciones informativas sobre los procesos de compra y alquiler.",
    ],
    PL: [
      "Strona zapewniła klientom z Polski wygodny dostęp do bazy nieruchomości w Bułgarii i uprościła proces wyszukiwania.",
      "Funkcjonalne filtry pozwoliły skrócić czas wyszukiwania odpowiednich nieruchomości, co zwiększyło satysfakcję użytkowników.",
      "Liczba zgłoszeń dotyczących zakupu i wynajmu nieruchomości wzrosła o 11% po uruchomieniu strony.",
      "Pozytywne opinie użytkowników dotyczą łatwości wyszukiwania i nawigacji, a także informacyjności sekcji o procesach zakupu i wynajmu.",
    ],
  },

  // L’EMIGRANT RealityBulgariaDescription

  lemignrantDescription: {
    RU: "Основной задачей было создание современного и информативного сайта для компании L’EMIGRANT, которая предоставляет юридические и миграционные услуги. Цели включали повышение доверия к компании, обеспечение легкого доступа к информации об услугах, а также создание удобной системы связи для потенциальных клиентов",
    EN: "The main task was to create a modern and informative website for Le Migrant, a company that provides legal and immigration services. The objectives included increasing trust in the company, providing easy access to information about services, and creating a convenient communication system for potential clients",
    ES: "La tarea principal era crear un sitio web moderno e informativo para Le Migrant, una empresa que presta servicios jurídicos y de inmigración. Los objetivos eran aumentar la confianza en la empresa, facilitar el acceso a la información sobre los servicios y crear un sistema de comunicación cómodo para los clientes potenciales",
    PL: "Głównym zadaniem było stworzenie nowoczesnej i informacyjnej strony internetowej dla firmy Le Migrant, która świadczy usługi prawne i imigracyjne. Cele obejmowały zwiększenie zaufania do firmy, zapewnienie łatwego dostępu do informacji o usługach oraz stworzenie wygodnego systemu komunikacji dla potencjalnych klientów",
  },

  lemignrantStep1Name: {
    RU: "Анализ аудитории и конкурентной среды",
    EN: "Analysing the audience and competitive environment",
    ES: "Analizar la audiencia y el entorno competitivo",
    PL: "Analiza odbiorców i środowiska konkurencyjnego ",
  },
  lemignrantStep1Description: {
    RU: "Изучили потребности клиентов, нуждающихся в юридической помощи и услугах по миграции, и определили, какие ключевые элементы должны быть на сайте для привлечения и удержания клиентов",
    EN: "Researched the needs of clients requiring legal aid and migration services and identified what key elements should be on the website to attract and retain clients",
    ES: "Investigar las necesidades de los clientes que requieren asistencia jurídica y servicios de migración y determinar qué elementos clave deben figurar en el sitio web para atraer y retener a los clientes",
    PL: "Изучили потребности клиентов, нуждающихся в юридической помощи и услугах по миграции, и определили, какие ключевые элементы должны быть на сайте для привлечения и удержания клиентов",
  },
  lemignrantStep2Name: {
    RU: "Разработка структуры и UX-дизайна",
    EN: "Development of structure and UX-design",
    ES: "Desarrollo de la estructura y diseño UX",
    PL: "Opracowanie struktury i projektu UX",
  },
  lemignrantStep2Description: {
    RU: "Создали логичную и удобную навигацию по сайту, обеспечив лёгкий доступ к разделам об услугах, часто задаваемым вопросам и контактной информации",
    EN: "Created a logical and user-friendly site navigation, providing easy access to sections on services, FAQs and contact information",
    ES: "Se ha creado una navegación lógica y fácil de usar que facilita el acceso a las secciones de servicios, preguntas frecuentes e información de contacto",
    PL: "Opracowano logiczną i wygodną nawigację po stronie, zapewniając łatwy dostęp do sekcji o usługach, najczęściej zadawanych pytaniach oraz danych kontaktowych",
  },
  lemignrantStep3Name: {
    RU: "Создание визуального дизайна",
    EN: "Creation of visual design",
    ES: "Creación del diseño visual",
    PL: "Tworzenie projektu wizualnego",
  },
  lemignrantStep3Description: {
    RU: "Подобрали сдержанный и профессиональный стиль дизайна, который соответствует теме юридических услуг, добавив элементы, подчеркивающие надежность и компетентность компании",
    EN: "We chose a discreet and professional design style that matches the theme of legal services, adding elements that emphasise the reliability and competence of the company",
    ES: "Elegimos un estilo de diseño discreto y profesional que encaja con el tema de los servicios jurídicos, añadiendo elementos que destacan la fiabilidad y competencia de la empresa",
    PL: "Zastosowano stonowany i profesjonalny styl projektowania, który odpowiada tematyce usług prawnych, dodając elementy podkreślające wiarygodność i kompetencje firmy",
  },
  lemignrantStep4Name: {
    RU: "Интеграция форм для связи",
    EN: "Integration of contact forms",
    ES: "Integración de formularios de contacto",
    PL: "Integracja formularzy kontaktowych",
  },
  lemignrantStep4Description: {
    RU: "Реализовали формы для быстрой связи и консультаций, чтобы пользователи могли оперативно получить необходимую помощь и задать интересующие вопросы",
    EN: "mplemented forms for quick communication and consultations so that users can quickly get the necessary help and ask questions",
    ES: "Implementación de formularios de comunicación rápida y consultas para que los usuarios puedan obtener rápidamente la ayuda necesaria y hacer preguntas",
    PL: "Wdrożono formularze do szybkiego kontaktu i konsultacji, aby użytkownicy mogli szybko uzyskać potrzebną pomoc i zadać pytania",
  },
  lemignrantStep5Name: {
    RU: "Оптимизация и адаптивность",
    EN: "Optimisation and adaptability",
    ES: "Optimización y adaptabilidad",
    PL: "Optymalizacja i responsywność",
  },
  lemignrantStep5Description: {
    RU: "Проработали дизайн для корректного отображения на мобильных устройствах и планшетах, обеспечив удобный доступ к сайту с любых устройств",
    EN: "We worked out the design for correct display on mobile devices and tablets, providing convenient access to the site from any device",
    ES: "Trabajamos el diseño para una correcta visualización en dispositivos móviles y tabletas, proporcionando un cómodo acceso al sitio desde cualquier dispositivo",
    PL: "Dopracowano projekt, aby zapewnić prawidłowe wyświetlanie na urządzeniach mobilnych i tabletach, umożliwiając wygodny dostęp do strony z dowolnych urządzeń",
  },

  lemignrantResultText: {
    RU: [
      "Сайт позволил упростить процесс поиска информации о юридических и миграционных услугах, предоставляемых компанией.",
      "Количество запросов через формы связи увеличилось на 35%, что помогло привлечь новых клиентов.",
      "Сайт получил положительные отзывы от пользователей за его удобную навигацию и профессиональный дизайн, отражающий специфику юридической деятельности.",
      "Улучшилось взаимодействие с клиентами, что положительно сказалось на имидже компании.",
    ],
    EN: [
      "The website simplified the process of searching for information on legal and migration services provided by the company.",
      "The number of enquiries through contact forms increased by 35%, which helped to attract new clients.",
      "The website received positive feedback from users for its easy navigation and professional design reflecting the specifics of legal activities.",
      "Client interaction improved, which had a positive impact on the company's image.",
    ],
    ES: [
      "El sitio web simplificó el proceso de búsqueda de información sobre los servicios jurídicos y de migración prestados por la empresa.",
      "El número de consultas a través de formularios de contacto aumentó un 35%, lo que contribuyó a atraer nuevos clientes.",
      "El sitio web recibió comentarios positivos de los usuarios por su facilidad de navegación y su diseño profesional que refleja las especificidades de las actividades jurídicas.",
      "La interacción con los clientes mejoró, lo que repercutió positivamente en la imagen de la empresa.",
    ],
    PL: [
      "Strona umożliwiła uproszczenie procesu wyszukiwania informacji o usługach prawnych i imigracyjnych świadczonych przez firmę.",
      "Liczba zapytań za pośrednictwem formularzy kontaktowych wzrosła o 35%, co pomogło przyciągnąć nowych klientów.",
      "Strona otrzymała pozytywne opinie od użytkowników za wygodną nawigację i profesjonalny design odzwierciedlający specyfikę działalności prawnej.",
      "Poprawiła się współpraca z klientami, co pozytywnie wpłynęło na wizerunek firmy.",
    ],
  },

  // DocPol

  docPolDescription: {
    RU: "Основная задача состояла в разработке одностраничного сайта для компании DocPol, занимающейся предоставлением услуг страхования и помощи в решении банковских вопросов. Основные цели включали повышение доступности компании через поисковые системы по целевым запросам и обеспечение лёгкого доступа к контактной информации для потенциальных клиентов",
    EN: "The main task was to develop a one-page website for DocPol, a company engaged in providing insurance services and assistance with banking issues. The main objectives included increasing the company's accessibility through search engines for targeted queries and providing easy access to contact information for potential clients",
    ES: "La tarea principal consistía en desarrollar un sitio web de una página para DocPol, una empresa dedicada a prestar servicios de seguros y asistencia en asuntos bancarios. Los principales objetivos eran aumentar la accesibilidad de la empresa a través de motores de búsqueda para consultas específicas y facilitar el acceso a la información de contacto a clientes potenciales",
    PL: "Głównym zadaniem było opracowanie jednostronicowej strony internetowej dla firmy DocPol, zajmującej się świadczeniem usług ubezpieczeniowych i pomocy w sprawach bankowych. Główne cele obejmowały zwiększenie dostępności firmy w wyszukiwarkach na podstawie docelowych zapytań oraz zapewnienie łatwego dostępu do danych kontaktowych dla potencjalnych klientów",
  },

  docPolStep1Name: {
    RU: "Исследование и анализ",
    EN: "Research and analysis",
    ES: "Investigación y análisis",
    PL: "Badanie i analiza",
  },

  docPolStep1Description: {
    RU: "Изучили запросы целевой аудитории, чтобы создать сайт, отвечающий специфическим потребностям клиентов, ищущих консультации по страхованию и банковским услугам",
    EN: "Researched the needs of the target audience to create a website that met the specific needs of customers looking for insurance and banking advice",
    ES: "Investigar las necesidades del público objetivo para crear un sitio web que respondiera a las necesidades específicas de los clientes que buscan asesoramiento sobre seguros y banca",
    PL: "Przeanalizowano potrzeby docelowej grupy odbiorców, aby stworzyć stronę odpowiadającą specyficznym wymaganiom klientów poszukujących porad w zakresie ubezpieczeń i usług bankowych",
  },

  docPolStep2Name: {
    RU: "Разработка структуры и UX-дизайна",
    EN: "Development of structure and UX-design",
    ES: "Desarrollo de la estructura y diseño UX",
    PL: "Opracowanie struktury i projektu UX",
  },

  docPolStep2Description: {
    RU: "Разработали простую и удобную структуру, позволяющую пользователям быстро находить необходимые услуги и контактную информацию для оперативной связи с консультантами",
    EN: "Developed a simple and user-friendly structure that allows users to quickly find the services they need and contact information for prompt communication with counsellors",
    ES: "Se ha desarrollado una estructura sencilla y fácil de usar que permite a los usuarios encontrar rápidamente los servicios que necesitan y la información de contacto para comunicarse rápidamente con los asesores",
    PL: "Opracowano prostą i przyjazną dla użytkownika strukturę, umożliwiającą szybkie znajdowanie potrzebnych usług oraz danych kontaktowych do szybkiej komunikacji z doradcami",
  },

  docPolStep3Name: {
    RU: "Создание визуального дизайна",
    EN: "Creation of visual design",
    ES: "Creación del diseño visual",
    PL: "Tworzenie projektu wizualnego",
  },

  docPolStep3Description: {
    RU: "Подобрали сдержанную и профессиональную цветовую палитру и элементы дизайна, которые подчёркивают надёжность и компетентность компании в области страхования и банковских услуг",
    EN: "Selected a discreet and professional colour palette and design elements that highlight the company's reliability and expertise in insurance and banking services",
    ES: "Seleccionó una paleta de colores discreta y profesional y elementos de diseño que resaltan la fiabilidad y experiencia de la empresa en servicios bancarios y de seguros",
    PL: "Wybrano stonowaną i profesjonalną paletę kolorów oraz elementy graficzne, które podkreślają wiarygodność i kompetencje firmy w zakresie ubezpieczeń i usług bankowych",
  },

  docPolStep4Name: {
    RU: "Тестирование и оптимизация",
    EN: "Testing and optimisation",
    ES: "Pruebas y optimización",
    PL: "Testowanie i optymalizacja",
  },

  docPolStep4Description: {
    RU: "Провели тестирование для обеспечения корректного отображения и быстрой загрузки страницы на всех популярных устройствах и браузерах, что критически важно для удержания пользователей",
    EN: "Conducted testing to ensure the page displays correctly and loads quickly on all popular devices and browsers, which is critical for user retention",
    ES: "Realización de pruebas para garantizar que la página se muestra correctamente y se carga con rapidez en todos los dispositivos y navegadores habituales, lo que es fundamental para la fidelización de los usuarios",
    PL: "Przeprowadzono testy w celu zapewnienia prawidłowego wyświetlania oraz szybkiego ładowania strony na wszystkich popularnych urządzeniach i przeglądarkach, co jest kluczowe dla utrzymania użytkowników",
  },

  docPolResultText: {
    RU: [
      "Сайт достиг высокой видимости в поисковых системах по целевым ключевым словам, что значительно повысило его доступность для потенциальных клиентов.",
      "В первые месяцы после запуска количество посетителей увеличилось на 38%, что указывает на рост интереса к услугам DocPol.",
      "Положительные отзывы клиентов подтверждают удобство перехода на контакт с менеджерами, что улучшило общее взаимодействие с пользователями.",
    ],
    EN: [
      "The site achieved high visibility in search engines for targeted keywords, which significantly increased its accessibility to potential clients.",
      "In the first months after launch, the number of visitors increased by 38%, indicating a growing interest in DocPol's services.",
      "Positive customer feedback confirms the ease of navigating to contact managers, which improved overall user interaction.",
    ],
    ES: [
      "El sitio consiguió una gran visibilidad en los motores de búsqueda para las palabras clave seleccionadas, lo que aumentó significativamente su accesibilidad para los clientes potenciales.",
      "En los primeros meses tras el lanzamiento, el número de visitantes aumentó un 38%, lo que indica un creciente interés por los servicios de DocPol. ",
      "Los comentarios positivos de los clientes confirman la facilidad de navegación hasta los gestores de contactos, lo que mejoró la interacción general con el usuario.",
    ],
    PL: [
      "Strona osiągnęła wysoką widoczność w wyszukiwarkach na podstawie docelowych słów kluczowych, co znacząco zwiększyło jej dostępność dla potencjalnych klientów.",
      "W pierwszych miesiącach po uruchomieniu liczba odwiedzających wzrosła o 38%, co wskazuje na rosnące zainteresowanie usługami DocPol",
      "Pozytywne opinie klientów potwierdzają łatwość nawiązywania kontaktu z menedżerami, co poprawiło ogólną interakcję z użytkownikami.",
    ],
  },

  // ProjectsReviews

  projectsReviewsTitle: {
    RU: (
      <>
        Отзывы <span className="primaryTxt">клиентов</span>
      </>
    ),
    EN: (
      <>
        Customer <span className="primaryTxt">feedback</span>
      </>
    ),
    ES: (
      <>
        {" "}
        Comentarios de los <span className="primaryTxt">clientes</span>
      </>
    ),
    PL: (
      <>
        Opinie <span className="primaryTxt">klientów</span>
      </>
    ),
  },
  projectsReviewsReviewer1: {
    RU: "Работа с вашей командой превзошла наши ожидания! Одностраничный сайт оказался простым и удобным в использовании, а клиенты теперь легко находят нас через поисковые системы. Благодаря вашему профессионализму и вниманию к деталям, мы заметили значительный рост обращений. Спасибо за отличный результат!",
    EN: "Working with your team has exceeded our expectations! The one-page website was simple and easy to use, and clients now find us easily through search engines. Thanks to your professionalism and attention to detail, we have seen a significant increase in conversions. Thank you for the great result!",
    ES: "Trabajar con su equipo ha superado nuestras expectativas. El sitio web de una sola página era sencillo y fácil de usar, y ahora los clientes nos encuentran fácilmente a través de los motores de búsqueda. Gracias a vuestra profesionalidad y atención al detalle, hemos visto un aumento significativo de las conversiones. Gracias por el gran resultado.",
    PL: "Współpraca z waszym zespołem przerosła nasze oczekiwania! Jednostronicowa strona okazała się prosta i wygodna w obsłudze, a klienci teraz łatwo nas znajdują przez wyszukiwarki. Dzięki waszemu profesjonalizmowi i dbałości o szczegóły zauważyliśmy znaczny wzrost zapytań. Dziękujemy za świetny rezultat!",
  },
  projectsReviewsReviewer2: {
    RU: "Сайт оказался именно тем, что нужно для представления наших юридических и миграционных услуг. Удобная навигация, стильный дизайн и возможность быстро найти нужную информацию — всё это делает сайт эффективным инструментом для наших клиентов. Мы получили массу положительных отзывов от пользователей, а количество заявок выросло сразу после запуска. Благодарим за проделанную работу!",
    EN: "The website turned out to be exactly what we needed to present our legal and migration services. Easy navigation, stylish design and the ability to quickly find the information they need - all this makes the site an effective tool for our clients. We received a lot of positive feedback from users, and the number of applications increased immediately after the launch. Thank you for the work done!",
    ES: "El sitio web resultó ser exactamente lo que necesitábamos para presentar nuestros servicios jurídicos y de migración. La facilidad de navegación, el diseño elegante y la posibilidad de encontrar rápidamente la información que necesitan hacen del sitio una herramienta eficaz para nuestros clientes. Recibimos muchos comentarios positivos de los usuarios, y el número de solicitudes aumentó inmediatamente después del lanzamiento. Gracias por el trabajo realizado.",
    PL: "Strona okazała się dokładnie tym, czego potrzebowaliśmy do prezentacji naszych usług prawnych i migracyjnych. Intuicyjna nawigacja, stylowy design i możliwość szybkiego znalezienia potrzebnych informacji — wszystko to czyni stronę skutecznym narzędziem dla naszych klientów. Otrzymaliśmy mnóstwo pozytywnych opinii od użytkowników, a liczba zgłoszeń wzrosła zaraz po uruchomieniu. Dziękujemy za wykonaną pracę!",
  },
  projectsReviewsReviewer3: {
    RU: "Хотим выразить благодарность за создание сайта, который помог вывести наш бизнес на новый уровень! Сайт полностью отвечает ожиданиям наших польских клиентов, ищущих недвижимость в Болгарии. Удобный интерфейс и возможность поиска по параметрам сделали выборнедвижимости проще и быстрее. Сразу после запуска мы начали получать новые заявки — отличный результат!",
    EN: "We would like to express our gratitude for creating a website that has helped to take our business to the next level! The site fully meets the expectations of our Polish clients looking for property in Bulgaria. The user-friendly interface and the ability to search by parameters made choosing a property easier and faster. Immediately after the launch we started receiving new applications - a great result!",
    ES: "Nos gustaría expresar nuestra gratitud por la creación de un sitio web que ha ayudado a llevar nuestro negocio al siguiente nivel. El sitio cumple plenamente las expectativas de nuestros clientes polacos que buscan propiedades en Bulgaria. La interfaz fácil de usar y la posibilidad de buscar por parámetros han hecho que elegir una propiedad sea más fácil y rápido. Inmediatamente después del lanzamiento empezamos a recibir nuevas solicitudes: ¡un gran resultado!",
    PL: "Chcemy wyrazić wdzięczność za stworzenie strony, która pomogła wynieść nasz biznes na nowy poziom! Strona w pełni spełnia oczekiwania naszych polskich klientów szukających nieruchomości w Bułgarii. Przyjazny interfejs i możliwość wyszukiwania według parametrów uczyniły wybór nieruchomości prostszym i szybszym. Zaraz po uruchomieniu zaczęliśmy otrzymywać nowe zgłoszenia — świetny wynik!",
  },
  projectsReviewsReviewer4: {
    RU: "Сайт, который вы разработали, стал мощным инструментом для нашего бизнеса. Современный дизайн и интуитивная структура помогают нашим клиентам быстро находить необходимую информацию и легко связываться с нами. В результате мы получили множество новых запросов от потенциальных водителей и уже видим позитивное влияние на развитие компании. Спасибо за качественную работу и профессиональный подход!",
    EN: "The website you have developed has become a powerful tool for our business. The modern design and intuitive structure help our customers quickly find the information they need and contact us easily. As a result, we have received many new enquiries from potential drivers and are already seeing a positive impact on the development of the company. Thank you for your quality work and professional approach!",
    ES: "El sitio web que han desarrollado se ha convertido en una poderosa herramienta para nuestro negocio. El diseño moderno y la estructura intuitiva ayudan a nuestros clientes a encontrar rápidamente la información que necesitan y a ponerse en contacto con nosotros fácilmente. Como resultado, hemos recibido muchas consultas nuevas de conductores potenciales y ya estamos viendo un impacto positivo en el desarrollo de la empresa. Gracias por vuestro trabajo de calidad y vuestro enfoque profesional.",
    PL: "Strona, którą stworzyliście, stała się potężnym narzędziem dla naszego biznesu. Nowoczesny design i intuicyjna struktura pomagają naszym klientom szybko znaleźć potrzebne informacje i łatwo się z nami skontaktować. W rezultacie otrzymaliśmy wiele nowych zapytań od potencjalnych kierowców i już dostrzegamy pozytywny wpływ na rozwój firmy. Dziękujemy za solidną pracę i profesjonalne podejście!",
  },

  projectsReviewsTeam: {
    RU: "Команда",
    EN: "Team",
    ES: "Equipo",
    PL: "Zespół",
  },

  // ProjectContactBlock

  projectContactBlockDescription: {
    RU: "Ищете надежного IT-партнера? Свяжитесь с нами, и мы поможем вам реализовать ваш проект",
    EN: "Looking for a reliable IT partner? Contact us and we will help you realise your project",
    ES: "¿Busca un socio informático de confianza? Póngase en contacto con nosotros y le ayudaremos a realizar su proyecto",
    PL: "Szukasz niezawodnego partnera IT? Skontaktuj się z nami, a pomożemy Ci zrealizować Twój projekt",
  },

  //Contact us

  contactHeaderTitle: {
    RU: (
      <>
        <span className="primaryTxt">Свяжитесь</span> с нами!
      </>
    ),
    EN: (
      <>
        <span className="primaryTxt">Contact</span> us!
      </>
    ),
    ES: (
      <>
        <span className="primaryTxt">Póngase en contacto</span> con nosotros
      </>
    ),
    PL: (
      <>
        <span className="primaryTxt">Skontaktuj</span> się z nami!
      </>
    ),
  },

  contactHeaderSubtitle: {
    RU: "Мы всегда рады помочь вам с любыми вопросами и предложениями. Пожалуйста, используйте любую из указанных форм связи, чтобы связаться с нашей командой.",
    EN: "We are always happy to assist you with any questions or suggestions.  Please use any of the above contact forms to get in touch with our team",
    ES: "Siempre estamos encantados de ayudarle con cualquier pregunta o sugerencia. Por favor, utilice cualquiera de los formularios de contacto indicados para ponerse en contacto con nuestro equipo",
    PL: "Zawsze chętnie pomożemy w przypadku pytań lub sugestii. Proszę skorzystać z dowolnej z podanych form kontaktu, aby skontaktować się z naszym zespołem",
  },

  contactHeaderDatasTitle: {
    RU: "Наши контактные данные:",
    EN: "Our contact details:",
    ES: "",
    PL: "Nasze dane kontaktowe:",
  },
  contactHeaderPhone: {
    RU: "Телефон:",
    EN: "Phone:",
    ES: "Teléfono:",
    PL: "Telefon:",
  },
  contactHeaderEmail: {
    RU: "Почта:",
    EN: "Mail:",
    ES: "Milla:",
    PL: "Poczta:",
  },
  contactHeaderAddress: {
    RU: "Адрес:",
    EN: "Address:",
    ES: "Dirección:",
    PL: "Adres:",
  },
  contactHeaderLegalAddress: {
    RU: "Тварда 18, 00-824 Варшава, Польша",
    EN: "Twarda 18, 00-824 Warsaw, Poland",
    ES: "Twarda 18, 00-824 Varsovia, Polonia",
    PL: "Twarda 18, 00-824 Warszawa, Polska",
  },

  // Legal information

  LegalInfoPageTitleLg: {
    RU: "Наши юридические данные",  
    EN: "Our Legal Data", 
    ES: "Nuestros datos legales",
    PL: "Nasze dane prawne",
  },
  LegalInfoPageIP: {
    RU: "ИНН:",  
    EN: "NIP:", 
    ES: "NIF:",
    PL: "NIP:",
  },
  LegalInfoPageVAT: {
    RU: "Номер плательщика НДС:",  
    EN: "VAT Number:", 
    ES: "Número de IVA:",
    PL: "Numer VAT:",
  },
  LegalInfoPageSubtitle1: {
    RU: "Банковский счет",  
    EN: "Bank Account", 
    ES: "Cuenta bancaria ",
    PL: "Numer rachunku bankowego",
  },
  LegalInfoPageSubtitle2: {
    RU: "Информация о компании",  
    EN: "Company Information", 
    ES: "Información de la empresa",
    PL: "Informacje o firmie",
  },  
  LegalInfoPageInfoText: {
    RU: "Наша компания официально зарегистрирована в Польше и соблюдает все налоговые требования, включая НДС.",  
    EN: "Our company is officially registered in Poland, and we comply with all tax regulations, including VAT.", 
    ES: "Nuestra empresa está registrada oficialmente en Polonia y cumple con todas las regulaciones fiscales, incluido el IVA.",
    PL: "Nasza firma jest oficjalnie zarejestrowana w Polsce i przestrzega wszystkich przepisów podatkowych, w tym dotyczących VAT.",
  },

  // Cookies Policy page

  cookiePolicyPageTitleLg: {
    RU: "Политика использования файлов cookie",  
    EN: "Cookies Policy", 
    ES: "Política de Cookies",
    PL: "Polityka plików cookie", 
  },

  cookiePolicyPageTitleLgText: {
    RU: 
    <>
    Настоящая Политика использования файлов cookie определяет правила использования файлов cookie и аналогичных технологий на сайте webscover.com, управляемом компанией L’EMIGRANT sp. z o.o., с офисом по адресу: Twarda 18, 00-105 Варшава, Польша. Компания зарегистрирована под номером NIP 5252980934 и REGON 526992520. Связаться с компанией можно по электронной почте <a href="mailto:info@webscover.com">info@webscover.com</a> или по телефону +48 793 466 999.  
    </>,
    EN: 
    <>
    This Cookies Policy outlines the principles for the use of cookies and similar technologies on the website webscover.com, operated by L’EMIGRANT sp. z o.o., with its registered office at Twarda 18, 00-105 Warsaw, Poland. The company is registered under NIP number 5252980934 and REGON number 526992520. You can contact the company via email at <a href="mailto:info@webscover.com">info@webscover.com</a> or by phone at +48 793 466 999.
    </>, 
    ES: 
    <>
    Esta Política de Cookies establece las reglas para el uso de cookies y tecnologías similares en el sitio web webscover.com, operado por L’EMIGRANT sp. z o.o., con sede en Twarda 18, 00-105 Varsovia, Polonia. La empresa está registrada bajo el número NIP 5252980934 y REGON 526992520. Puede ponerse en contacto con la empresa a través del correo electrónico <a href="mailto:info@webscover.com">info@webscover.com</a> o por teléfono al +48 793 466 999.
    </>,
    PL:
    <>
    Niniejsza Polityka Cookies określa zasady wykorzystywania plików cookies oraz podobnych technologii na stronie internetowej webscover.com prowadzonej przez firmę L’EMIGRANT sp. z o. o. z siedzibą pod adresem: Twarda 18, 00-105 Warszawa, Polska. Firma jest zarejestrowana pod numerem NIP 5252980934, REGON 526992520. Kontakt z firmą jest możliwy za pośrednictwem poczty elektronicznej <a href="mailto:info@webscover.com">info@webscover.com</a>, oraz telefonicznie pod numerem +48 793 466 999.
    </>
  },

  cookiePolicyPageTitle1: {
    RU: "1. Что такое файлы cookie?",  
    EN: "1. What are cookies? ", 
    ES: "1. ¿Qué son las cookies?",
    PL: "1. Czym są pliki cookies?",
  },
  cookiePolicyPageText1: {
    RU: "Файлы cookie — это небольшие текстовые файлы, которые сохраняются на устройстве пользователя во время просмотра веб-сайтов. Они могут содержать информацию о действиях пользователя на сайте, его предпочтениях и других данных.",  
    EN: "Cookies are small text files that are saved on the user's device while browsing websites. They may contain information about the user's activity on the site, preferences, and other data", 
    ES: "Las cookies son pequeños archivos de texto que se guardan en el dispositivo del usuario mientras navega por sitios web. Pueden contener información sobre la actividad del usuario en el sitio, sus preferencias y otros datos.",
    PL: "Pliki cookies to niewielkie pliki tekstowe, które są zapisywane na urządzeniu użytkownika podczas przeglądania stron internetowych. Mogą zawierać informacje o aktywności użytkownika na stronie, preferencjach oraz innych danych.",
  },
  
  cookiePolicyPageTitle2: {
    RU: "2. Какие типы файлов cookie используются на сайте webscover.com?",  
    EN: "2. What types of cookies are used on webscover.com?", 
    ES: "2. ¿Qué tipos de cookies se utilizan en webscover.com?",
    PL: "2. Jakie pliki cookies są wykorzystywane na stronie webscover.com?",
  },
  cookiePolicyPageListTitle: {
    RU: "На сайте webscover.com используются следующие типы файлов cookie:", 
    EN: "The following types of cookies are used on webscover.com:", 
    ES: "En webscover.com se utilizan los siguientes tipos de cookies:",
    PL: "Na stronie webscover.com wykorzystywane są następujące rodzaje plików cookies:",
  },
  cookiePolicyPageText2: {
    RU: [
      "Обязательные файлы cookie: Эти файлы необходимы для правильной работы сайта. Они обеспечивают навигацию по сайту и использование его функций.",
      "Аналитические файлы cookie: Эти файлы позволяют собирать анонимную информацию о том, как пользователи взаимодействуют с сайтом. Они помогают анализировать трафик на сайте и улучшать его функциональность.",
      "Функциональные файлы cookie: Эти файлы позволяют запоминать предпочтения пользователя, такие как язык или регион, что упрощает использование сервиса.",
    ],
    EN: [
      "Essential cookies: These cookies are necessary for the proper functioning of the website. They enable navigation and the use of its features.",
      "Analytical cookies: These cookies allow the collection of anonymous information on how users interact with the website. They help us analyze website traffic and improve its functionality.",
      "Functional cookies: These cookies enable the website to remember user preferences, such as language or region, making it easier to use the service.",
    ],
    ES: [
      "Cookies esenciales: Estas cookies son necesarias para el correcto funcionamiento del sitio web. Permiten la navegación y el uso de sus funciones.",
      "Cookies analíticas: Estas cookies permiten recopilar información anónima sobre cómo los usuarios interactúan con el sitio web. Nos ayudan a analizar el tráfico y a mejorar la funcionalidad del sitio.",
      "Cookies funcionales: Estas cookies permiten que el sitio recuerde las preferencias del usuario, como el idioma o la región, facilitando el uso del servicio.",
    ],
    PL: [
      "Pliki cookies niezbędne do działania: Są to pliki cookies niezbędne do poprawnego działania strony internetowej. Dzięki nim możliwe jest nawigowanie po stronie oraz korzystanie z jej funkcji.",
      "Pliki cookies analityczne: Te pliki cookies pozwalają na gromadzenie anonimowych informacji o sposobie korzystania ze strony. Pomagają nam analizować ruch na stronie oraz doskonalić jej funkcjonalność.",
      "Pliki cookies funkcjonalne: Pliki te pozwalają na zapamiętanie wybranych przez użytkownika preferencji, takich jak język czy region, co ułatwia korzystanie z serwisu.",
    ],
  },
  
  cookiePolicyPageTitle3: {
    RU: "3. Как управлять файлами cookie?",  
    EN: "3. How to manage cookies?", 
    ES: "3. ¿Cómo gestionar las cookies?",
    PL: "3. Jak zarządzać plikami cookies?",
  },
  cookiePolicyPageText3: {
    RU: "Пользователь может управлять настройками файлов cookie в своем веб-браузере. Можно заблокировать или ограничить использование файлов cookie через настройки браузера. Однако следует учитывать, что блокировка файлов cookie может повлиять на функциональность сайта и ограничить доступ к некоторым его элементам.",  
    EN: "Users can manage cookie settings in their web browser. They can block or limit the use of cookies through the browser's settings. However, please note that blocking cookies may affect the functionality of the website and limit access to some of its features.", 
    ES: "El usuario puede gestionar las configuraciones de cookies en su navegador web. Puede bloquear o restringir el uso de cookies a través de las configuraciones del navegador. Sin embargo, tenga en cuenta que bloquear las cookies puede afectar la funcionalidad del sitio web y limitar el acceso a algunas de sus características.",
    PL: "Użytkownik ma możliwość zarządzania ustawieniami plików cookies w swojej przeglądarce internetowej. Może zablokować lub ograniczyć wykorzystywanie plików cookies poprzez odpowiednie ustawienia przeglądarki. Warto jednak pamiętać, że zablokowanie plików cookies może wpłynąć na funkcjonalność strony oraz ograniczyć dostęp do niektórych jej elementów.",
  },
  
  cookiePolicyPageTitle4: {
    RU: "4. Контактная информация",  
    EN: "4. Contact ", 
    ES: "4. Contacto",
    PL: "4. Kontakt",
  },
  cookiePolicyPageText4Paragraph1: {
    RU:
      <>
      Если у вас есть вопросы относительно Политики использования файлов cookie или других вопросов, связанных с персональными данными, пожалуйста, свяжитесь с нами по адресу <a href="mailto:info@webscover.com">info@webscover.com</a>. 
      </>, 
      EN: 
      <>
      If you have any questions regarding the Cookies Policy or other issues related to personal data, please contact us at <a href="mailto:info@webscover.com">info@webscover.com</a> 
      </>, 
      ES:
      <>
      Si tiene alguna pregunta sobre la Política de Cookies u otros temas relacionados con datos personales, póngase en contacto con nosotros en <a href="mailto:info@webscover.com">info@webscover.com</a>.
      </>, 
      PL:     
      <>  
      W przypadku pytań dotyczących Polityki Cookies lub innych zagadnień związanych z danymi osobowymi, prosimy o kontakt pod adresem e-mail <a href="mailto:info@webscover.com">info@webscover.com</a>.    
      </>,
  },
  cookiePolicyPageText4Paragraph2: {
    RU: "Настоящая Политика использования файлов cookie может периодически обновляться, поэтому мы рекомендуем регулярно знакомиться с ее содержанием.", 
    EN: "This Cookies Policy may be updated periodically, so we recommend regularly reviewing its content.", 
    ES: "Esta Política de Cookies puede actualizarse periódicamente, por lo que recomendamos revisar su contenido con regularidad.",
    PL: "Niniejsza Polityka Cookies może być okresowo aktualizowana, dlatego zalecamy regularne zapoznanie się z jej treścią.",
  },
  cookiePolicyPageText4Paragraph3: {   
    RU: "Последнее обновление: 4 января 2025 года.", 
    EN: "Last updated: January 4, 2025.", 
    ES: "Última actualización: 4 de enero de 2025.",
    PL: "Ostatnia aktualizacja: 04.01.2025 r.",
  },

  // Privacy Policy

  privacyPolicyPageTitleLg: {
    RU: "Политика конфиденциальности",  
    EN: "Privacy Policy", 
    ES: "Política de Privacidad",
    PL: "Polityka prywatności",
  },

  privacyPolicyPageText: {
    RU: "Настоящая Политика конфиденциальности устанавливает правила сбора, обработки и использования персональных данных компанией L’EMIGRANT spółka z ograniczoną odpowiedzialnością, зарегистрированной по адресу: ул. Twarda 18, 00-105 Варшава, Польша, ИНН 5252980934, REGON 526992520.",  
    EN: "This Privacy Policy outlines the rules for the collection, processing, and use of personal data by L’EMIGRANT spółka z ograniczoną odpowiedzialnością, registered at ul. Twarda 18, 00-105 Warsaw, Poland, NIP 5252980934, REGON 526992520", 
    ES: "La presente Política de Privacidad establece las normas para la recopilación, el procesamiento y el uso de datos personales por parte de L’EMIGRANT spółka z ograniczoną odpowiedzialnością, registrada en ul. Twarda 18, 00-105 Varsovia, Polonia, NIF 5252980934, REGON 526992520.",
    PL: "Niniejsza Polityka Prywatności określa zasady gromadzenia, przetwarzania oraz wykorzystywania danych osobowych przez firmę L’EMIGRANT spółka z ograniczoną odpowiedzialnością zarejestrowaną pod adresem ul.Twarda 18, 00-105 Warszawa, Polska, NIP 5252980934, REGON 526992520.",
  },

  privacyPolicyPageTitle1: {
    RU: "ОБЩИЕ ПОЛОЖЕНИЯ", 
    EN: "GENERAL PROVISIONS", 
    ES: "DISPOSICIONES GENERALES",
    PL: "PRZEPISY OGÓLNE",
  },
 
  privacyPolicyPageText1Paragraph1: {
    RU:
    <>
    1. Администратором ваших персональных данных является компания L’EMIGRANT spółka z ograniczoną odpowiedzialnością, внесённая в Национальный судебный реестр Республики Польша, с ИНН 5252980934, REGON 526992520, с главным офисом по адресу: ул. Twarda 18, 00-105 Варшава, Польша. Электронная почта: <a href="mailto:info@webscover.com">info@webscover.com</a>, телефон для связи: +48 793 466 999 (далее — Администратор).
    </>, 
    EN: 
    <>
    1. The administrator of your personal data is L’EMIGRANT spółka z ograniczoną odpowiedzialnością, entered in the KRS of the Republic of Poland under NIP 5252980934, REGON 526992520, conducting business at ul. Twarda 18, 00-105 Warsaw, Poland. You may contact the Administrator via email at <a href="mailto:info@webscover.com">info@webscover.com</a> or by phone at +48 793 466 999 (hereinafter referred to as the "Administrator"). 
    </>, 
    ES:
    <>
    1. El administrador de sus datos personales es L’EMIGRANT spółka z ograniczoną odpowiedzialnością, inscrita en el Registro Nacional Judicial de la República de Polonia con el NIF 5252980934, REGON 526992520, con sede en ul. Twarda 18, 00-105 Varsovia, Polonia. Correo electrónico: <a href="mailto:info@webscover.com">info@webscover.com</a>, teléfono de contacto: +48 793 466 999 (en adelante, el Administrador).
    </>, 
    PL:     
    <>
    1. Administratorem Państwa danych osobowych jest L’EMIGRANT spółka z ograniczoną odpowiedzialnością wpisaną do KRS Rzeczypospolitej Polskiej pod numerem NIP 5252980934, REGON 526992520, adres wykonywania działalności ul.Twarda 18, 00-105 Warszawa, Polska, adres poczty elektronicznej <a href="mailto:info@webscover.com">info@webscover.com</a>, numer telefonu kontaktowego +48 793 466 999. (dalej jako Administrator).
    </>,
  },

  privacyPolicyPageText1Paragraph2: {
    RU: "2. Персональные данные обрабатываются Администратором в соответствии с Общим регламентом о защите данных (GDPR) Европейского парламента и Совета от 27 апреля 2016 года и в соответствии с действующим законодательством.", 

    EN: '2. Personal data will be processed by the Administrator in accordance with Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons concerning the processing of personal data and the free movement of such data, repealing Directive 95/46/EC (hereinafter referred to as "GDPR"), as well as applicable laws.', 

    ES: "2. Los datos personales serán procesados por el Administrador de acuerdo con el Reglamento General de Protección de Datos (RGPD) del Parlamento Europeo y del Consejo de 27 de abril de 2016, y en cumplimiento de las leyes vigentes.",
    
    PL: "2. Dane osobowe będą przetwarzane przez Administratora zgodnie z rozporządzeniem Parlamentu Europejskiego i Rady (UE) z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej jako „RODO”), oraz zgodnie z powszechnie obowiązującymi przepisami prawa.",
  },

  privacyPolicyPageText1Paragraph3: {
    RU: "ПРАВОВОЕ ОСНОВАНИЕ, ЦЕЛИ И ОБЪЁМ СБОРА ДАННЫХ, А ТАКЖЕ СРОК ИХ ХРАНЕНИЯ", 
    EN: "LEGAL BASIS, PURPOSE, SCOPE OF DATA COLLECTION, AND RETENTION PERIOD", 
    ES: "BASE LEGAL, FINALIDAD Y ALCANCE DE LA RECOGIDA DE DATOS, Y PERIODO DE CONSERVACIÓN",
    PL: "PODSTAWA PRAWNA, CEL I ZAKRES ZBIERANIA DANYCH, ORAZ OKRES ICH PRZECHOWYWANIA",
  },

  privacyPolicyPageText1Paragraph4: {
    RU: "Администратор ограничивает сбор и обработку персональных данных только необходимым минимумом для достижения поставленных целей.", 
    EN: "The Administrator limits the collection and processing of personal data to the minimum necessary to achieve specific purposes.", 
    ES: "El Administrador limita la recopilación y el procesamiento de datos personales al mínimo necesario para cumplir con la finalidad.",
    PL: "Administrator ogranicza zbieranie i przetwarzanie danych osobowych do niezbędnego minimum wymaganego do realizacji celu.",
  },

  privacyPolicyPageText1Paragraph5: {
    RU: "Данные собираются и обрабатываются на следующих правовых основаниях:", 
    EN: "Data is collected and processed based on the following legal grounds:", 
    ES: "Los datos se recopilan y procesan sobre las siguientes bases legales:",
    PL: "Dane są zbierane i przetwarzane w oparciu o następujące podstawy prawne:",
  },

  privacyPolicyPageTitle2: {
    RU: "ПОЛУЧАТЕЛИ ПЕРСОНАЛЬНЫХ ДАННЫХ", 
    EN: "DATA RECIPIENTS", 
    ES: "DESTINATARIOS DE LOS DATOS PERSONALES",
    PL: "ODBIORCY DANYCH OSOBOWYCH",
  },

  privacyPolicyPageText2Paragraph1: {
    RU: "Администратор принимает соответствующие технические и организационные меры для защиты персональных данных от несанкционированного доступа, утраты или повреждения. В процессе своей деятельности персональные данные могут быть переданы поставщикам, ответственным за: бухгалтерские услуги, поддержку ИТ-систем, юридические услуги, а также почтовые или курьерские услуги. Эти поставщики обеспечивают защиту данных в соответствии с действующим законодательством. Кроме того, Администратор оставляет за собой право предоставлять определённые данные соответствующим органам власти или третьим лицам при наличии надлежащего правового основания.", 

    EN: "The Administrator implements appropriate technical and organizational measures to ensure the protection of personal data from unauthorized access, loss, or damage. Data may be disclosed to service providers responsible for accounting, IT systems support, legal services, and postal or courier services. All such entities guarantee the application of appropriate safeguards as required by law. Additionally, the Administrator reserves the right to disclose selected personal data to relevant authorities or third parties requesting such information based on applicable legal grounds.", 

    ES: "El Administrador adopta medidas técnicas y organizativas adecuadas para proteger los datos personales contra el acceso no autorizado, pérdida o daño. En el curso de sus actividades, los datos personales pueden ser compartidos con proveedores responsables de: servicios contables, soporte de sistemas informáticos, servicios legales, y servicios postales o de mensajería. Estos proveedores garantizan la seguridad de los datos según la legislación vigente. Además, el Administrador se reserva el derecho de divulgar ciertos datos a las autoridades pertinentes o a terceros, siempre que exista una base legal adecuada.",

    PL: "Administrator podejmuje odpowiednie środki techniczne i organizacyjne, aby zapewnić odpowiedni poziom ochrony danych osobowych przed nieautoryzowanym dostępem, utratą lub uszkodzeniem. W związku z prowadzeniem działalności wymagającej przetwarzania danych osobowych, mogą one być ujawniane niektórym dostawcom odpowiedzialnym za: obsługę księgową, obsługę systemów informatycznych, podmiotom świadczącym usługi prawne, działalność pocztową lub kurierską. Wszystkie te podmioty gwarantują stosowanie odpowiednich środków ochrony i bezpieczeństwa danych osobowych wymaganych przez przepisy prawa. Ponadto Administrator zastrzega sobie prawo ujawnienia wybranych informacji dotyczących osoby, której dane dotyczą, właściwym organom bądź osobom trzecim, które zgłoszą żądanie udzielenia takich informacji, w oparciu o odpowiednią podstawę prawną oraz zgodnie z przepisami obowiązującego prawa.",
  },

  privacyPolicyPageTitle3: {
    RU: "ПРОФИЛИРОВАНИЕ ДАННЫХ", 
    EN: "DATA PROFILING", 
    ES: "PERFILADO DE DATOS",
    PL: "PROFILOWANIE DANYCH",
  },

  privacyPolicyPageText3Paragraph1: {
    RU: "Ваши данные не обрабатываются автоматически, включая создание профилей.", 
    EN: "Your data is not processed automatically, including profiling.", 
    ES: "Sus datos no son procesados de manera automatizada, incluyendo la creación de perfiles.",
    PL: "Państwa dane nie są przetwarzane w sposób zautomatyzowany, w tym również w formie profilowania.",
  },

  privacyPolicyPageTitle4: {
    RU: "ПРАВА СУБЪЕКТОВ ДАННЫХ", 
    EN: "RIGHTS OF DATA SUBJECTS ", 
    ES: "DERECHOS DE LOS TITULARES DE LOS DATOS",
    PL: "PRAWA OSÓB, KTÓRYCH DANE DOTYCZĄ",
  },

  privacyPolicyPageTitle5: {
    RU: "КОНТАКТ", 
    EN: "CONTACT", 
    ES: "CONTACTO",
    PL: "KONTAKT",
  },
  privacyPolicyPageTextParagraph1: {
    RU:
    <>
     Для реализации ваших прав свяжитесь с Администратором по электронной почте: <a href="mailto:info@webscover.com">info@webscover.com</a>. В случае невозможности идентификации заявителя может потребоваться дополнительная информация. Запрос будет обработан в течение 14 рабочих дней.
     </>, 
    EN: 
    <>
    To exercise any of the rights above, contact the Administrator at <a href="mailto:info@webscover.com">info@webscover.com</a>. If the Administrator cannot identify the requester based on the application, additional information may be requested. Requests will be processed promptly and no later than 14 business days, with responses provided via email.
    </>,

    ES:
    <>
    Para ejercer sus derechos, comuníquese con el Administrador por correo electrónico: <a href="mailto:info@webscover.com">info@webscover.com</a>. En caso de que no sea posible identificar al solicitante, se podrá requerir información adicional. La solicitud será procesada en un plazo máximo de 14 días hábiles.
    </>,

    PL: 
    <>
    W celu zgłaszania żądań związanych z realizacją praw osoby, której dane dotyczą należy skontaktować się z Administratorem wysyłając maila w tej sprawie na adres <a href="mailto:info@webscover.com">info@webscover.com</a>. W przypadku gdy Administrator nie będzie w stanie zidentyfikować osoby składającej wniosek w oparciu o dokonane zgłoszenie, zwróci się do wnioskodawcy o dodatkowe informacje. Administrator po otrzymaniu żądania niezwłocznie podejmie działania w celu jego rozpatrzenia, oraz realizacji. Wniosek będzie rozpatrzony w najkrótszym możliwym terminie, najpóźniej w ciągu 14 dni roboczych. Odpowiedź będzie udzielona drogą mailową.
    </>
  },

  privacyPolicyPageTitle6: {
    RU: "ОБНОВЛЕНИЯ", 
    EN: "This Privacy Policy may be updated periodically, so we recommend reviewing its content regularly.", 
    ES: "ACTUALIZACIONES",
    PL: "Niniejsza Polityka Prywatności może być okresowo aktualizowana, dlatego zalecamy regularne zapoznanie się z jej treścią.",
  },
  privacyPolicyPageText6Paragraph1: {
    RU: "Настоящая Политика конфиденциальности может периодически обновляться. Последнее обновление: 04.01.2025.", 
    EN: "Last updated: January 4, 2025.", 
    ES: "Esta Política de Privacidad puede ser actualizada periódicamente. Última actualización: 04.01.2025.",
    PL: "Ostatnia aktualizacja: 04.01.2025 r.",
  },

  // List

  privacyPolicyPageList1Title: {
    RU: "Обработка персональных данных на основе согласия", 
    EN: "Processing of personal data based on consent", 
    ES: "Procesamiento de datos personales basado en el consentimiento",
    PL: "Przetwarzanie danych osobowych na podstawie zgody",
  },

  privacyPolicyPageList1: {
    RU: [      
      "ПРАВОВОЕ ОСНОВАНИЕ: Статья 6, пункт 1, подпункт a GDPR",
      "ЦЕЛЬ: Обеспечение связи и ответы на запросы, отправленные через сайт, по электронной почте или телефону; продвижение и маркетинг собственных услуг.",     
      "ОБЪЁМ: Имя и фамилия, адрес электронной почты, номер телефона (и любая другая информация, предоставленная потенциальным клиентом в сообщении Администратору).",
      "РОК ХРАНЕНИЯ: До момента отзыва согласия. Отзыв согласия не влияет на законность обработки данных до момента его отзыва."
    ],
    EN: [     
      "LEGAL BASIS: Article 6(1)(a) GDPR",
      "PURPOSE: Personal data is collected to enable contact, respond to inquiries or messages sent via the website, email, or phone, and for the promotion and marketing of the Administrator’s services.", 
      "SCOPE: Name, email address, phone number (and other information provided by the client in their message to the Administrator).",
      "RETENTION PERIOD: Personal data processed based on consent will be retained until the consent is withdrawn. Withdrawal of consent does not affect the legality of processing carried out before its withdrawal."
    ],
    ES: [     
      "BASE LEGAL: Artículo 6, apartado 1, letra a del RGPD",
      "FINALIDAD: Facilitar el contacto y responder a consultas enviadas a través del sitio web, correo electrónico o teléfono; promoción y marketing de servicios propios.",
      "ALCANCE: Nombre y apellidos, dirección de correo electrónico, número de teléfono (y cualquier otra información que el cliente potencial desee incluir en el mensaje dirigido al Administrador).",
      "PERIODO DE CONSERVACIÓN: Hasta que se retire el consentimiento. El retiro del consentimiento no afecta la legalidad del procesamiento realizado antes de su retirada." 
    ],
    PL: [     
      "PODSTAWA PRAWNA: art. 6 ust. 1 lit. a RODO",
      "CEL: Dane osobowe gromadzone są w celu umożliwienia kontaktu oraz udzielenia odpowiedzi na zapytania i wiadomości przesłane za pośrednictwem strony internetowej, poczty elektronicznej lub telefonu, promocji i marketingu usług własnych.",
      "ZAKRES: Imię i nazwisko, adres e-mail, numer telefonu (oraz inne informacje jakie potencjalny klient chce zawrzeć w wiadomości kierowanej do Administratora).",
      "OKRES PRZECHOWYWANIA: Dane osobowe przetwarzane na podstawie dobrowolnie wyrażonej zgody będą przechowywane do momentu jej wycofania. Wycofanie zgody nie wpływa na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej wycofaniem."
    ]
  },

  privacyPolicyPageList2Title: {
    RU: "Обработка персональных данных на основании договора или запроса", 
    EN: "Processing of personal data under an order or contrac", 
    ES: "Procesamiento de datos personales basado en un contrato o solicitud",
    PL: "Przetwarzanie danych osobowych na podstawie zlecenia lub umowy",
  },
  privacyPolicyPageList2: {
    RU: [      
      "ПРАВОВОЕ ОСНОВАНИЕ: Статья 6, пункт 1, подпункт b GDPR",
      "ЦЕЛЬ: Выполнение обязательств, связанных с оказанием ИТ-услуг, контакты, вытекающие из договора или действий до его заключения.",     
      "ОБЪЁМ: Название компании, номер ИНН, адрес офиса/деятельности, номер телефона, электронная почта, имя и фамилия, указанные в договоре или запросе.",
      "СРОК ХРАНЕНИЯ: На протяжении времени, необходимого для надлежащего выполнения договора, а после его завершения — до истечения срока исковой давности или других обязательств."
    ],
    EN: [     
      "LEGAL BASIS: Article 6(1)(b) GDPR",
      "PURPOSE: Fulfillment of obligations related to IT services contracts, ongoing communication arising from contracts, or actions taken to conclude a contract.", 
      "SCOPE: Company name, tax identification number (NIP), business or registered address, phone number, email address, name and surname based on the order or contract.",
      "RETENTION PERIOD: Data is retained for the duration necessary to properly execute the service agreement, including electronic services. Afterward, data is stored until the limitation period for claims and other obligations under applicable law expires." 
    ],
    ES: [     
      "BASE LEGAL: Artículo 6, apartado 1, letra b del RGPD",
      "FINALIDAD: Cumplimiento de obligaciones relacionadas con la prestación de servicios informáticos, contacto derivado de un contrato o acciones previas a su firma.",
      "ALCANCE: Nombre de la empresa, número NIF, dirección de sede/actividad, número de teléfono, correo electrónico, nombre y apellidos proporcionados en el contrato o solicitud.",
      "PERIODO DE CONSERVACIÓN: Durante el tiempo necesario para la ejecución correcta del contrato y, tras su finalización, hasta que expire el periodo de prescripción de reclamaciones u otras obligaciones legales." 
    ],
    PL: [     
      "PODSTAWA PRAWNA: art. 6 ust. 1 lit. b RODO",
      "CEL: Wypełnienie obowiązków w związku z zawarciem i  realizacją usług informatycznych , lub w przypadku bieżącego kontaktu wynikającego z zawartej umowy lub z podjętych działań zmierzających do jej zawarcia.",
      "ZAKRES: Nazwa firmy, numer NIP, adres siedziby/ prowadzenia działalności, numer telefonu, adres email, imię i nazwisko na podstawie zlecenia lub umowy.",
      "OKRES PRZECHOWYWANIA: Dane są przechowywane przez okres niezbędny do prawidłowego wykonania umowy świadczenia usług, w tym usług świadczonych drogą elektroniczną. A także po jej wykonaniu do momentu, gdy upłynie okres przedawnienia roszczeń i innych obowiązków wynikających z obowiązujących przepisów prawa."
    ]
  },

  privacyPolicyPageList3Title: {
    RU: "Обработка персональных данных по законному обязательству", 
    EN: "Processing of personal data under legal obligations", 
    ES: "Procesamiento de datos personales por obligación legal",
    PL: "Przetwarzanie danych osobowych na podstawie przepisów prawa",
  },
  privacyPolicyPageList3: {
    RU: [      
      "ПРАВОВОЕ ОСНОВАНИЕ: Статья 6, пункт 1, подпункт c GDPR",
      "ЦЕЛЬ: Соблюдение налоговых и бухгалтерских обязательств. ",     
      "ОБЪЁМ: Имя и фамилия, название компании, номер ИНН, адрес проживания, деятельности или офиса.",
      "СРОК ХРАНЕНИЯ: В течение времени, требуемого налоговым законодательством, обычно пять лет с конца финансового года, в котором возникло налоговое обязательство."
    ],
    EN: [     
      "LEGAL BASIS: Article 6(1)(c) GDPR",
      "PURPOSE: Compliance with accounting and tax obligations.", 
      "SCOPE: Name, company name, tax identification number (NIP), address of residence, business, or registered office.",
      "RETENTION PERIOD: Data is retained for the period required by tax law, i.e., the Tax Ordinance and Accounting Act, typically 5 years from the end of the calendar year in which the tax payment deadline expired."
    ],
    ES: [     
      "BASE LEGAL: Artículo 6, apartado 1, letra c del RGPD",
      "FINALIDAD: Cumplir con obligaciones fiscales y contables.",
      "ALCANCE: Nombre y apellidos, nombre de la empresa, número NIF, dirección de residencia, de actividad o de sede.",
      "PERIODO DE CONSERVACIÓN: Por el periodo requerido por las leyes fiscales, generalmente cinco años a partir del final del año fiscal en que venció la obligación tributaria."
    ],
    PL: [     
      "PODSTAWA PRAWNA: art. 6 ust. 1 lit. c RODO",
      "CEL: Wypełnienie obowiązków rachunkowo – podatkowych.",
      "ZAKRES: Imię i nazwisko, nazwa firmy, numer NIP, adres zamieszkania, lub prowadzenia działalności, lub siedziby.",
      "OKRES PRZECHOWYWANIA: Dane są przechowywane przez okres wymagany przez przepisy prawa podatkowego tj. Ordynację Podatkową i Ustawę o rachunkowości, a więc zasadniczo przez okres 5 lat, licząc od końca roku kalendarzowego, w którym upłynął termin płatności podatku."
    ]
  },

  privacyPolicyPageList4Title: {
    RU: "Обработка персональных данных на основе законного интереса", 
    EN: "Processing of personal data based on legitimate interests", 
    ES: "Procesamiento de datos personales basado en intereses legítimo",
    PL: "Przetwarzanie danych osobowych na podstawie prawnie uzasadnionego interesu",
  },

  privacyPolicyPageList4: {
    RU: [      
      "ПРАВОВОЕ ОСНОВАНИЕ: Статья 6, пункт 1, подпункт f GDPR",
      "ЦЕЛЬ: Установление, защита или предъявление законных требований; архивные, статистические цели или контакты через электронные формы, электронную почту или телефонные звонки.",     
      "ОБЪЁМ: Имя и фамилия, название компании, номер ИНН, адрес проживания, деятельности или офиса, адрес доставки, номер телефона, электронная почта (и любая другая информация, представленная потенциальным клиентом).",
      "СРОК ХРАНЕНИЯ: До истечения срока исковой давности, обычно три года"
    ],
    EN: [     
      "LEGAL BASIS: Article 6(1)(f) GDPR",
      "PURPOSE: Establishing, pursuing, or defending potential claims, archiving and statistical purposes, and contact through the website’s contact form, email, or phone.", 
      "SCOPE: Name, company name, tax identification number (NIP), address of residence, business, or registered office, delivery address, phone number, email address (and other information provided by the client in their message to the Administrator).",
      "RETENTION PERIOD: Data necessary for purposes based on legitimate interests is retained until the limitation period for claims expires, typically 3 years, consistent with the statute of limitations for business-related claims. The end date is the last day of the calendar year."
    ],
    ES: [     
      "BASE LEGAL: Artículo 6, apartado 1, letra f del RGPD",
      "FINALIDAD: Establecimiento, defensa o reclamación de derechos legales; fines archivísticos, estadísticos o contacto a través de formularios electrónicos, correos electrónicos o llamadas telefónicas.",
      "ALCANCE: Nombre y apellidos, nombre de la empresa, número NIF, dirección de residencia, actividad o sede, dirección de entrega, número de teléfono, correo electrónico (y cualquier otra información proporcionada por el cliente potencial). ",
      "PERIODO DE CONSERVACIÓN: Hasta el vencimiento del periodo de prescripción aplicable, generalmente tres años."
    ],
    PL: [     
      "PODSTAWA PRAWNA: art. 6 ust. 1 lit. f RODO",
      "CEL: Ustalenie dochodzenia i obrony ewentualnych roszczeń jakie może podnosić Administrator lub jakie mogą być podnoszone wobec Administratora. A także do celów archiwalnych i statystycznych, lub w celach kontaktowych za pośrednictwem elektronicznego formularza kontaktowego, maila, lub połączenia telefonicznego.",
      "ZAKRES: Imię i nazwisko, nazwa firmy, numer NIP, adres zamieszkania, lub prowadzenia działalności, lub siedziby, adres dostawy, numer telefonu, adres e-mail (oraz inne informacje jakie potencjalny klient chce zawrzeć w wiadomości kierowanej do Administratora).",
      "OKRES PRZECHOWYWANIA: Dane osobowe niezbędne do celów wynikających z prawnie uzasadnionych interesów realizowanych przez Administratora będą przechowywane do momentu upływu terminu przedawnienia. Zasadniczo przez okres 3 lat co wynika z długości okresu przedawnienia roszczeń związanych z prowadzeniem działalności gospodarczej. Koniec terminu przedawnienia przypada na ostatni dzień roku kalendarzowego."
    ]
  },

  privacyPolicyPageList5Title: {
    RU: "Согласно GDPR (статьи 7, 15–21 и 77), лица, чьи данные обрабатываются, имеют следующие права:", 
    EN: "Under GDPR (Articles 7(3), 15-21, and 77), individuals whose data is processed have the following rights:", 
    ES: "Según el RGPD (artículos 7, 15-21 y 77), las personas cuyos datos se procesan tienen los siguientes derechos:",
    PL: "Zgodnie z obowiązującymi przepisami, w tym RODO (art. 7 ust. 3 RODO, art. 15-21 RODO art. 77 RODO) osobie, której dane dotyczą przysługują następujące prawa:",
  },
  privacyPolicyPageList5: {
    RU: [      
      "Право на информацию об обработке персональных данных.",
      "Право на получение копии данных.",     
      "Право на исправление неточных данных.",
      "Право на удаление данных.",
      "Право на ограничение обработки.",
      "Право возражать против обработки данных для маркетинговых целей.",
      "Право возражать против обработки данных для других целей",
      "Право отозвать согласие в любой момент.",
      "Право на перенос данных.",
      "Право подать жалобу в надзорный орган."
    ],
    EN: [     
      "Right to information: Receive details about the purposes, legal basis, and scope of data processing, and the entities to which the data is disclosed.",
      "Right to access: Obtain a copy of the data processed.", 
      "Right to rectification: Correct errors or inaccuracies in processed data.",
      'Right to erasure ("right to be forgotten"): Request deletion of data no longer necessary for the purposes for which it was collected.',
      " Right to restriction of processing: Halt data processing operations except those consented to by the data subject.",
      "Right to object to processing for marketing purposes: Cease data processing for marketing upon objection, which can be made without justification.",
      "Right to object to other processing purposes: Request termination of processing for analytical or statistical purposes.",
      "Right to withdraw consent: Cease processing upon withdrawal of consent, which does not affect the legality of processing before withdrawal.",
      "Right to data portability: Obtain data in a computer-readable format or request its transfer to another entity.",
      "Right to lodge a complaint: Submit a complaint to the President of the Personal Data Protection Office if processing violates GDPR or other data protection laws"
    ],

    ES: [     
      "Derecho a la información sobre el procesamiento de datos personales.",
      "Derecho a obtener una copia de los datos.",
      "Derecho a corregir datos inexactos.",
      "Derecho a la eliminación de datos.",
      "Derecho a restringir el procesamiento.",
      "Derecho a oponerse al procesamiento con fines de marketing.",
      "Derecho a oponerse al procesamiento con otros fines.",
      "Derecho a retirar el consentimiento en cualquier momento.  ",
      "Derecho a la portabilidad de los datos.",
      "Derecho a presentar una queja ante la autoridad de supervisión."
    ],
    PL: [     
      "Prawo do informacji o przetwarzaniu danych osobowych. Osobie zgłaszającej takie żądanie Administrator przekazuje informacje o przetwarzaniu jej danych. Przede wszystkim o celach i podstawach prawnych przetwarzania, zakresie posiadanych danych, oraz o podmiotach którym są ujawniane wraz z planowanym terminie ich usunięcia.",
      "Prawo do uzyskania kopii danych. Administrator przekazuje kopię przetwarzanych danych, dotyczących osoby której dane dotyczą i która zgłasza takie żądanie.", 
      "Prawo do sprostowania danych. Administrator zobowiązany jest usuwać błędy lub niezgodności w przetwarzanych danych.",
      "Prawo do usunięcia danych. Administrator na żądanie osoby, której dane dotyczą usuwa dane, których przetwarzanie nie jest już niezbędne do realizowania żadnego z celów, dla których zostały zebrane.",
      "Prawo do ograniczenia przetwarzania. Administrator na żądanie osoby, której dane dotyczą zaprzestaje dokonywania operacji na danych osobowych, z wyjątkiem operacji, na które wyraziła zgodę osoba, której dane dotyczą.",
      "Prawo sprzeciwu wobec przetwarzania danych w celach marketingowych. Administrator w związku z sprzeciwem osoby, której dane dotyczą przestaje przetwarzać dane osobowe tej osoby. Sprzeciw taki może być złożony w każdym czasie, bez konieczności jego uzasadnienia.",
      "Prawo sprzeciwu wobec innych celów przetwarzania danych. Administrator na uzasadniony sprzeciw osoby, której dane dotyczą przestaje przetwarzać dane osobowe do innych celów np. dla celów analitycznych lub statystycznych.",
      "Prawo wycofania zgody w każdym momencie. Administrator w przypadku cofnięcia zgody przez osobę, której dane dotyczą przestaje przetwarzać dane osobowe. Nie wpływa to na zgodność z prawem przetwarzania dokonanego przed wycofaniem tej zgody.",
      "Prawo wycofania zgody w każdym momencie. Administrator w przypadku cofnięcia zgody przez osobę, której dane dotyczą przestaje przetwarzać dane osobowe. Nie wpływa to na zgodność z prawem przetwarzania dokonanego przed wycofaniem tej zgody.",
      "Prawo do skargi do organu nadzorczego. Jeżeli osoba, której dane są przetwarzane uzna, że przetwarzanie narusza przepisy RODO lub inne przepisy dotyczące ochrony danych osobowych może złożyć skargę do Prezesa Urzędu Ochrony Danych Osobowych."
    ],
  },

  
  // Cookies Modal

  cookiesModalТеxt: {
    RU: "Мы используем файлы cookie, чтобы улучшить ваши впечатления от просмотра сайта, отображать персонализированную рекламу или контент и анализировать наш трафик. Нажав кнопку «Принять все», вы соглашаетесь с использованием нами файлов cookie.",
    EN: "We use cookies to improve your browsing experience, display personalised advertising or content and analyse our traffic. By clicking ‘Accept All’ you agree to our use of cookies.",
    ES: "Utilizamos cookies para mejorar su experiencia de navegación, mostrar publicidad o contenidos personalizados y analizar nuestro tráfico. Al hacer clic en «Aceptar todo», aceptas el uso que hacemos de las cookies.",
    PL: "Używamy plików cookie, aby poprawić komfort przeglądania, wyświetlać spersonalizowane reklamy lub treści oraz analizować nasz ruch. Klikając „Akceptuj wszystko”, wyrażasz zgodę na używanie przez nas plików cookie.",
  },
  cookiesModalBtnAccept: {
    RU: "Принять все",  
    EN: "Accept all", 
    ES: "Aceptar todo",
    PL: "Zaakceptuj wszystkie",
  },
  cookiesModalBtnReject: {
    RU: "Отклонить все",  
    EN: "Reject all", 
    ES: "Rechazar todo",
    PL: "Odrzuć wszystkie",
  },
  
  // SEO meta tags

  // Homepage page meta tags

    metaHomepageKeyword: {
    RU: "разработка веб-сайтов, веб-дизайн, адаптивный сайт, корпоративный сайт,  современный дизайн. веб-программирование, UI/UX дизайн, таргетированная реклама, сайт под ключ, автоматизация общения с клиентами, чат боты",  
    EN: "website development, web design, responsive site, corporate site, modern design, web programming, UI/UX design, targeted ads, turnkey website, customer interaction automation, chatbots", 
    ES: "desarrollo web, diseño web, sitio responsivo, sitio corporativo, diseño moderno, programación web, diseño UI/UX, publicidad dirigida, sitio llave en mano, automatización de comunicación con clientes, chatbots",
    PL: "tworzenie stron WWW, projektowanie stron, responsywna strona, strona firmowa, nowoczesny design, programowanie stron, projektowanie UI/UX, reklama celowana, strona na zamówienie, automatyzacja komunikacji z klientami, chatboty",
  },
  metaHomepagetTitle: {
    RU: "Создание вебсайтов | Таргетированная реклама | Чат-боты",  
    EN: "Website Development | Targeted Ads | Chatbots", 
    ES: "Desarrollo Web | Publicidad Dirigida | Chatbots",
    PL: "Tworzenie Stron WWW | Reklama Celowana | Chatboty",
  },
  metaHomepageDescription: {
    RU: "Мы создаем веб-решения для вашего бизнеса, от разработки сайтов и таргетированной рекламы до автоматизации общения с клиентами.",  
    EN: "We create web solutions for your business, including website development, targeted advertising, and customer interaction automation.", 
    ES: "Creamos soluciones web para tu negocio, incluyendo desarrollo de sitios web, publicidad dirigida y automatización de la comunicación con clientes.",
    PL: "Tworzymy rozwiązania webowe dla Twojego biznesu, w tym tworzenie stron, reklamy celowane oraz automatyzację komunikacji z klientami.",
  },

  // About page meta tags

  metaAboutKeyword: {
    RU: "IT-компания, дизайнеры, разработчики,  аналитики, тестировщики, опытные специалисты, эксперты по таргетированной рекламе, маркетологи, специалисты по автоматизации, стильные сайты, сайт под ключ, чат-боты, таргетированная реклама",
    EN: "IT company, designers, developers, analysts, testers, experienced professionals, targeted advertising experts, marketers, automation specialists, stylish websites, turnkey website, chatbots, targeted advertising",
    ES: "empresa IT, diseñadores, desarrolladores, analistas, testers, profesionales experimentados, expertos en publicidad dirigida, especialistas en marketing, especialistas en automatización, sitios elegantes, sitio llave en mano, chatbots, publicidad dirigida",
    PL: "firma IT, projektanci, programiści, analitycy, testerzy, doświadczeni specjaliści, eksperci od reklamy celowanej, marketerzy, specjaliści ds. automatyzacji, stylowe strony, strona na zamówienie, chatboty, reklama celowana",
  },

  metaAboutTitle: {
    RU: "Высококвалифицированная команда с опытом в сфере IT-услуг",
    EN: "Highly Skilled Team with Experience in IT Services",
    ES: "Equipo Altamente Calificado con Experiencia en Servicios IT",
    PL: "Wykwalifikowany Zespół z Doświadczeniem w Usługach IT",
  },

  metaAboutDescription: {
    RU: "Мы помогаем бизнесам расширить свои возможности и достичь успеха в цифровом мире, используя передовые технологии и подходы.",
    EN: "We help businesses expand their potential and achieve success in the digital world using advanced technologies and approaches.",
    ES: "Ayudamos a las empresas a ampliar su potencial y alcanzar el éxito en el mundo digital utilizando tecnologías y enfoques avanzados.",
    PL: "Pomagamy firmom rozwijać ich potencjał i osiągać sukces w cyfrowym świecie, wykorzystując zaawansowane technologie i podejścia.",
  },

  // Services page meta tags

  metaServicesKeyword: {
    RU: "IT-услуги, веб-разработка, индивидуальный дизайн, адаптивный дизайн, редизайн сайта, landing page, интернет-магазин, многостраничный сайт, интернет-маркетинг, системы управления контентом (CMS), фреймворки SEO оптимизация, SEO-аудит, аналитика. таргетинг, чат-бот с аналитикой,  создание контента, тестирование сайта, A/B-тестирование, миграция и запуск, техническая поддержка,  таргетированная реклама, чат-боты",  
    EN: "IT services, web development, custom design, responsive design, website redesign, landing page, online store, multipage site, internet marketing, content management systems (CMS), frameworks, SEO optimization, SEO audit, analytics, targeting, chatbot with analytics, content creation, website testing, A/B testing, migration and launch, technical support, targeted advertising, chatbots", 
    ES: "servicios IT, desarrollo web, diseño personalizado, diseño responsivo, rediseño de sitios web, landing page, tienda en línea, sitio multipágina, marketing en línea, sistemas de gestión de contenido (CMS), frameworks, optimización SEO, auditoría SEO, analítica, segmentación, chatbot con analítica, creación de contenido, pruebas de sitios web, pruebas A/B, migración y lanzamiento, soporte técnico, publicidad dirigida, chatbots",
    PL: "usługi IT, tworzenie stron WWW, projektowanie na zamówienie, responsywny design, redesign strony, landing page, sklep internetowy, strona wielostronicowa, marketing internetowy, systemy zarządzania treścią (CMS), frameworki, optymalizacja SEO, audyt SEO, analityka, targetowanie, chatbot z analityką, tworzenie treści, testowanie stron, testy A/B, migracja i uruchomienie, wsparcie techniczne, reklama celowana, chatboty ",
  },
  metaServicesTitle: {
    RU: "Широкий Спектр IT услуг для Вашего Бизнеса | Webscover",  
    EN: "Comprehensive IT Services for Your Business | Webscover", 
    ES: "Amplia Gama de Servicios IT para tu Negocio | Webscover ",
    PL: "Szeroki Zakres Usług IT dla Twojego Biznesu | Webscover",
  },
  metaServicesDescription: {
    RU: "Мы предоставляем широкий спектр IT-услуг: адаптивный дизайн, SEO оптимизация, интернет-маркетинг,  чат-бот с аналитикой, поддержка сайта.",  
    EN: "We offer a wide range of IT services: responsive design, SEO optimization, internet marketing, analytics-based chatbots, and website support.", 
    ES: "Ofrecemos una amplia gama de servicios IT: diseño responsivo, optimización SEO, marketing en línea, chatbots con analítica y soporte web.",
    PL: "Oferujemy szeroki zakres usług IT: responsywny design, optymalizację SEO, marketing internetowy, chatboty z analityką oraz wsparcie strony internetowej.",
  },

   // Our projects page meta tags

   metaProjectsKeyword: {
    RU: "веб-сайт, портфолио Webscover, уникальные проекты, проекты с индивидуальным дизайном, заказ сайта, разработка структуры, адаптивность, L’EMIGRANT, Reality Bulgaria, Rise Partner",  
    EN: "website, Webscover portfolio, unique projects, custom design projects, website orders, structure development, responsiveness, L’EMIGRANT, Reality Bulgaria, Rise Partner", 
    ES: "sitio web, portafolio Webscover, proyectos únicos, proyectos de diseño personalizado, pedidos de sitios web, desarrollo de estructura, adaptabilidad, L’EMIGRANT, Reality Bulgaria, Rise Partner",
    PL: "strona internetowa, portfolio Webscover, unikalne projekty, projekty z indywidualnym designem, zamówienie strony, opracowanie struktury, responsywność, L’EMIGRANT, Reality Bulgaria, Rise Partner",
  },
  metaProjectsTitle: {
    RU: "Успешные разработки сайтов Webscover | Портфолио проектов",  
    EN: "Successful Website Developments by Webscover | Project Portfolio", 
    ES: "Desarrollos Web Exitosos por Webscover | Portafolio de Proyectos",
    PL: "Udane Projekty Stron WWW Webscover | Portfolio Projektów ",
  },
  metaProjectsDescription: {
    RU: "Мы разрабатываем сайты с индивидуальным дизайном, адаптивной версткой и высокой функциональностью для различных бизнес-решений.",  
    EN: "We design websites with custom designs, responsive layouts, and high functionality tailored to various business solutions.", 
    ES: "Diseñamos sitios web con diseños personalizados, estructuras responsivas y alta funcionalidad adaptadas a diversas soluciones empresariales.",
    PL: "Tworzymy strony webowe z unikalnym designem, responsywnym układem i wysoką funkcjonalnością dostosowaną do różnych rozwiązań biznesowych.",
  },

  //Contact us page meta tags

    metaContactKeyword: {
    RU: "контакты Webscover, служба поддержки Webscover, консультация, обслуживание сайтов, IT-услуги, заказать сайт, номер телефона Webscover",  
    EN: "Webscover contacts, Webscover support, consultation, website maintenance, IT services, order a website, Webscover phone number", 
    ES: "contacto Webscover, soporte Webscover, consulta, mantenimiento de sitios web, servicios IT, pedir un sitio web, número de teléfono Webscover",
    PL: "kontakt Webscover, wsparcie Webscover, konsultacja, obsługa stron internetowych, usługi IT, zamówienie strony, numer telefonu Webscover",
  },
  metaContactTitle: {
    RU: "Контакты Webscover | +48 793 466 999 | info@webscover.com",  
    EN: "Contact Webscover | +48 793 466 999 | info@webscover.com ", 
    ES: "Contacto Webscover | +48 793 466 999 | info@webscover.com",
    PL: "Kontakt Webscover | +48 793 466 999 | info@webscover.com",
  },
  metaContactDescription: {
    RU: "Свяжитесь с нами для обсуждения вашей бизнес-модели и требований. Мы предоставляем постоянную поддержку и обслуживание.",  
    EN: "Contact us to discuss your business model and requirements. We provide continuous support and maintenance.", 
    ES: "Contáctanos para discutir tu modelo de negocio y requerimientos. Ofrecemos soporte y mantenimiento continuo.",
    PL: "Skontaktuj się z nami, aby omówić swój model biznesowy i wymagania. Zapewniamy stałe wsparcie i obsługę.",
  },  
};