import React from "react";

import styles from "./AboutFeatures.module.scss";
import BgLight from "../../layout/BgLight/BgLight";
import { useLazy } from "../../../hooks/useLazy";
import TransitionProvider, {
  TransitionStyleTypes,
} from "../../../providers/TransitionProvider";

import { useAppSelector } from "../../../hooks/redux";
import { translations } from "../../../constants/translations";

const { 
  aboutFeaturesTitleLg,
  aaboutFeature1, 
  aaboutFeature2,
  aaboutFeature3, 
  aaboutFeature4
} = translations;

interface Props {}

const AboutFeatures: React.FC<Props> = (props) => {
  const { isInView, ref } = useLazy(0.2);
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage)

  const features = [
    aaboutFeature1[siteLanguage],
    aaboutFeature2[siteLanguage],
    aaboutFeature3[siteLanguage],
    aaboutFeature4[siteLanguage],
  ];  

  return (
    <section ref={ref} className={`container ${styles.aboutFeatures}`}>
      <TransitionProvider inProp={isInView} style={TransitionStyleTypes.top}>
        <h3 className={"titleLg"}>
          {aboutFeaturesTitleLg[siteLanguage]}
        </h3>
      </TransitionProvider>
      <div className={styles.aboutFeatures__main}>
        {features.map((item, index) => (
          <TransitionProvider
            inProp={isInView}
            style={TransitionStyleTypes.right}
            delay={index * 100}
            className={styles.aboutFeatures__item}
            key={index}
          >
            <div className={styles.aboutFeatures__indexWrapper}>
              <h4 className={styles.aboutFeatures__indexTxt}>{index + 1}</h4>
            </div>
            <p className={styles.aboutFeatures__itemText}>{item}</p>
          </TransitionProvider>
        ))}
      </div>
      <TransitionProvider
        inProp={isInView}
        delay={400}
        style={TransitionStyleTypes.opacity}
      >
        <BgLight className={styles.aboutFeatures__bgLight} />
      </TransitionProvider>
    </section>
  );
};

export default AboutFeatures;
