import React from "react";
import { useAppSelector } from "../hooks/redux";
import { translations } from "../constants/translations";

const {
  privacyPolicyPageTitleLg,
  privacyPolicyPageText,

  privacyPolicyPageTitle1,
  privacyPolicyPageText1Paragraph1,
  privacyPolicyPageText1Paragraph2,
  privacyPolicyPageText1Paragraph3,
  privacyPolicyPageText1Paragraph4,
  privacyPolicyPageText1Paragraph5,

  privacyPolicyPageTitle2,
  privacyPolicyPageText2Paragraph1,

  privacyPolicyPageTitle3,
  privacyPolicyPageText3Paragraph1,

  privacyPolicyPageTitle4,
  privacyPolicyPageTitle5,

  privacyPolicyPageTextParagraph1,
  privacyPolicyPageTitle6,

  privacyPolicyPageText6Paragraph1,

  // List

  privacyPolicyPageList1Title,
  privacyPolicyPageList1,

  privacyPolicyPageList2Title,
  privacyPolicyPageList2,

  privacyPolicyPageList3Title,
  privacyPolicyPageList3,

  privacyPolicyPageList4Title,
  privacyPolicyPageList4,

  privacyPolicyPageList5Title,
  privacyPolicyPageList5,
} = translations;

const List = ({ list }: { list: string[] }) => {
  return (
    <ol>
      {list.map((item, index) => (
        <li key={index}>{item}</li>
      ))}
    </ol>
  );
};

const PrivacyPolicyPage: React.FC = () => {
  const siteLanguage = useAppSelector((state) => state.ui.siteLanguage);

  const list1 = privacyPolicyPageList1[siteLanguage];
  const list2 = privacyPolicyPageList2[siteLanguage];
  const list3 = privacyPolicyPageList3[siteLanguage];
  const list4 = privacyPolicyPageList4[siteLanguage];
  const list5 = privacyPolicyPageList5[siteLanguage];

  return (
    <main className="container doc">
      <header>
        <h1>{privacyPolicyPageTitleLg[siteLanguage]}</h1>
        <p>{privacyPolicyPageText[siteLanguage]}</p>
      </header>
      <section>
        <h2>{privacyPolicyPageTitle1[siteLanguage]}</h2>
        <p>{privacyPolicyPageText1Paragraph1[siteLanguage]}</p>
        <p>{privacyPolicyPageText1Paragraph2[siteLanguage]}</p>
        <p>{privacyPolicyPageText1Paragraph3[siteLanguage]}</p>
        <p>{privacyPolicyPageText1Paragraph4[siteLanguage]}</p>
        <p>{privacyPolicyPageText1Paragraph5[siteLanguage]}</p>

        <h3>{privacyPolicyPageList1Title[siteLanguage]}</h3>
        <List list={list1} />

        <h3>{privacyPolicyPageList2Title[siteLanguage]}</h3>
        <List list={list2} />

        <h3>{privacyPolicyPageList3Title[siteLanguage]}</h3>
        <List list={list3} />

        <h3>{privacyPolicyPageList4Title[siteLanguage]}</h3>
        <List list={list4} />

        <h2>{privacyPolicyPageTitle2[siteLanguage]}</h2>
        <p>{privacyPolicyPageText2Paragraph1[siteLanguage]}</p>

        <h2>{privacyPolicyPageTitle3[siteLanguage]}</h2>
        <p>{privacyPolicyPageText3Paragraph1[siteLanguage]}</p>

        <h2>{privacyPolicyPageTitle4[siteLanguage]}</h2>

        <h3>{privacyPolicyPageList5Title[siteLanguage]}</h3>
        <List list={list5} />

        <h2>{privacyPolicyPageTitle5[siteLanguage]}</h2>
        <p>{privacyPolicyPageTextParagraph1[siteLanguage]}</p>

        <h2>{privacyPolicyPageTitle6[siteLanguage]}</h2>
        <p>{privacyPolicyPageText6Paragraph1[siteLanguage]}</p>
      </section>
    </main>
  );
};

export default PrivacyPolicyPage;
